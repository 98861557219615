<script setup lang="ts">
const { t } = useI18n()
const { createNotification } = useNotifications()
const { deleteWishlist } = useMultipleWishlist()

const emits = defineEmits<{
  'wishlist-deleted': [success: boolean]
}>()
const props = defineProps<{
  wishlistTitle: string
  wishlistToken: string
}>()

const handleConfirmDelete = async () => {
  try {
    await deleteWishlist(props.wishlistToken)

    createNotification({
      message: t('wishlistMultiple.wishlistDeleted', {
        title: props.wishlistTitle,
      }),
      showCloseButton: false,
      type: 'secondary',
    })

    emits('wishlist-deleted', true)
  } catch (err) {
    console.log('err', err)
    emits('wishlist-deleted', false)
  }
}
</script>

<template>
  <div>
    <div class="mb-6 text-xl font-bold">
      {{ $t('wishlistMultiple.deleteMyList') }}
    </div>

    <p class="mb-0">
      {{ $t('wishlistMultiple.confirmDelete', { title: wishlistTitle }) }}
    </p>

    <div class="mt-6 flex justify-end">
      <BaseButton
        class="w-full lg:w-auto"
        color="secondary"
        data-cy="confirm-delete-wishlist"
        @click="handleConfirmDelete"
      >
        {{ t('global.delete') }}
      </BaseButton>
    </div>
  </div>
</template>
