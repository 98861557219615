export const locales: {
  code: string
  iso: string
  name: string
}[] = [
  {
    code: 'en',
    iso: 'en-US',
    name: 'English',
  },
  {
    code: 'fr',
    iso: 'fr-FR',
    name: 'Français',
  },
]
