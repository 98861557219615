import { InputSelect } from '~/components/BaseForm/core/primitives'

const Country: typeof InputSelect = {
  ...InputSelect,
  attr: {
    ...InputSelect.attr,
    rules: 'required',
    labelTextTranslateKey: 'form.country',
    name: 'country',
    searchable: true,
  },
  containerInputClass: 'w-full md:w-1/2 md:pr-10',
}

export default Country
