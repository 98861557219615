<template>
  <div class="relative flex rounded border border-gray-200">
    <div class="animate-wave size-[88px] rounded-l"></div>
    <div
      class="flex w-[calc(100%-88px)] grow flex-col justify-center px-4 py-2"
    >
      <div class="animate-wave mb-2 h-4 w-10/12"></div>
      <div class="animate-wave mb-4 h-3 w-full"></div>
      <div class="animate-wave h-3 w-full"></div>
      <div class="absolute right-2 top-2">
        <BaseIcon
          name="heart"
          class="fill-gray-200 stroke-gray-200"
          :stroke-width="1"
          :size="1"
        />
      </div>
    </div>
  </div>
</template>
