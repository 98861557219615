<script setup lang="ts">
import type { DestinationGroup } from '~/types/header/megaMenuDestinations'

const { t, locale } = useI18n()
const { destinationGroups, linkGroups, getHref, sendTracking } =
  useMegaMenuDestinations()
const { setMenuIsOpen } = useHeaderMobile()

const { data: getDestinationsData } = await useFetch(
  `/api/${locale.value}/header-destinations`,
)

const selectedGroup = ref<DestinationGroup>()
const selectedChildDestination = ref<number>()

const buttonBackText = computed(() => {
  if (selectedChildDestination.value) {
    return (
      getDestinationsData.value?.find(
        (d) => d.id === selectedChildDestination.value,
      )?.name || ''
    )
  }

  return (
    destinationGroups.value.find((d) => d.key === selectedGroup.value)?.name ||
    ''
  )
})

const setSelectedGroup = (key?: DestinationGroup) => {
  if (key) {
    selectedGroup.value = key
    sendTracking(key)
  } else {
    selectedChildDestination.value = undefined
    selectedGroup.value = undefined
  }
}

const setSelectedChildDestination = (destinationId?: number) => {
  selectedChildDestination.value = destinationId
}

const handleBackButton = () => {
  if (selectedChildDestination.value) {
    setSelectedChildDestination()
  } else if (selectedGroup.value) {
    setSelectedGroup()
  }
}

const closeMenu = () => {
  setMenuIsOpen(false)
  setSelectedGroup()
  setSelectedChildDestination()
}
</script>

<template>
  <div class="px-4 py-8 tablet:px-12 tablet:py-10">
    <div :class="{ hidden: selectedGroup }">
      <div
        v-for="group in destinationGroups"
        :key="`group-${group.key}`"
        class="flex cursor-pointer items-center justify-between border-b border-gray-200 py-3"
        @click="setSelectedGroup(group.key)"
      >
        {{ group.name }}
        <BaseIcon name="chevronRight" />
      </div>
    </div>

    <div :class="{ hidden: !selectedGroup }">
      <button
        type="button"
        class="mb-4 flex w-full items-center font-bold"
        @click="handleBackButton"
      >
        <BaseIcon name="chevronLeft" class="mr-2" />
        {{ buttonBackText }}
      </button>

      <div :class="['mb-4', { hidden: selectedChildDestination }]">
        <NuxtLink
          v-for="group in linkGroups"
          :key="`link-group-${group.key}`"
          :class="[
            'font-bold text-gray-700',
            { hidden: group.key !== selectedGroup },
          ]"
          :to="group.link"
          @click.prevent="closeMenu"
        >
          {{
            t('headerDestination.seeAll', {
              desti: group.name?.trim(),
            })
          }}
        </NuxtLink>
      </div>

      <ul class="mb-0">
        <li
          v-for="destination in getDestinationsData"
          :key="`destination-${destination.id}`"
          :class="[{ hidden: destination.group !== selectedGroup }]"
        >
          <NuxtLink
            v-if="!destination?.children?.length"
            :to="getHref(destination.link)"
            :class="[
              'flex items-center justify-between border-b border-gray-200 py-3 text-gray-700',
              { hidden: selectedChildDestination },
            ]"
            @click.prevent="closeMenu"
          >
            {{ destination.name }}
            <BaseIcon
              v-if="destination?.children?.length"
              name="chevronRight"
            />
          </NuxtLink>
          <template v-else>
            <NuxtLink
              :class="[
                'font-bold text-gray-700',
                { hidden: destination.id !== selectedChildDestination },
              ]"
              :to="getHref(destination.link)"
              @click.prevent="closeMenu"
            >
              {{
                t('headerDestination.seeAll', {
                  desti: destination.name?.trim(),
                })
              }}
            </NuxtLink>

            <button
              :class="[
                'flex w-full items-center justify-between border-b border-gray-200 py-3',
                { hidden: selectedChildDestination },
              ]"
              type="button"
              @click="setSelectedChildDestination(destination.id)"
            >
              {{ destination.name }}
              <BaseIcon name="chevronRight" />
            </button>

            <ul
              :class="[
                'mb-0 mt-4',
                { hidden: destination.id !== selectedChildDestination },
              ]"
            >
              <li
                v-for="subRegion in destination.children"
                :key="`subRegion-${subRegion.id}`"
                :class="{ 'mb-4': subRegion.style === 'title' }"
              >
                <NuxtLink
                  :class="[
                    'inline-block py-1 text-gray-700',
                    {
                      'font-bold': subRegion.style === 'bold',
                      'font-monospace': subRegion.style === 'title',
                    },
                  ]"
                  :to="getHref(subRegion.link)"
                  @click.prevent="closeMenu"
                >
                  {{ subRegion.name }}
                </NuxtLink>

                <ul v-if="subRegion?.children?.length">
                  <li
                    v-for="region in subRegion.children"
                    :key="`region-${region.id}`"
                  >
                    <NuxtLink
                      :class="[
                        'inline-block py-1 text-gray-700',
                        {
                          'font-bold': region.style === 'bold',
                        },
                      ]"
                      :to="getHref(region.link)"
                      @click.prevent="closeMenu"
                    >
                      {{ region.name }}
                    </NuxtLink>
                  </li>
                </ul>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>
