<script setup lang="ts">
const { newIllustrationPaths, paths } = useIconPaths()

const props = withDefaults(
  defineProps<{
    color?: string
    filled?: boolean
    name: IconPath
    size?: number
  }>(),
  {
    color: '',
    size: 1.5,
  },
)

const viewBoxRegex = /^\[viewBox="(\d+ \d+ \d+ \d+)"\]/
const path = computed(() =>
  (Array.isArray(paths[props.name])
    ? (paths[props.name] as string[])
    : [paths[props.name] as string]
  ).filter(Boolean),
)
const hasViewBoxInPath = computed(() =>
  (path.value[0] ?? '').match(viewBoxRegex),
)
const strokeWidth = computed(() => {
  if (Object.keys(newIllustrationPaths).includes(props.name)) {
    return 0.8
  }

  return 24 / (props.size * 16)
})

const viewbox = computed(() => {
  if (hasViewBoxInPath.value?.[1]) {
    return hasViewBoxInPath.value?.[1]
  } else if (Object.keys(newIllustrationPaths).includes(props.name)) {
    return '0 0 32 32'
  }

  return '0 0 24 24'
})
const cleanPath = (path = '') => path.replace(viewBoxRegex, '')
</script>

<template>
  <svg
    :class="color"
    :style="{
      width: `${size}rem`,
      height: `${size}rem`,
    }"
    :stroke-width="strokeWidth"
    :fill="filled ? 'currentColor' : 'none'"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke="currentColor"
    :viewBox="viewbox"
  >
    <path v-for="p in path" :key="p" :d="cleanPath(p)" />
  </svg>
</template>
