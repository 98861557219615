export const constructQuery = (params) => {
  let query = ''

  Object.keys(params).forEach((p) => {
    let key = '?'

    if (params[p]) {
      if (query.length > 0) {
        key = '&'
      }

      if (Array.isArray(params[p])) {
        query += `${key}${params[p].map((x) => `${p}[]=${x}`).join('&')}`
      } else {
        query += `${key}${p}=${params[p]}`
      }
    }
  })

  return query
}
