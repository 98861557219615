import { GetUserWishlistsRepository } from "./interfaces/GetUserWishlistsRepository";
import { GetUserWishlistsPresentation } from "./interfaces/GetUserWishlistsPresentation";
import { Wishlists } from "../../domain/Wishlists";

export default class GetUserWishlistsUseCase {
  constructor(private repository: GetUserWishlistsRepository) {}

  async execute(presenter: GetUserWishlistsPresentation) {
    const response = await this.repository.getWishlists();

    if (response?.data) {
      const wishlists = new Wishlists(response.data).build();
      presenter.displayWishlists(wishlists);
    } else {
      presenter.displayWishlists([]);
    }
  }
}
