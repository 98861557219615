import { InputFile } from '~/components/BaseForm/core/primitives'

const ContactFile: typeof InputFile = {
  ...InputFile,
  attr: {
    ...InputFile.attr,
    accept: '.pdf',
    rules: 'required',
    labelTranslateKey: 'form.pdfContract',
    name: 'contract',
  },
  containerInputClass: 'w-full md:w-1/2 md:pr-10',
}

export default ContactFile
