import { AutomaticProposalsVM } from "./ViewModel";
import { GetAutomaticProposalsPresentation } from "../usecases/GetAutomaticProposals/interfaces/GetAutomaticProposalsPresentation";
import Presenter from "../../../abstracts/Presenter";
import { CreateAutomaticProposalCommentPresentation } from "../usecases/CreateAutomaticProposalComment/interfaces/CreateAutomaticProposalCommentPresentation";
import { ModifyAutomaticProposalCommentPresentation } from "../usecases/ModifyAutomaticProposalComment/interfaces/ModifyAutomaticProposalCommentPresentation";
import {
  AutomaticProposalCommentFormatted,
  GetAutomaticProposalsDataSorted,
} from "../../../types/common/automaticProposal";

export default class AutomaticProposalsPresenter
  extends Presenter<AutomaticProposalsVM>
  implements
    CreateAutomaticProposalCommentPresentation,
    ModifyAutomaticProposalCommentPresentation,
    GetAutomaticProposalsPresentation
{
  constructor() {
    super(new AutomaticProposalsVM());
  }

  displayMessage(msg: string): void {
    this.vm.msg = msg;
    this.notifyVM();
  }

  displayAutomaticProposals(data?: GetAutomaticProposalsDataSorted): void {
    this.vm.autoProposals = data;
  }

  displayAutomaticProposalCommentCreated(
    comment: AutomaticProposalCommentFormatted
  ): void {
    this.vm.automaticProposalCommentCreated = comment;
    this.notifyVM();
  }

  displayAutomaticProposalCommentUpdated(
    comment: AutomaticProposalCommentFormatted
  ): void {
    this.vm.automaticProposalCommentUpdated = comment;
    this.notifyVM();
  }
}
