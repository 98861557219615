export default (clientHttp, apiVersion) => ({
  read(query, params) {
    // Index
    if (Array.isArray(query)) {
      const ids = `?ids=${query.join(',')}`

      return clientHttp.get(`${apiVersion}/destinations${ids}`, {
        ...params,
      })
    }

    // Show
    return clientHttp.get(`${apiVersion}/destinations/${query}`, {
      ...params,
    })
  },
})
