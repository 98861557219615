import type { ConditionsField, Rules } from '~/components/BaseForm/types'

type InputFile = {
  inputType: 'file-input'
  infoText?: string
  conditions?: ConditionsField
  containerInputClass?: string
  hidden?: boolean
  viewModeRow?: boolean
  order?: number
  attr: {
    accept: 'image/*, .pdf' | '.pdf' | '.jpg, .jpeg, .png'
    disabled: boolean
    hasBorder?: boolean
    infoText?: string
    inputHtml?: string
    labelHtml?: string
    labelTranslateKey: string
    name: string
    placeholder?: string
    placeholderTranslateKey?: string
    rules?: Rules
    showCurrentFile?: boolean
    type: 'file'
    wrapperClass?: string
  }
}

const inputFile: InputFile = {
  inputType: 'file-input',
  attr: {
    accept: 'image/*, .pdf',
    labelTranslateKey: 'form.label',
    name: 'file',
    type: 'file',
    rules: 'required',
    disabled: false,
  },
}

export default inputFile
