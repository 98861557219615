export const useURL = () => {
  const config = useRuntimeConfig()

  const getBaseURL = () => {
    return config.public.baseUrl
  }

  const getRootURL = () => {
    if (config.public.appDomain === 'development') {
      return 'http://'
    }

    return 'https://'
  }

  const getApiURL = ({ locale = '', path = '' }) => {
    const rootUrl = getRootURL()

    let url = `${rootUrl}${config.public.apiHost}:${config.public.apiPort}`

    if (locale) {
      url += `/${locale}`
    }

    if (path) {
      if (path.includes('/')) {
        path = path.replace('/', '')
      }
      url += `/${path}`
    }

    return url
  }

  return {
    getApiURL,
    getRootURL,
    getBaseURL,
  }
}
