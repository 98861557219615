import { InputText } from '~/components/BaseForm/core/primitives'

const CompanyName: typeof InputText = {
  ...InputText,
  attr: {
    ...InputText.attr,
    rules: 'required',
    labelTranslateKey: 'form.companyName',
    name: 'companyName',
  },
  containerInputClass: 'w-full md:w-1/2 md:pr-10 self-end',
}

export default CompanyName
