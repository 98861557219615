import { InputText } from '~/components/BaseForm/core/primitives'

const PostalCode: typeof InputText = {
  ...InputText,
  attr: {
    ...InputText.attr,
    rules: 'required',
    labelTranslateKey: 'form.postalCode',
    name: 'postalCode',
    pattern: '[0-9]*',
    inputmode: 'numeric',
  },
  containerInputClass: 'w-full md:w-1/4 md:pr-10',
}

export default PostalCode
