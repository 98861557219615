export default (clientHttp, apiVersion) => ({
  signOut() {
    return clientHttp.delete(`${apiVersion}/auth/sign_out`)
  },

  /**
   *
   * @returns {Promise<{ data: import('~/types/types').Auth['user']}}>}
   */
  validateToken() {
    return clientHttp.get(`${apiVersion}/auth/validate_token`)
  },
})
