import { UserRepositoryType } from "../../types";
import Controller from "./adapters/Controller";
import Presenter from "./adapters/Presenter";
import {
  GetClientContractUseCase,
  ConfirmClientContractUseCase,
  VerifyIdentityDocumentUseCase,
  SendClientContractInformationUseCase,
  PrepareClientContractInformationUseCase,
} from "./usecases";
import { DocVerifier } from "lc-repository";

export const clientContractBuild = ({
  repository,
}: {
  repository: {
    userRepository: UserRepositoryType;
    docVerifierRepository: DocVerifier;
  };
}) => {
  const presenter = new Presenter();
  const controller = new Controller(
    {
      getClientContract: new GetClientContractUseCase({
        getClientContract:
          repository.userRepository.clientContracts.getClientContract,
      }),
      confirmClientContract: new ConfirmClientContractUseCase({
        confirmClientContract:
          repository.userRepository.clientContracts.confirmClientContract,
      }),
      verifyIdentityDocument: new VerifyIdentityDocumentUseCase({
        verifyIdentityCard: async (file) =>
          repository.docVerifierRepository.verifyIdentityCard(file),
        verifyPassport: async (file) =>
          repository.docVerifierRepository.verifyPassport(file),
      }),
      sendClientContractInformation: new SendClientContractInformationUseCase({
        sendClientContractInformation:
          repository.userRepository.clientContracts
            .sendClientContractInformation,
      }),
      prepareClientContractInformation:
        new PrepareClientContractInformationUseCase(),
    },
    presenter
  );

  return {
    controller,
    presenter,
  };
};
