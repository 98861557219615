<script setup lang="ts">
const { getApiURL } = useURL()

const props = withDefaults(
  defineProps<{
    value?: string
  }>(),
  {
    value: '',
  },
)

const baseUrl = `${getApiURL({})}/`
const urlFormated = computed(() =>
  props.value ? `${baseUrl}${String(props.value)?.substring(1)}` : '',
)
</script>

<template>
  <base-button
    v-if="urlFormated"
    :href="urlFormated"
    target="_blank"
    variant="link"
  >
    {{ $t('downloadTheFile') }}
  </base-button>
  <span v-else>/</span>
</template>
