import { InputText } from '~/components/BaseForm/core/primitives'

const Iban: typeof InputText = {
  ...InputText,
  attr: {
    ...InputText.attr,
    rules: 'required|min:14|max:50',
    labelTranslateKey: 'form.iban',
    name: 'iban',
  },
  containerInputClass: 'w-full md:w-1/2',
}

export default Iban
