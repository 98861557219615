<script setup lang="ts">
import type { ColSizes, OffsetSizes } from '~/helpers/utils/sizes'

const props = withDefaults(
  defineProps<{
    cols?: ColSizes
    sm?: ColSizes
    md?: ColSizes
    lg?: ColSizes
    xl?: ColSizes
    xxl?: ColSizes
    offsetMd?: OffsetSizes
    offsetLg?: OffsetSizes
  }>(),
  {
    cols: '',
    sm: '',
    md: '',
    lg: '',
    xl: '',
    xxl: '',
    offsetMd: '',
    offsetLg: '',
  },
)
defineSlots<{ default(): any }>()

const computedClass = computed(() => {
  let computedClass = ['relative lc-col']
  const cols = props.cols ? `w-${props.cols}/24` : ''
  const sm = props.sm ? `sm:w-${props.sm}/24` : ''
  const md = props.md ? `md:w-${props.md}/24` : ''
  const lg = props.lg ? `lg:w-${props.lg}/24` : ''
  const xl = props.xl ? `xl:w-${props.xl}/24` : ''
  const xxl = props.xxl ? `xxl:w-${props.xxl}/24` : ''
  const offsetMd = props.offsetMd ? `md:ml-offset-${props.offsetMd}` : ''
  const offsetLg = props.offsetLg ? `lg:ml-offset-${props.offsetLg}` : ''

  if (+props.offsetMd > 3 || +props.offsetLg > 3) {
    // @todo: wtf??? either throw or do nothing!!
    return new Error('This offset doesnt exist')
  }

  computedClass.push(cols, sm, md, lg, xl, xxl, offsetMd, offsetLg)
  computedClass = computedClass.filter((c) => c.length)

  if (computedClass.length === 1) {
    return ['lc-col lc-col-auto']
  }

  return computedClass
})
</script>

<template>
  <div :class="computedClass">
    <slot />
  </div>
</template>
