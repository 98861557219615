import {
  HousePhotoMeta,
  HousePhotoResponse,
} from "../usecases/GetHousePhotos/types";
import { HousePhotosAttributesFormatted } from "../../../types";

export class HousePhotos {
  private housePhotosFormatted: HousePhotosAttributesFormatted;

  constructor(
    private housePhotosResponse: {
      data: HousePhotoResponse[];
      meta: HousePhotoMeta;
    }
  ) {
    this.housePhotosFormatted = {
      photos: this.housePhotosResponse.data,
      totalCount: housePhotosResponse.meta.total_count,
      totalPages: housePhotosResponse.meta.total_pages,
    };
  }

  build() {
    return this.housePhotosFormatted;
  }
}
