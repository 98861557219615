<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    aspectRatio?: string
    cover?: boolean
    sizes?: string
  }>(),
  {
    aspectRatio: '',
    cover: true,
    sizes: '',
  },
)
let attrs = useAttrs() as {
  width: number
  height: number
  src: string
  format: string
}

if (!attrs?.src?.includes('svg')) {
  attrs = {
    ...attrs,
    format: 'webp',
  }
}

const placeholder = computed(() => {
  const blur = 5
  const quality = 70
  const [w, h] = props.aspectRatio?.split('/').map((x) => Number(x)) || []

  if (props.aspectRatio) {
    return [Math.round(w * 25), Math.round(h * 25), quality, blur]
  } else if (attrs.width && attrs.height) {
    return [
      Math.round(attrs.width / 10),
      Math.round(attrs.height / 10),
      quality,
      blur,
    ]
  } else if (props.sizes) {
    return [30, 30, quality, blur]
  }

  console.warn('BaseNuxtImg: Missing placeholder size')

  return []
})

const sizesComputed = computed(() => {
  if (attrs.width || attrs.height) return undefined
  if (props.sizes) return props.sizes

  return 'sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw'
})

const hasImage = computed(() => {
  return Boolean(attrs.src)
})

const src = computed(() => {
  if (attrs.src) return attrs.src

  return 'production/assets/images/placeholder-badge.svg'
})
</script>

<template>
  <!-- preload -->
  <NuxtImg
    v-if="hasImage"
    v-bind="$attrs"
    :src="src"
    densities="x1 x2"
    :class="[
      'lc-image',
      cover ? 'size-full object-cover' : 'mx-auto block object-contain',
    ]"
    data-cy="base-nuxt-img"
    :sizes="sizesComputed"
    :placeholder="cover ? placeholder : false"
  />
  <span
    v-else
    class="base-nuxt-img--placeholder flex aspect-square h-full items-center justify-center bg-gray-100 p-2"
  >
    <NuxtImg
      v-bind="$attrs"
      :src="src"
      densities="x1 x2"
      height="100"
      width="100"
      data-cy="base-nuxt-img-placeholder"
      :sizes="sizesComputed"
      :placeholder="placeholder"
    />
  </span>
</template>

<style>
.base-nuxt-img--placeholder img {
  @apply max-h-[100px];
}
</style>
