import {
  QueryModelType,
  Locale,
  SupportedCurrencies,
  Mode,
} from "../../../../../types";

import { SearchHousesWithoutDatesV2Repository } from "./interfaces/SearchHousesWithoutDatesV2Repository";
import { SearchHousesPresentation } from "../interfaces/SearchHousesPresentation";
import { GetSearchParamsType } from "../types";

import { Search } from "../../../domain";
import { PRICES } from "../../../domain/helpers";
import { SearchType } from "../../../../../services/algolia";
import { SearchRepository } from "../SearchHousesWithDates/interfaces/SearchHousesWithDatesRepository";

export default class SearchHousesWithoutDatesV2UseCase extends Search {
  constructor(private repository: SearchHousesWithoutDatesV2Repository) {
    super();
  }

  async getSearchHousesInfo(
    input: GetSearchParamsType,
    deviceMode: "mobile" | "web",
    mode: Mode,
    repository: SearchRepository,
    locale?: Locale,
    algoliaFilters?: string
  ) {
    const searchParams = this.getSearchParams(input, deviceMode, mode, locale);

    return await this.getSearchResultByPage({
      ...searchParams,
      algoliaFilters,
      hitsPerPage: input.hitsPerPage,
      mode,
      page: input.page,
      repository,
    });
  }

  async execute(
    presenter: SearchHousesPresentation,
    {
      bathrooms = "",
      bedrooms = "",
      capacity = "",
      destinationIds = [],
      housesIds = [],
      facetFilters = new Map(),
      initializelist = false,
      itemsPerPage = 15,
      page = 0,
      prices = [PRICES.MIN_PRICE_CHOICE, PRICES.MAX_PRICE_CHOICE],
      query = "",
      replica = "recommended",
      mode = "client",
      algoliaFilters = "",
    }: QueryModelType,
    deviceMode: "mobile" | "web",
    locale: Locale,
    currency: SupportedCurrencies | null,
    algoliaIds: SearchType
  ) {
    try {
      const { algolia } = this.repository;
      const repository = {
        algolia: algolia(algoliaIds),
      } as SearchRepository;
      this.setCurrentPage(page);
      this.setHitsPerPage(itemsPerPage);
      this.setCurrency(currency || undefined);
      this.setLocale(locale);

      const input = {
        bathrooms,
        bedrooms,
        capacity,
        currency: this.currency,
        destinationIds,
        hitsPerPage: itemsPerPage,
        housesIds,
        facetFilters,
        initializelist,
        maxPrice: prices[1] || PRICES.MAX_PRICE_CHOICE,
        minPrice: prices[0] || PRICES.MIN_PRICE_CHOICE,
        page,
        query,
        replicaValue: replica,
        mode,
        deviceMode,
        algoliaFilters,
      };

      const { hits, nbHits, nbPages, facets, queryID } =
        await this.getSearchHousesInfo(
          { ...input },
          deviceMode,
          mode,
          repository,
          locale,
          algoliaFilters
        );

      if (initializelist) {
        this.initializeList();
        this.setCurrentPage(page);

        this.facetsFilters = facets;
        this.setNbHits(nbHits);
        this.setNbPages(nbPages);

        presenter.displayFacets(this.facetsFilters);
        presenter.displayNbPages(this.nbPages);
        presenter.displayNbHouses(this.nbHits);
      }

      this.currentHits = [...this.currentHits, ...hits];

      presenter.displaySearchCurrentPage(this.currentPage);
      presenter.displaySearchHousesResult(this.currentHits);
      presenter.displayAlgoliaQueryId(queryID);
    } catch (err) {
      console.log("SearchHousesWithoutDatesV2UseCase", err);
    }
  }
}
