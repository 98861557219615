import type { CoreGroupFields, Field } from '~/components/BaseForm/types'

export const useConfigForm = (
  groupFieldsCopy: Ref<CoreGroupFields[]>,
  configFormMutate: Ref,
): void => {
  groupFieldsCopy.value.map((groupField) => {
    // groupsFields
    if (groupField.key) {
      const valuesGroupFields: CoreGroupFields =
        configFormMutate.value?.groupFields?.[groupField.key]

      if (valuesGroupFields) {
        const groupeFieldsKeys = Object.keys(valuesGroupFields) as Array<
          keyof CoreGroupFields
        >
        groupeFieldsKeys.forEach((key) => {
          // We want assigne a Ref type to a string attribute. It was never happened for typescript
          groupField[key] = toRef(valuesGroupFields, key) as never
        })
      }
    }

    // container classes
    const containerClasses: Array<keyof CoreGroupFields> = [
      'containerGroupClass',
      'containerGroupFieldsClass',
    ]

    containerClasses.forEach((containerClass) => {
      if (
        groupField?.[containerClass] &&
        configFormMutate.value?.[containerClass]
      ) {
        const valueField: CoreGroupFields = configFormMutate.value
        groupField[containerClass] = toRef(valueField, containerClass) as never
      }
    })

    // fields
    groupField.fields.map((field) => {
      const valueField: Field =
        configFormMutate.value?.fields?.[field.attr.name]

      if (valueField) {
        const keysAll = Object.keys(valueField) as Array<keyof Field>

        keysAll.forEach((key) => {
          if (key === 'attr') {
            const attrKeys = Object.keys(valueField.attr) as Array<
              keyof Field['attr']
            >
            attrKeys.forEach((attrKey) => {
              // We want assigne a Ref type to a string attribute. It was never happened for typescript
              field.attr[attrKey] = toRef(valueField.attr, attrKey) as never
            })
          } else {
            // We want assigne a Ref type to a string attribute. It was never happened for typescript
            field[key] = toRef(valueField, key) as never
          }
        })
      }

      return field
    })

    // order fields
    if (groupField.fields.some((field) => field.order)) {
      groupField.fields.sort((a, b) => {
        if (a.order && b.order) {
          return a.order - b.order
        }

        return 0
      })
    }

    return groupField
  })
}
