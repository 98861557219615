export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', {
    mounted(el, { value }) {
      el.clickOutsideEvent = function (event) {
        // check if click is outside of el and his children
        if (!(el === event.target || el.contains(event.target))) {
          // call method provided in attribute value
          value()
        }
      }

      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  })
})
