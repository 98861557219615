import { constructQuery } from '~/repository/config/constructQuery'

export default (clientHttp, apiVersion) => ({
  read(id, params) {
    // Index
    if (Array.isArray(id)) {
      const ids = ''

      return clientHttp.get(`${apiVersion}/house_periods${ids}`, {
        ...params,
      })
    }

    // Show
    return clientHttp.get(`${apiVersion}/house_periods/${id}`, {
      ...params,
    })
  },

  getHousePeriods(params) {
    const query = constructQuery(params)

    return clientHttp.get(`${apiVersion}/house_periods${query}`)
  },
})
