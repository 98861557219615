import { InputText } from '~/components/BaseForm/core/primitives'

const City: typeof InputText = {
  ...InputText,
  attr: {
    ...InputText.attr,
    rules: 'required',
    labelTranslateKey: 'form.city',
    name: 'city',
  },
  containerInputClass: 'w-full md:w-1/4',
}

export default City
