import { createQueryV3 } from '@/repository/config/createQueryV3'

export default (clientHttp, apiVersion) => ({
  read(houseId, areaGroupId, includes, otherQuery) {
    const query = createQueryV3({ includes, otherQuery })

    return clientHttp.get(
      `${apiVersion}/houses/${houseId}/area_groups/${areaGroupId}/areas${query}`,
    )
  },
})
