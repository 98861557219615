import algoliasearch from "algoliasearch";

import { IndexName, QueryParamsType } from "../types";

export type SearchType = {
  appId: string;
  apiKey: string;
  prefix: "staging" | "production";
};

export type AlgoliaReturnType = (
  input: SearchType
) => ReturnType<typeof algolia>;

export type AlogliaType = ReturnType<typeof algolia>;

export const algolia = ({ appId, apiKey, prefix }: SearchType) => {
  const client = algoliasearch(appId, apiKey);

  const index = (indexName: IndexName) =>
    client.initIndex(`${prefix}_${indexName}`);

  const multipleIndices = <T>(queries: QueryParamsType[]) =>
    client.multipleQueries<T>(
      queries.map((query) => ({
        ...query,
        indexName: `${prefix}_${query.indexName}`,
      }))
    );

  return {
    multiQuery: async <T>(queries: QueryParamsType[]) => {
      return multipleIndices<T>(queries).then((res) => res.results);
    },
    simpleQuery: <T>(query: QueryParamsType) => {
      return index(query.indexName).search<T>(query.query, {
        ...query.params,
        clickAnalytics: true,
        typoTolerance: false,
      });
    },
    browseQuery: async <T>(query: QueryParamsType) => {
      let hits: T[] = [];

      await index(query.indexName).browseObjects<T>({
        ...query.params,
        batch: (batch) => {
          hits = hits.concat(batch);
        },
        clickAnalytics: true,
      });

      return hits;
    },
  };
};
