import { GetTopDestinationsRepository } from "./interfaces/GetTopDestinationsRepository";
import { GetTopDestinationsPresentation } from "./interfaces/GetTopDestinationsPresentation";
import { Locale, MutilpleIndicesGenericType } from "../../../../types";
import { SearchType } from "../../../../services/algolia";

export default class GetTopDestinationsUseCase {
  constructor(private repository: GetTopDestinationsRepository) {}

  private topDestinations = [84, 17, 101, 40, 27, 70];

  private getTopDestinationsIdsFilter() {
    return this.topDestinations.map((id) => `id=${id}`).join(" OR ");
  }

  async execute(
    presenter: GetTopDestinationsPresentation,
    locale: Locale,
    algoliaIds: SearchType
  ) {
    const { simpleQuery } = this.repository.algolia(algoliaIds);
    await simpleQuery<MutilpleIndicesGenericType>({
      indexName: "public_destinations",
      query: "",
      params: {
        filters: this.getTopDestinationsIdsFilter(),
      },
    }).then(({ hits }) => {
      const topDestinationsResult = hits.map((hit) => ({
        name: hit.clusterized_name[locale],
        id: hit.id,
      }));

      presenter.displayTopDestinationsResult(topDestinationsResult);
    });
  }
}
