import type { HouseAvailable } from 'lc-services/types'
import type { WishlistDetailsFormatted } from '~/types/wishlist'

type StateMultipleWishlistSummary = {
  summaryOpen: boolean
  wishlistActive: WishlistDetailsFormatted | null
  wishlistActiveLoading: boolean
  wishlistHouseAvailables: HouseAvailable[]
  wishlistHouseAvailablesLoading: boolean
}

export const useMultipleWishlistSummary = () => {
  const { $lcServicesWishlist } = useNuxtApp()
  const { wishlistController, wishlistPresenter } = $lcServicesWishlist

  const stateMultipleWishlistSummary = useState<StateMultipleWishlistSummary>(
    'state-multiple-wishlist-summary',
    () => ({
      summaryOpen: false,
      wishlistActive: null,
      wishlistActiveLoading: false,
      wishlistHouseAvailables: [],
      wishlistHouseAvailablesLoading: false,
    }),
  )

  const summaryOpen = computed(
    () => stateMultipleWishlistSummary.value.summaryOpen,
  )
  const loading = computed(
    () =>
      stateMultipleWishlistSummary.value.wishlistHouseAvailablesLoading ||
      stateMultipleWishlistSummary.value.wishlistActiveLoading,
  )
  const wishlistActive = computed(
    () => stateMultipleWishlistSummary.value.wishlistActive,
  )

  const housesAvailable = computed(() => {
    return (
      wishlistActive.value?.houses
        .filter((h) =>
          stateMultipleWishlistSummary.value.wishlistHouseAvailables
            .map((house) => house.id)
            .includes(h.id),
        )
        .map((h) => {
          return {
            ...h,
            price:
              stateMultipleWishlistSummary.value.wishlistHouseAvailables.find(
                (house) => house.id === h.id,
              )?.price,
          }
        }) || []
    )
  })

  const housesUnavailable = computed(() => {
    return (
      wishlistActive.value?.houses.filter(
        (h) =>
          !stateMultipleWishlistSummary.value.wishlistHouseAvailables
            .map((house) => house.id)
            .includes(h.id),
      ) || []
    )
  })

  const fetchWishlist = async (token: string) => {
    stateMultipleWishlistSummary.value.wishlistActiveLoading = true

    await wishlistController.getUserWishlist(token)

    setWishlistActive(wishlistPresenter.vm.wishlist)

    stateMultipleWishlistSummary.value.wishlistActiveLoading = false
  }

  const fetchAvailaibilityHouses = async (
    token: string,
    checkIn: string,
    checkOut: string,
  ) => {
    stateMultipleWishlistSummary.value.wishlistHouseAvailablesLoading = true

    await wishlistController.getUserWishlistHouseAvailables({
      privateToken: token,
      checkIn,
      checkOut,
    })

    stateMultipleWishlistSummary.value = {
      ...stateMultipleWishlistSummary.value,
      wishlistHouseAvailables: wishlistPresenter.vm.houseAvailables || [],
      wishlistHouseAvailablesLoading: false,
    }
  }

  const setSummaryOpen = (open: boolean) =>
    (stateMultipleWishlistSummary.value.summaryOpen = open)

  const setWishlistActive = (wishlistActive: WishlistDetailsFormatted | null) =>
    (stateMultipleWishlistSummary.value.wishlistActive = wishlistActive)

  return {
    fetchWishlist,
    fetchAvailaibilityHouses,
    housesAvailable,
    housesUnavailable,
    loading,
    setSummaryOpen,
    setWishlistActive,
    summaryOpen,
    wishlistActive,
  }
}
