import { createQueryV3 } from '~/repository/config/createQueryV3'

export const getDestinations = ({
  apiVersion,
  clientHttp,
  fields,
  id,
  includes,
  params,
  slug,
}) => {
  if (id) {
    if (Array.isArray(id) && id.length) {
      const uniqIds = [...new Set(id)]
      const query = createQueryV3({ id: uniqIds, fields, includes })

      return clientHttp.get(`${apiVersion}/destinations${query}`, {
        params: { ...params },
      })
    } else if (id) {
      const query = createQueryV3({ fields, includes })

      return clientHttp.get(`${apiVersion}/destinations/${id}${query}`, {
        params: { ...params },
      })
    }
  }

  if (slug) {
    const query = createQueryV3({ fields, includes })

    return clientHttp.get(`${apiVersion}/destinations/${slug}${query}`, {
      params: { ...params },
    })
  }

  const query = createQueryV3({ fields, includes })

  return clientHttp.get(`${apiVersion}/destinations${query}`, {
    params: { ...params },
  })
}
