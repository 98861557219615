export const cleanSpace = (text = '') => {
  return text.replace(/[?\s]/g, '')
}

export const cleanCaracter = (text = '') => {
  return text.replace(/[?#{}?[?\]]/g, '').trim()
}

export const cleanString = (text: string) => {
  return cleanSpace(cleanCaracter(text))
}

const splitText = (text = '', split = '') => {
  const splitResult = text.split(`${split}:`)
  const classString = splitResult[1]

  return {
    v: cleanCaracter(classString),
    txt: splitResult[0],
  }
}

type HtmlStringParams = {
  name: string
  params?: { id: string }
}
type HtmlStringType = 'text' | 'icon' | 'link' | 'routerLink' | ''
type HtmlString<T extends HtmlStringType = HtmlStringType> = {
  class?: string
  position: number
  type: T
  value: T extends 'icon' ? IconPath : string
  attrs?: {
    class?: string
    href?: string
    to?: HtmlStringParams
    target?: string
  }
}

const createStrHtml = (
  strIndex: number,
  str: string,
  type: HtmlStringType,
): HtmlString<HtmlStringType> => {
  let obj: HtmlString<HtmlStringType> = {
    type: '',
    value: '',
    position: strIndex,
    class: '',
  }
  let value = ''

  value = cleanCaracter(str).replace(`${type}:`, '')

  obj = {
    ...obj,
    type,
    value: cleanString(value),
  }

  if (value.includes('class')) {
    const { v, txt } = splitText(value, 'class')

    obj = {
      ...obj,
      value: txt,
      attrs: { class: v },
    }
  }

  if (obj.value.includes('href')) {
    if (obj.value.includes('href_blank')) {
      const { v, txt } = splitText(obj.value, 'href_blank')

      obj = {
        ...obj,
        value: txt,
        attrs: { ...obj.attrs, href: v, target: '_blank' },
      }
    } else {
      const { v, txt } = splitText(obj.value, 'href')
      obj = {
        ...obj,
        value: txt,
        attrs: { ...obj.attrs, href: v },
      }
    }
  }

  if (obj.value.includes('to')) {
    const { v, txt } = splitText(obj.value, 'to')
    obj = {
      ...obj,
      value: txt,
      attrs: { ...obj.attrs, to: { name: v } },
    }

    if (v.includes('params')) {
      const { v: paramsValue, txt: txtValue } = splitText(v, 'params')

      if (obj?.attrs?.to) {
        obj.attrs = {
          ...obj.attrs,
          to: {
            name: txtValue,
            params: { id: paramsValue },
          },
          target: '_blank',
        }
      }
    }
  }

  return obj
}

export const stringToHtmlElm = (htmlString: string) => {
  const strHtml: HtmlString[] = []
  const strs = htmlString.split(',')

  strs.forEach((str, strIndex) => {
    if (str.includes('text:')) {
      strHtml.push(createStrHtml(strIndex, str, 'text'))
    } else if (str.includes('icon:')) {
      strHtml.push(createStrHtml(strIndex, str, 'icon'))
    } else if (str.includes('link:')) {
      strHtml.push(createStrHtml(strIndex, str, 'link'))
    } else if (str.includes('routerLink:')) {
      strHtml.push(createStrHtml(strIndex, str, 'routerLink'))
    }
  })

  return strHtml
}
