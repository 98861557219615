import { ConfirmClientContractRepository } from './interfaces/ConfirmClientContractRepository'
import { ConfirmClientContractPresentation } from './interfaces/ConfirmClientContractPresentation'

export default class ConfirmClientContractUseCase {
  constructor(private repository: ConfirmClientContractRepository) {}

  async execute(
    presenter: ConfirmClientContractPresentation,
    input: { isConditionsChecked: boolean; clientContractId: string }
  ) {
    presenter.displayToast(null)
    if (!input.isConditionsChecked) {
      presenter.displayToast({
        type: 'error',
        msg: 'clientContract.clientContractSummary.toast.confirmTermsAndConditions',
      })
      return
    }

    await this.repository.confirmClientContract({
      clientContractId: input.clientContractId,
    })
  }
}
