import { LocalizedString } from "lc-repository/dist/entities";
import {
  AutomaticProposalsDataSorted,
  GetAutomaticProposalsDataFormatted,
} from "../../../types";

export class AutomaticProposalSorted {
  private automaticProposalHousesSorted: AutomaticProposalsDataSorted[] = [];

  constructor(
    private proposalDataFormatted: GetAutomaticProposalsDataFormatted
  ) {
    if (this.proposalDataFormatted.sortedBy === "destination") {
      this.automaticProposalHousesSorted = this.groupByDestination(
        this.proposalDataFormatted
      );
    } else if (this.proposalDataFormatted.sortedBy === "date") {
      this.automaticProposalHousesSorted = this.groupByDate(
        this.proposalDataFormatted
      );
    } else {
      this.automaticProposalHousesSorted = [
        {
          houses: this.proposalDataFormatted.automaticProposalHouses,
        },
      ];
    }
  }
  private groupByDestination(data: GetAutomaticProposalsDataFormatted) {
    const groupedData = data.automaticProposalHouses.reduce((acc, curr) => {
      const key = curr.houseDetails.destination?.en ?? "Unknown";

      if (acc[key]) {
        acc[key]?.houses.push(curr);
      } else {
        acc[key] = {
          destination: {
            fr: curr.houseDetails.destination?.fr as string,
            en: curr.houseDetails.destination?.en as string,
          },
          houses: [curr],
        };
      }

      return acc;
    }, {} as Record<string, { destination: LocalizedString; houses: GetAutomaticProposalsDataFormatted["automaticProposalHouses"] }>);
    return Object.values(groupedData);
  }

  private groupByDate(data: GetAutomaticProposalsDataFormatted) {
    const groupedData = data.automaticProposalHouses.reduce((acc, curr) => {
      const key = `${new Date(curr.check_in_date).toISOString()}-${new Date(
        curr.check_out_date
      ).toISOString()}`;

      if (acc[key]) {
        acc[key]?.houses.push(curr);
      } else {
        acc[key] = {
          startDate: new Date(curr.check_in_date).toISOString(),
          endDate: new Date(curr.check_out_date).toISOString(),
          houses: [curr],
        };
      }

      return acc;
    }, {} as Record<string, { startDate: string; endDate: string; houses: GetAutomaticProposalsDataFormatted["automaticProposalHouses"] }>);
    return Object.values(groupedData);
  }

  build() {
    return this.automaticProposalHousesSorted;
  }
}
