import { ApiError } from "lc-repository/dist/repositories/types";
import FormError from "../../../services/GenericFormError";

export default class SignInFormError extends FormError {
  constructor() {
    super();
  }

  accountNotComplete(errors?: ApiError) {
    const error = errors?.response.data.errors as { user: string }[];
    if (errors && error[0]["user"] === "incomplete_signup") {
      this.errors = [{ fieldName: "email", error: "incompleteSignup" }];
    }
    return this;
  }

  emailWrongFormat(email: string, fieldName?: string) {
    if (this.testEmailFormat(email) === "notEmail") {
      this.errors = [
        { fieldName: fieldName ? fieldName : "email", error: "wrongFormat" },
      ];
    }

    return this;
  }

  emailTaken() {
    this.errors = [
      {
        fieldName: "token",
        error: "userAccountInfo.userInformation.emailTaken",
      },
    ];
    return this;
  }

  emailNotFound(errors?: ApiError) {
    const error = errors?.response.data.errors as { user: string }[];
    if (errors && error[0]["user"] === "not_found") {
      this.errors = [{ fieldName: "email", error: "notFound" }];
    }
    return this;
  }

  emailLoked(errors?: ApiError) {
    const error = errors?.response.data.errors as { user: string }[];
    if (errors && error[0]["user"] === "locked") {
      this.errors = [{ fieldName: "email", error: "locked" }];
    }
    return this;
  }

  emailInvalid(errors?: ApiError) {
    const error = errors?.response.data.errors as {
      user: { email: { error: string }[] };
    }[];
    if (error && error?.[0]?.user?.email?.[0]?.error === "invalid") {
      this.errors = [{ fieldName: "email", error: "invalid" }];
    }
    return this;
  }

  build() {
    return [...this.errors];
  }
}
