<script setup lang="ts">
import { $dayjs } from '@/utils/dayjs'

const props = withDefaults(
  defineProps<{
    value?: { startDate: string; endDate: string }
  }>(),
  {
    value: () => ({ startDate: '', endDate: '' }),
  },
)

const { locale } = useI18n()

const formatDate = locale.value === 'fr' ? 'DD-MM-YYYY' : 'YYYY-MM-DD'

const startDate = $dayjs(props.value.startDate).format(formatDate)
const endDate = $dayjs(props.value.endDate).format(formatDate)
</script>

<template>
  <div class="flex items-center">
    {{ startDate }} <BaseIcon class="mx-2" name="arrowRight" /> {{ endDate }}
  </div>
</template>
