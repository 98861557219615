// Generate input masks for vue-the-mask

const alphanumericChar = '#'
const ibanMin = 14
const ibanMax = 50
const ibanMasks: string[] = []
const bicMin = 8
const bicMax = 11
const bicMasks: string[] = []
const dateMask = '##/##/####'
const siretMask = '### ### ### #####'

for (let i = ibanMin; i <= ibanMax; i++) {
  const string = alphanumericChar.repeat(i)

  const array = string.match(/#{1,4}/g)
  if (array?.length) ibanMasks.push(array.join(' '))
}

for (let i = bicMin; i <= bicMax; i++) {
  bicMasks.push(alphanumericChar.repeat(i))
}

export { ibanMasks, bicMasks, dateMask, siretMask }
