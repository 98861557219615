import { algoliaQueries } from '~/utils/algolia'
import type { RuntimeConfig } from 'nuxt/schema'
import type { SearchResponse } from '@algolia/client-search'

export const useSearchInputData = ({
  indexDestinations,
  indexHouses,
  locale,
  config,
}: {
  indexDestinations: Ref<string>
  indexHouses: Ref<string>
  locale: Ref<string>
  config: RuntimeConfig
}) => {
  const { algoliaIds } = useAuth()

  const searchQueryOnAlgoliaData = async <T = unknown>({
    filtersDesti = '',
    filtersHouse = '',
    hitsPerPage,
    query = '',
  }: {
    filtersDesti?: string
    filtersHouse?: string
    hitsPerPage: number
    query?: string
  }) => {
    const { results } = await algoliaQueries<T>({
      algoliaSearchKey: algoliaIds.value.apiKey,
      queries: [
        {
          indexName: indexHouses.value,
          query,
          params: {
            filters: filtersHouse,
            hitsPerPage,
          },
        },
        {
          indexName: indexDestinations.value,
          query,
          params: {
            filters: filtersDesti,
            restrictSearchableAttributes: [
              `name.${locale.value}`,
              `name_suffixes.${locale.value}`,
              'searchable_names',
            ],
            hitsPerPage,
          },
        },
      ],
      config,
    })

    return results || []
  }

  return { searchQueryOnAlgoliaData }
}

export type { SearchResponse as AlgoliaSearchResponse }
