import {
  QueryModelType,
  FacetsRetrieve,
  Locale,
  SupportedCurrencies,
  Mode,
  GetNbHiddenHousesInput,
  SearchHousesMapInput,
} from "../../../types";
import {
  GetAreaEquipmentsUseCase,
  GetHouseDetailsUseCase,
  GetHouseKeyFeaturesUseCase,
  GetHousePeriodsAndBookingsUseCase,
  GetHousePeriodsByIdUseCase,
  GetHousePeriodsUseCase,
  GetHousePhotosUseCase,
  GetNbHiddenHousesUseCase,
  GetTopDestinationsUseCase,
  MakeHouseRequestUseCase,
  OrderRetrievedFacetsUseCase,
  SearchHousesAndDestinationsUseCase,
  SearchHousesMapUseCase,
  SearchHousesWithDatesUseCase,
  SearchHousesWithoutDatesUseCase,
  SearchHousesWithoutDatesV2UseCase,
  UpdateInquiryUseCase,
  GetHouseIncludedServices,
  GetHouseTruthLinksUseCase,
} from "../usecases";
import { GetHouseDetailsPresentation } from "../usecases/GetHouseDetails/interfaces/GetHouseDetailsPresentation";
import { GetHousePeriodsPresentation } from "../usecases/GetHousePeriods/interfaces/GetHousePeriodsPresentation";
import { GetHousePhotosPresentation } from "../usecases/GetHousePhotos/interfaces/GetHousePhotosPresentation";
import { GetTopDestinationsPresentation } from "../usecases/GetTopDestinations/interfaces/GetTopDestinationsPresentation";
import { MakeHouseRequestPresentation } from "../usecases/MakeHouseRequest/interfaces/MakeHouseRequestPresentation";
import { OrderRetrievedFacetsPresentation } from "../usecases/OrderRetrievedFacets/interfaces/OrderRetrievedFacetsPresentation";
import { SearchHousesPresentation } from "../usecases/SearchHouses/interfaces/SearchHousesPresentation";
import { SearchHousesAndDestinationsPresentation } from "../usecases/SearchHousesAndDestinations/interfaces/SearchHousesAndDestinationsPresentation";
import { GetHousePeriodsAndBookingsPresentation } from "../usecases/GetHousePeriodsAndBookings/interfaces/GetHousePeriodsAndBookingsPresentation";
import { MakeHouseRequestInput } from "../usecases/MakeHouseRequest/types";
import { UpdateInquiryInput } from "../usecases/UpdateInquiry/types";
import { UpdateInquiryPresentation } from "../usecases/UpdateInquiry/interfaces/UpdateInquiryPresentation";
import { GetNbHiddenHousesPresentation } from "../usecases/GetNbHiddenHouses/interfaces/GetNbHiddenHousesPresentation";
import { SearchHousesMapPresentation } from "../usecases/SearchHousesMap/interfaces/SearchHousesMapPresentation";
import { GetHouseKeyFeaturesPresentation } from "../usecases/GetHouseKeyFeatures/interfaces/GetHouseKeyFeaturesPresentation";
import { GetAreaEquipmentsPresentation } from "../usecases/GetAreaEquipments/interfaces/GetAreaEquipmentsPresentation";
import { SearchType } from "services/algolia";
import { GetHousePeriodsByIdPresentation } from "../usecases/GetHousePeriodsById/interfaces/GetHousePeriodsByIdPresentation";
import { GetHouseIncludedServicesPresentation } from "../usecases/GetHouseIncludedServices/interfaces/GetHouseIncludedServicesPresentation";
import { GetHouseTruthLinksPresentation } from "../usecases/GetHouseTruthLinks/interfaces/GetHouseTruthLinksPresentation";

type Usecases = {
  getHouseAreaEquipments: GetAreaEquipmentsUseCase;
  getHouseDetails: GetHouseDetailsUseCase;
  getHouseIncludedServices: GetHouseIncludedServices;
  getHousePeriods: GetHousePeriodsUseCase;
  getHousePeriodsById: GetHousePeriodsByIdUseCase;
  getHousePeriodsAndBookings: GetHousePeriodsAndBookingsUseCase;
  getHousePhotos: GetHousePhotosUseCase;
  getHouseKeyFeatures: GetHouseKeyFeaturesUseCase;
  getTopDestinations: GetTopDestinationsUseCase;
  makeHouseRequest: MakeHouseRequestUseCase;
  orderFacets: OrderRetrievedFacetsUseCase;
  searchHouses: {
    searchHousesWithoutDates: SearchHousesWithoutDatesUseCase;
    searchHousesWithDates: SearchHousesWithDatesUseCase;
  };
  searchHousesV2: {
    searchHousesWithoutDates: SearchHousesWithoutDatesV2UseCase;
    searchHousesWithDates: SearchHousesWithDatesUseCase;
  };
  searchHousesAndDestinations: SearchHousesAndDestinationsUseCase;
  updateInquiry: UpdateInquiryUseCase;
  getNbHiddenHouses: GetNbHiddenHousesUseCase;
  searchHousesMap: SearchHousesMapUseCase;
  getHouseTruthLinks: GetHouseTruthLinksUseCase;
};

type Presenter = SearchHousesAndDestinationsPresentation &
  GetTopDestinationsPresentation &
  MakeHouseRequestPresentation &
  OrderRetrievedFacetsPresentation &
  GetHouseKeyFeaturesPresentation &
  GetAreaEquipmentsPresentation &
  SearchHousesPresentation &
  GetHouseDetailsPresentation &
  GetHouseIncludedServicesPresentation &
  GetHousePhotosPresentation &
  GetHousePeriodsPresentation &
  GetHousePeriodsByIdPresentation &
  GetHousePeriodsAndBookingsPresentation &
  GetHouseTruthLinksPresentation &
  UpdateInquiryPresentation &
  GetNbHiddenHousesPresentation &
  SearchHousesMapPresentation;

export default class SearchController {
  constructor(
    private usecases: Partial<Usecases>,
    private presenter: Presenter
  ) {}

  async getHouseDetails(input: {
    houseId: string;
    locale: Locale;
    privateToken?: string;
    withKeyfeatures?: boolean;
    withCodes?: boolean;
  }) {
    const { houseId, locale, privateToken, withKeyfeatures, withCodes } = input;
    await this.usecases.getHouseDetails?.execute({
      presenter: this.presenter,
      houseId,
      locale,
      withKeyfeatures,
      withCodes,
      privateToken,
    });
  }
  async getHousePhotos(
    houseId: string,
    pagination: { page: string; per: string },
    privateToken?: string
  ) {
    await this.usecases.getHousePhotos?.execute(
      this.presenter,
      houseId,
      pagination,
      privateToken
    );
  }

  async getTopDestinations(locale: Locale, algoliaIds: SearchType) {
    await this.usecases.getTopDestinations?.execute(
      this.presenter,
      locale,
      algoliaIds
    );
  }

  orderFacets(facets: FacetsRetrieve, locale: Locale, mode: Mode = "client") {
    this.usecases.orderFacets?.execute(this.presenter, facets, locale, mode);
  }

  async searchHouses(
    queryModel: QueryModelType,
    locale: Locale,
    currency: SupportedCurrencies | null,
    algoliaIds: SearchType,
    deviceMode: "web" | "mobile"
  ) {
    if (queryModel.period) {
      await this.usecases.searchHouses?.searchHousesWithDates.execute(
        this.presenter,
        queryModel,
        deviceMode,
        locale,
        currency,
        algoliaIds
      );
    } else {
      await this.usecases.searchHouses?.searchHousesWithoutDates.execute(
        this.presenter,
        queryModel,
        deviceMode,
        locale,
        currency,
        algoliaIds
      );
    }
  }

  async searchHousesV2(
    queryModel: QueryModelType,
    locale: Locale,
    currency: SupportedCurrencies | null,
    algoliaIds: SearchType,
    deviceMode: "web" | "mobile"
  ) {
    if (queryModel.period) {
      await this.usecases.searchHousesV2?.searchHousesWithDates.execute(
        this.presenter,
        queryModel,
        deviceMode,
        locale,
        currency,
        algoliaIds
      );
    } else {
      await this.usecases.searchHousesV2?.searchHousesWithoutDates.execute(
        this.presenter,
        queryModel,
        deviceMode,
        locale,
        currency,
        algoliaIds
      );
    }
  }

  async searchHousesMap(
    input: SearchHousesMapInput,
    algoliaIds: SearchType,
    deviceMode: "web" | "mobile"
  ) {
    await this.usecases.searchHousesMap?.execute(
      this.presenter,
      input,
      algoliaIds,
      deviceMode
    );
  }

  async searchHousesAndDestinations(
    value: string,
    locale: Locale,
    mode: Mode = "client",
    algoliaIds: SearchType
  ) {
    await this.usecases.searchHousesAndDestinations?.execute(
      this.presenter,
      value,
      locale,
      mode,
      algoliaIds
    );
  }

  async getHousePeriods(
    houseId: string,
    period: { startDate: string; endDate: string },
    currency: SupportedCurrencies,
    destinationIds: string[] = []
  ) {
    await this.usecases.getHousePeriods?.execute(
      this.presenter,
      houseId,
      period,
      currency,
      destinationIds,
    );
  }

  async makeHouseRequest(request: MakeHouseRequestInput) {
    await this.usecases.makeHouseRequest?.execute(this.presenter, request);
  }

  async getHousePeriodsAndBookings(input: {
    houseId: string;
    startAt: string;
    endAt: string;
    privateToken?: string;
  }) {
    await this.usecases.getHousePeriodsAndBookings?.execute(
      this.presenter,
      input
    );
  }

  async updateInquiry(input: UpdateInquiryInput) {
    await this.usecases.updateInquiry?.execute(input, this.presenter);
  }

  async getNbHiddenHouses(
    input: GetNbHiddenHousesInput,
    algoliaIds: SearchType
  ) {
    await this.usecases.getNbHiddenHouses?.execute(
      this.presenter,
      input,
      algoliaIds
    );
  }

  async getHouseKeyFeatures(houseId: string, privateToken?: string) {
    await this.usecases.getHouseKeyFeatures?.execute(
      this.presenter,
      houseId,
      privateToken
    );
  }

  async getHouseAreaEquipments(houseId: string, privateToken?: string) {
    await this.usecases.getHouseAreaEquipments?.execute(
      this.presenter,
      houseId,
      privateToken
    );
  }

  async getHousePeriodsById(
    houseId: string,
    period: { startDate: string; endDate: string },
    currency?: SupportedCurrencies
  ) {
    await this.usecases.getHousePeriodsById?.execute(
      this.presenter,
      houseId,
      period,
      currency
    );
  }

  async getHouseIncludedServices(input: { houseId: string | number }) {
    await this.usecases.getHouseIncludedServices?.execute(
      this.presenter,
      input
    );
  }

  async getHouseTruthLinks(input: {
    houseId: string | number;
    privateToken?: string;
  }) {
    await this.usecases.getHouseTruthLinks?.execute(this.presenter, input);
  }
}
