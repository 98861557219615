<script setup lang="ts">
import type { RouteLocation } from 'vue-router'
import type { Locale } from 'lc-services/types'

const { locale } = useI18n<unknown, Locale>()
const localePath = useLocalePath()
const vueRoute = useRoute()
const router = useRouter()
const { authenticated } = useAuth()
const { headerTheme, setNextRoute, setTemporaryTheme } = useHeaderNavigation()
const {
  closeDropdown,
  closeDropdownAndOpenActiveTab,
  headerDesktop,
  resetTab,
  setActiveDropdown,
} = useHeaderDesktop()
const { summaryOpen } = useMultipleWishlistSummary()
const { properties } = useUserHouses()
const { trackEvent } = useTracking()

const currentDropdown = computed(() => headerDesktop.value.activeDropdown)
const tabUnderlined = computed(() => headerDesktop.value.tabUnderlined)
const routeIsAccount = computed(
  () => String(vueRoute?.name ?? '').includes('account') || false,
)
const colorIcon = computed(() =>
  headerTheme.value === 'transparent' ? 'white' : 'black',
)

const authenticatedColor = computed(() =>
  headerTheme.value === 'transparent' ? 'text-white' : 'text-success',
)
const accountLink = computed(() => {
  const accountLoggedLink = properties.value.length
    ? localePath({
        name: 'myaccount-property-name-rentals',
        params: { name: properties.value[0].slug[locale.value] },
      })
    : localePath('myaccount-bookings')

  return authenticated.value ? accountLoggedLink : localePath('login')
})

watch(summaryOpen, async (value) => {
  if (value) {
    await closeDropdownAndOpenActiveTab(
      'wishlist',
      document.getElementById('header-wishlist'),
    )
    setTemporaryTheme('white')
  } else {
    resetTab('right')
    setTemporaryTheme('')
  }
})

// Methods
const toggleDropdownSearch = (name: string, index: number) => {
  setActiveDropdown(name, index)

  trackEvent({
    event: 'header_clicked',
    item: 'search',
  })
}

const onLinkClicked = (
  route: RouteLocation,
  keyTracking: 'wishlist' | 'account',
) => {
  trackEvent({
    event: 'header_clicked',
    item: keyTracking,
  })
  // if dropdown already open, trigger async navigation
  // else push directly
  if (currentDropdown.value.name && route.name === vueRoute.name) {
    closeDropdown()
  } else if (currentDropdown.value.name) {
    setNextRoute(route)
  } else {
    router.push(route)
  }
}
</script>

<template>
  <nav class="navigation-icons" :aria-label="$t('header.secondaryNavigation')">
    <ul class="m-0 flex items-center">
      <li class="leading-none">
        <BaseButton
          id="header-search"
          :aria-expanded="currentDropdown.name ? true : false"
          :aria-label="$t('header.search')"
          :color="colorIcon"
          aria-controls="header-dropdown"
          class="!border-0"
          no-padding
          has-icon
          role="menu"
          type="button"
          @click="toggleDropdownSearch('search', 3)"
        >
          <BaseIcon
            name="search"
            :stroke-width="tabUnderlined === 'search' ? 1.3 : 1"
          />
        </BaseButton>
      </li>
      <li class="ml-4">
        <ABContainer flag-key="wishlist-summary">
          <template #control>
            <NuxtLink
              v-slot="{ href, route }"
              :to="localePath('wishlist')"
              custom
            >
              <a
                id="header-wishlist"
                class="link relative block"
                data-cy="header-wishlist"
                data-testid="navigation-icons-desktop-link-wishlist"
                :aria-label="$t('header.wishlist')"
                :data-v-inspector="$attrs['data-v-inspector']"
                :href="href"
                @click.prevent="onLinkClicked(route, 'wishlist')"
              >
                <BaseIcon
                  name="heart"
                  :class="['icon-transition', colorIcon]"
                  :stroke-width="1"
                />
              </a>
            </NuxtLink>
          </template>

          <template #treatment>
            <TheHeaderButtonWishlist />
          </template>
        </ABContainer>
      </li>
      <li class="ml-4">
        <NuxtLink v-slot="{ href, isActive, route }" :to="accountLink" custom>
          <a
            id="header-account"
            class="link relative block h-full"
            data-cy="header-account"
            data-testid="navigation-icons-desktop-link-account"
            :aria-label="$t('header.account')"
            :data-v-inspector="$attrs['data-v-inspector']"
            :href="href"
            @click.prevent="onLinkClicked(route, 'account')"
          >
            <template v-if="authenticated">
              <BaseIcon
                name="profileCircle"
                :class="[
                  'icon-transition',
                  colorIcon,
                  { active: routeIsAccount || isActive },
                ]"
                :stroke-width="isActive ? 1.3 : 1"
              />

              <BaseIcon
                :class="[
                  'icon-overlay-small absolute left-full top-1 -translate-x-1/2',
                  authenticatedColor,
                ]"
                name="check"
                stroke-width="4"
                :size="0.8"
              />
            </template>

            <BaseIcon
              v-else
              name="profileCircle"
              :class="[
                'icon-transition',
                colorIcon,
                { active: routeIsAccount || isActive },
              ]"
              :stroke-width="isActive ? 1.3 : 1"
            />
          </a>
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<style>
.icon-transition {
  transition:
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
}

.navigation-icons .link {
  @apply hover:no-underline;
}

.navigation-icons .link .white {
  @apply text-white;
}

.navigation-icons .link .black {
  @apply text-gray-700;
}

.phone-wrap .lc-link--black:hover {
  @apply text-gray-700;
}
</style>
