import { formatRoute } from '~/helpers/formatRoute'

export type HeaderDesktop = {
  activeDropdown: {
    name: string
    index: number
  }
  currentRouteTab: string
  tabUnderlined: string
  underlinePosition: {
    left: { offsetLeft: number; width: number; scale: number }
    right: { offsetRight: number; width: number; scale: number }
  }
}

const tabsByGroup = {
  left: ['houses', 'destinations', 'inspirations', 'about'],
  right: ['search', 'wishlist', 'account'],
}
const routeTabs = {
  search: 'houses',
  destinations: 'destinations',
  wishlist: 'wishlist',
  login: 'account',
  forgotPassword: 'account',
} as const

const getTabGroup = (tabName: string) => {
  if (tabsByGroup.left.includes(tabName)) {
    return 'left'
  }

  if (tabsByGroup.right.includes(tabName)) {
    return 'right'
  }

  return ''
}

export const useHeaderDesktop = () => {
  const headerDesktop = useState<HeaderDesktop>('header-desktop', () => ({
    activeDropdown: {
      name: '',
      index: 0,
    },
    currentRouteTab: '',
    tabUnderlined: '',
    underlinePosition: {
      left: { offsetLeft: 0, width: 0, scale: 0 },
      right: { offsetRight: 0, width: 0, scale: 0 },
    },
  }))

  const underlineLeftStyle = computed(() => {
    const underline = headerDesktop.value.underlinePosition.left

    return {
      left: `${underline.offsetLeft}px`,
      width: `${underline.width}px`,
      transform: `scale(${underline.scale})`,
    }
  })

  const underlineRightStyle = computed(() => {
    const underline = headerDesktop.value.underlinePosition.right

    return {
      right: `${underline.offsetRight}px`,
      width: `${underline.width}px`,
      transform: `scale(${underline.scale})`,
    }
  })

  const closeDropdown = () => {
    headerDesktop.value.activeDropdown.name = ''
  }

  const setActiveDropdown = (name: string, index: number) => {
    if (headerDesktop.value.activeDropdown.name !== name) {
      setTabUnderlined(name)
      headerDesktop.value.activeDropdown = { name, index }
    } else {
      headerDesktop.value.activeDropdown = { name: '', index }
    }
  }

  const setTabUnderlined = (activeTab: string) => {
    const previousTab = headerDesktop.value.tabUnderlined
    const previousTabGroup = getTabGroup(previousTab)
    const newTabGroup = getTabGroup(activeTab)
    const element = document.getElementById(`header-${activeTab}`)

    // if the group changes (left or right)
    if (previousTabGroup !== newTabGroup) {
      if (previousTabGroup) {
        // make underline disappear under previous group
        headerDesktop.value.underlinePosition[previousTabGroup].scale = 0
      }

      headerDesktop.value.tabUnderlined = activeTab

      if (newTabGroup) {
        // make underline appear under new group
        setUnderlinePositionFromElement(newTabGroup, element)

        headerDesktop.value.underlinePosition[newTabGroup].scale = 1
      }
    } else if (newTabGroup) {
      // if underline remains on the same group, simply update position
      headerDesktop.value.tabUnderlined = activeTab

      setUnderlinePositionFromElement(newTabGroup, element)
    }
  }

  const setUnderlinePositionFromElement = (
    group: 'left' | 'right',
    element: HTMLElement | null,
  ) => {
    if (group === 'left') {
      const offsetLeft = element?.offsetLeft
      const width = element?.offsetWidth

      if (offsetLeft && width) {
        headerDesktop.value.underlinePosition.left.offsetLeft = offsetLeft
        headerDesktop.value.underlinePosition.left.width = width
      }
    } else if (group === 'right') {
      const widthWithoutScrollbar = document.body.clientWidth
      const offsetLeft = element?.offsetLeft
      const width = element?.offsetWidth
      const offsetRight = widthWithoutScrollbar - offsetLeft - width

      if (offsetRight && width) {
        headerDesktop.value.underlinePosition.right.offsetRight = offsetRight
        headerDesktop.value.underlinePosition.right.width = width
      }
    }
  }

  const setTabUnderlinedFromPreviousRoute = () => {
    setTabUnderlined(headerDesktop.value.currentRouteTab)
  }

  const setActiveTabFromRoute = (localeRoute: string) => {
    const globalRoute = formatRoute(localeRoute)
    const previousTab = headerDesktop.value.tabUnderlined
    const previousTabGroup = getTabGroup(previousTab)
    let activeTab = routeTabs?.[globalRoute] || ''
    const activeTabGroup = getTabGroup(activeTab)

    // all pages with account layout have 'account' tab active
    if (document.querySelector('.account-layout')) {
      activeTab = 'account'
    }

    headerDesktop.value.currentRouteTab = activeTab

    if (activeTab) {
      setTabUnderlined(activeTab)

      if (activeTabGroup) {
        headerDesktop.value.underlinePosition[activeTabGroup].scale = 1
      }
    } else if (previousTabGroup) {
      resetTab(previousTabGroup)
    }
  }

  const resetTab = (group: 'left' | 'right') => {
    headerDesktop.value.underlinePosition[group].scale = 0

    if (group === 'left') {
      headerDesktop.value.underlinePosition.left.offsetLeft = 0
      headerDesktop.value.underlinePosition.left.width = 0
    } else {
      headerDesktop.value.underlinePosition.right.offsetRight = 0
      headerDesktop.value.underlinePosition.right.width = 0
    }

    headerDesktop.value.tabUnderlined = ''
  }

  const setCurrentRouteTab = (tabName: string) => {
    headerDesktop.value.currentRouteTab = tabName
  }

  const closeDropdownAndOpenActiveTab = async (
    activeTab: string,
    element: HTMLElement | null,
  ) => {
    closeDropdown()
    await nextTick()

    setUnderlinePositionFromElement('right', element)
    setTabUnderlined(activeTab)
  }

  return {
    closeDropdownAndOpenActiveTab,
    closeDropdown,
    headerDesktop,
    resetTab,
    setActiveDropdown,
    setActiveTabFromRoute,
    setCurrentRouteTab,
    setTabUnderlined,
    setTabUnderlinedFromPreviousRoute,
    setUnderlinePositionFromElement,
    underlineLeftStyle,
    underlineRightStyle,
  }
}
