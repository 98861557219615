import { ApiFormError, ApiFormErrors, ErrorsForm } from "../../types";

export const convertApiFormErrorsToErrorsForm = (
  apiFormErrors: ApiFormErrors
): ErrorsForm => {
  const errorsForm: ErrorsForm = [];

  const flattenErrors = (
    parentFormError: ApiFormError,
    parentPrefix?: string
  ) => {
    Object.keys(parentFormError).forEach((key) => {
      const childFormError = parentFormError[key];
      if (childFormError === undefined) {
        return;
      } else if (Array.isArray(childFormError)) {
        if (childFormError.length > 0) {
          const firstChildFormError = childFormError[0];

          if (typeof firstChildFormError === "string") {
            errorsForm.push({
              fieldName: parentPrefix ? `${parentPrefix}.${key}` : key,
              error: firstChildFormError,
            });
          } else {
            const error = firstChildFormError?.error ?? "";
            if (typeof error === "object") {
              // we need to go deeper
              flattenErrors(
                error as ApiFormError,
                parentPrefix ? `${parentPrefix}.${key}` : key
              );
            } else {
              errorsForm.push({
                fieldName: parentPrefix ? `${parentPrefix}.${key}` : key,
                error,
              });
            }
          }
        }
      } else if (typeof childFormError === "object") {
        // we need to go deeper
        flattenErrors(
          childFormError as ApiFormError,
          parentPrefix ? `${parentPrefix}.${key}` : key
        );
      } else {
        errorsForm.push({
          fieldName: parentPrefix ? `${parentPrefix}.${key}` : key,
          error: childFormError,
        });
      }
    });
  };

  for (const error of apiFormErrors) {
    flattenErrors(error);
  }

  return errorsForm;
};
