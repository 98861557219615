import { GetHouseKeyFeaturesRepository } from "./interfaces/GetHouseKeyFeaturesRepository";
import { GetHouseKeyFeaturesPresentation } from "./interfaces/GetHouseKeyFeaturesPresentation";
import { HouseKeyFeatures } from "../GetHouseDetails/types";

export default class GetHouseKeyFeaturesUseCase {
  constructor(private repository: GetHouseKeyFeaturesRepository) {}

  async execute(
    presenter: GetHouseKeyFeaturesPresentation,
    houseId: string,
    privateToken?: string
  ) {
    try {
      const houseKeyFeaturesResponse =
        await this.repository.getHouseKeyFeatures({
          houseId,
          privateToken,
        });

      if (houseKeyFeaturesResponse.data) {
        presenter.displayHouseKeyFeatures(houseKeyFeaturesResponse.data);
      } else if (houseKeyFeaturesResponse) {
        presenter.displayHouseKeyFeatures(
          houseKeyFeaturesResponse as HouseKeyFeatures
        );
      }
    } catch (err) {
      console.log("GetHouseKeyFeaturesUseCase", err);
    }
  }
}
