import { housesFormatted } from "./helpers/house";

import type { Wishlist, WishlistDetailsFormatted } from "../../../types";

export class WishlistDetails {
  private newWishlist: WishlistDetailsFormatted | null = null;

  constructor(private wishlistRepository: Wishlist) {
    if (this.wishlistRepository?.id) {
      this.newWishlist = {
        houses: housesFormatted(
          this.wishlistRepository.multiInquiryHouses.houses
        ),
        id: this.wishlistRepository.id,
        legacy: this.wishlistRepository.legacy,
        privateToken: this.wishlistRepository.privateToken,
        title: this.wishlistRepository.title,
      };
    }
  }

  build() {
    return this.newWishlist;
  }
}
