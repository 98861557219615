export default (clientHttp, apiVersion) => ({
  read(houseId, query) {
    return clientHttp.get(
      `${apiVersion}/user/houses/${houseId}/bookings?${query}`,
    )
  },

  create(houseId, params) {
    return clientHttp.post(
      `${apiVersion}/user/houses/${houseId}/owner_bookings`,
      {
        ...params,
      },
    )
  },

  delete(houseId, bookingId) {
    return clientHttp.delete(
      `${apiVersion}/user/houses/${houseId}/bookings/${bookingId}`,
    )
  },
})
