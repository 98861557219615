<script setup lang="ts">
const { headerMobile, setShowDropUp } = useHeaderMobile()

const dropUpMobileIsOpen = computed(() => headerMobile.value.showDropUp)
const dropUpActive = computed(() => headerMobile.value.dropUpActive)
const showDropUp = computed({
  get() {
    return dropUpMobileIsOpen.value
  },
  set(value) {
    setShowDropUp(value)
  },
})
</script>

<template>
  <BaseDropupMobile v-model="showDropUp" class="z-modal">
    <div class="px-4 pb-28 tablet:px-10">
      <the-menu-mobile-bottom-bar-contact v-if="dropUpActive === 'contact'" />
      <the-menu-mobile-bottom-bar-currency v-if="dropUpActive === 'language'" />
    </div>
  </BaseDropupMobile>
</template>
