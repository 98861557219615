export default class Formatter {
  emailFormatter(email: string) {
    const emailFormatted = email
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    return emailFormatted;
  }
}
