import { SupportedCurrencies } from "../../../../types";
import { GetHousePeriodsByIdRepository } from "./interfaces/GetHousePeriodsByIdRepository";
import { GetHousePeriodsByIdPresentation } from "./interfaces/GetHousePeriodsByIdPresentation";

export default class GetHousePeriodsByIdUseCase {
  constructor(private repository: GetHousePeriodsByIdRepository) {}

  async execute(
    presenter: GetHousePeriodsByIdPresentation,
    houseId: string,
    period: { startDate: string; endDate: string },
    currency?: SupportedCurrencies
  ) {
    const response = await this.repository.getHousePeriodsById({
      houseId,
      startDate: period.startDate,
      endDate: period.endDate,
      currency,
    });
    const housePeriods = response?.data || null;
    presenter.displayHousePeriodsById(housePeriods);
  }
}
