import { InputText } from '~/components/BaseForm/core/primitives'

const Email: typeof InputText = {
  ...InputText,
  attr: {
    ...InputText.attr,
    rules: 'required|email',
    labelTranslateKey: 'form.email',
    name: 'email',
    type: 'email',
  },
  containerInputClass: 'w-full md:w-1/2',
}

export default Email
