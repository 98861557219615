<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

withDefaults(
  defineProps<{
    disabled?: boolean
    icon?: { path: IconPath; size?: number } | null
    label?: string
    labelHtml?: string
    modelValue: number | string
    name: string
    value: string
    vertical: boolean
  }>(),
  {
    disabled: false,
    icon: null,
    label: '',
    labelHtml: '',
  },
)

const emits = defineEmits<{
  'update:model-value': [string]
}>()

const onChange = (event: Event): void => {
  const target = event.target as HTMLInputElement

  emits('update:model-value', target.value)
}
</script>

<template>
  <label
    :class="[
      'base-radio-label',
      { 'base-radio-label--disabled': disabled },
      { 'base-radio-label--vertical': vertical },
    ]"
    :for="`${value}-${name}`"
  >
    <input
      :id="`${value}-${name}`"
      :checked="modelValue === value"
      :disabled="disabled"
      :name="name"
      :value="value"
      class="base-radio base-form-tick"
      data-testid="base-radio"
      type="radio"
      v-bind="$attrs"
      @change="onChange"
    />

    <span class="radio" />

    <BaseFormComponentsBaseFormLabel
      :icon="icon"
      :label="label"
      :label-html="labelHtml"
    />
  </label>
</template>

<style>
.base-form-tick:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-size: 120% 120%;
  background-position: 50%;
  background-repeat: no-repeat;
}
.base-radio-label {
  @apply inline-flex justify-start items-center mr-4 cursor-pointer mb-2;
}

.base-radio-label--disabled {
  @apply text-gray-500 cursor-not-allowed;
}

.base-radio-label--vertical {
  @apply mb-2 mr-0;
}

.base-radio {
  @apply appearance-none flex-none cursor-pointer mr-2 border border-gray-300 rounded-full checked:bg-primary-800 checked:border-primary-800 w-6 h-6 md:w-5 md:h-5;
}

.base-radio:hover:not(:checked) {
  @apply bg-gray-200;
}

.base-radio:disabled {
  @apply bg-gray-200 cursor-not-allowed;
}

.base-radio:hover:disabled {
  @apply bg-gray-200;
}
</style>
