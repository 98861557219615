import { getWindowWidth } from './windowWidth'

export const defineHeader = async ({ routeName }) => {
  if (import.meta.client) {
    const isSmallScreen = ['mobile', 'tablet'].includes(getWindowWidth())
    const { authenticated } = useAuth()
    const { setMenuIsOpen, setSearchIsOpen, setShowDropUp } = useHeaderMobile()
    const { setCurrentRouteTab, setActiveTabFromRoute, closeDropdown } =
      useHeaderDesktop()

    if (isSmallScreen) {
      if (!authenticated.value) {
        return navigateTo('/login')
      }
      // Close menu / dropUp / Search
      setMenuIsOpen(false)
      setShowDropUp(false)
      setSearchIsOpen(false)
    } else {
      // Set currentRoute / ActiveTab
      setCurrentRouteTab('')
      setActiveTabFromRoute(routeName)
      // Close Dropdown
      closeDropdown()
    }
  }
}
