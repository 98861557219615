import type { HttpService } from '~/repository/config/http-service'

export default (clientHttp: HttpService, apiVersion: 'v1') => ({
  read() {
    return clientHttp.get(
      `${apiVersion}/user/travel_agent_general_rental_condition`,
      {},
    ) as Promise<{ data: { id: string; signatureDate: string } }>
  },
})
