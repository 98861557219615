import { formatRoute } from '~/helpers/formatRoute'
import { sortByIds } from '~/utils/sortByIds'
import { getInspirations } from '~/helpers/strapi/getInspirations'
import { aboutLinks } from '~/assets/js/aboutLinks'
import { transparentPagesNames } from '~/utils/headerTheme'
import type { RouteLocation } from 'vue-router'
import type { Locale } from 'lc-services/types'

type PageTheme = 'white' | 'transparent' | ''

export type InspiLink = {
  href: { fr: string; en: string }
  id: number | null
  img: string
  text?: string
  titleEn: string
  titleFr: string
}

type HeaderNavigation = {
  customPageTheme: PageTheme
  inspirationsLinks: InspiLink[]
  nextRoute: RouteLocation | null
  routeName: string
  searchDestination: { name: { en: string; fr: string }; destinationId: number }
  temporaryTheme: PageTheme
  urlFrom: string
}

export const useHeaderNavigation = () => {
  const { $strapiApi } = useNuxtApp()
  const localePath = useLocalePath()

  const headerNavigation = useState<HeaderNavigation>(
    'header-navigation',
    () => ({
      customPageTheme: '', // if one route can have several themes
      inspirationsLinks: [],
      nextRoute: null,
      routeName: '',
      searchDestination: {} as HeaderNavigation['searchDestination'],
      temporaryTheme: '',
      urlFrom: '',
    }),
  )

  const isRouteMatched = (payload: {
    locale: Locale
    page: string
    path: string
  }) => {
    const isAboutPage = aboutLinks.some((link) =>
      payload.path.includes(localePath(link.href)),
    )
    const isInpirationPage = headerNavigation.value.inspirationsLinks.some(
      (link) => payload.path.includes(link.href[payload.locale]),
    )
    const isDestinationPage =
      payload.page === 'destinations' &&
      headerNavigation.value.routeName === 'destination-name' &&
      !isInpirationPage &&
      !isAboutPage

    return (
      (payload.page === 'inspirations' && isInpirationPage) ||
      (payload.page === 'about' && isAboutPage) ||
      isDestinationPage
    )
  }

  const headerHeight = computed(() => 74)

  const pageTheme = computed(() => {
    if (headerNavigation.value.customPageTheme) {
      return headerNavigation.value.customPageTheme
    }

    if (transparentPagesNames.includes(headerNavigation.value.routeName)) {
      return 'transparent'
    }

    return 'white'
  })

  const headerTheme = computed(() => {
    if (headerNavigation.value.temporaryTheme) {
      return headerNavigation.value.temporaryTheme
    }

    return pageTheme.value
  })

  const setInspirationsLinks = async () => {
    const { data: getInspirationsData } = await useAsyncData(
      'headerNavigationGetInspirations',
      () => getInspirations({ $strapiApi }),
    )

    if (getInspirationsData.value) {
      const { strapiInspirationsIds, strapiInspirations } =
        getInspirationsData.value

      const inspirationsLinks = strapiInspirations
        .sort((a, b) => sortByIds(strapiInspirationsIds, a, b))
        .map((d) => {
          const prefixSlug = {
            en: `luxury-villas-rentals/${d.slugEn}`,
            fr: `location-villas-luxe/${d.slugFr}`,
          }

          return {
            href: prefixSlug,
            id: d.id,
            img: d?.coverPhotoUrl,
            titleEn: d?.titleEn,
            titleFr: d?.titleFr,
          }
        })

      headerNavigation.value.inspirationsLinks = inspirationsLinks

      return inspirationsLinks
    }
  }

  const setCustomPageTheme = (theme: PageTheme) => {
    headerNavigation.value.customPageTheme = theme
  }

  const setRoute = (localeRoute: string) => {
    const globalRoute = formatRoute(localeRoute)

    headerNavigation.value.routeName = globalRoute
  }

  const setNextRoute = (route: RouteLocation | null) => {
    headerNavigation.value.nextRoute = route
  }

  const setSearchDestination = (
    payload: HeaderNavigation['searchDestination'],
  ) => {
    headerNavigation.value.searchDestination = payload
  }

  const setTemporaryTheme = (theme: PageTheme) => {
    headerNavigation.value.temporaryTheme = theme
  }

  const setUrlFrom = (url: string) => {
    headerNavigation.value.urlFrom = url
  }

  return {
    headerHeight,
    headerNavigation,
    headerTheme,
    isRouteMatched,
    pageTheme,
    setCustomPageTheme,
    setInspirationsLinks,
    setNextRoute,
    setRoute,
    setSearchDestination,
    setTemporaryTheme,
    setUrlFrom,
  }
}
