export default (clientHttp, apiVersion) => ({
  read(payload, params, query) {
    // Index with query
    if (query) {
      return clientHttp.get(`${apiVersion}/houses${query}`, params)
    }

    // Index
    if (Array.isArray(payload)) {
      if (payload.length > 0) {
        const ids = `?ids=${payload.join(',')}`

        return clientHttp.get(`${apiVersion}/houses${ids}`, {
          ...params,
        })
      }

      return new Promise((resolve) => {
        resolve('response')
      })
    }

    // Show
    return clientHttp.get(`${apiVersion}/houses/${payload}`, {
      ...params,
    })
  },
})
