export default (clientHttp, apiVersion) => ({
  /**
   *
   * @param {*} params
   * @returns {PromiseLike<{ data: { id: string; stripeClientSecret: string; uuid: string } }>}
   */
  create(params) {
    return clientHttp.post(`${apiVersion}/requests`, params)
  },

  update(id, params) {
    return clientHttp.put(`${apiVersion}/requests/${id}`, { ...params })
  },
})
