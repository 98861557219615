import { housesFormatted } from "./helpers/house";

import type { Wishlist, WishlistDetailsSharedFormatted } from "../../../types";

export class WishlistShared {
  private newWishlistShard: WishlistDetailsSharedFormatted | null = null;

  constructor(private wishlistRepository: Wishlist) {
    if (this.wishlistRepository?.id) {
      this.newWishlistShard = {
        houses: housesFormatted(
          this.wishlistRepository.multiInquiryHouses.houses
        ),
        id: this.wishlistRepository.id,
        privateToken: this.wishlistRepository.privateToken,
        title: this.wishlistRepository.title,
        userName: this.wishlistRepository.user?.firstName || "",
      };
    }
  }

  build() {
    return this.newWishlistShard;
  }
}
