type inputFilters = {
  bathrooms: string
  bedrooms: string
  capacity: string
}

export const formatNumericFilters = ({
  bathrooms,
  bedrooms,
  capacity,
}: inputFilters) => {
  const filters: string[] = []

  const addFilter = (property: string, value: string) => {
    if (Number(value) > 0) {
      filters.push(`${property} >= ${value}`)
    }
  }

  addFilter('bathrooms', bathrooms)
  addFilter('bedrooms', bedrooms)
  addFilter('capacity', capacity)

  return filters
}
