import { InputText } from '~/components/BaseForm/core/primitives'

const Address: typeof InputText = {
  ...InputText,
  attr: {
    ...InputText.attr,
    rules: 'required',
    labelTranslateKey: 'form.address',
    name: 'address',
  },
}

export default Address
