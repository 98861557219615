export default (clientHttp, apiVersion) => ({
  read(id) {
    // Index
    if (Array.isArray(id)) {
      const ids = `?ids=${id.join(',')}`

      return clientHttp.get(`${apiVersion}/experiences${ids}`)
    }

    // Show
    return clientHttp.get(`${apiVersion}/experiences/${id}`)
  },
})
