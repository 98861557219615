import {
  QueryModelType,
  Locale,
  SupportedCurrencies,
  Mode,
} from "../../../../../types";

import { SearchHousesWithoutDatesRepository } from "./interfaces/SearchHousesWithoutDatesRepository";
import { SearchHousesPresentation } from "../interfaces/SearchHousesPresentation";
import { GetSearchParamsType } from "../types";

import { Search } from "../../../domain";
import { PRICES } from "../../../domain/helpers";
import { SearchType } from "../../../../../services/algolia";
import { SearchRepository } from "../SearchHousesWithDates/interfaces/SearchHousesWithDatesRepository";

export default class SearchHousesWithoutDatesUseCase extends Search {
  constructor(private repository: SearchHousesWithoutDatesRepository) {
    super();
  }

  async getSearchHousesInfo(
    input: GetSearchParamsType,
    deviceMode: "mobile" | "web",
    mode: Mode,
    repository: SearchRepository,
    locale?: Locale,
    algoliaFilters?: string
  ) {
    const searchParams = this.getSearchParams(
      input,
      deviceMode,
      mode,
      locale as Locale
    );

    return await this.getBrowseResponse({
      ...searchParams,
      repository,
      mode,
      algoliaFilters,
    });
  }

  async execute(
    presenter: SearchHousesPresentation,
    {
      bathrooms = "",
      bedrooms = "",
      capacity = "",
      destinationIds = [],
      housesIds = [],
      facetFilters = new Map(),
      initializelist = false,
      itemsPerPage = 15,
      page = 0,
      prices = [PRICES.MIN_PRICE_CHOICE, PRICES.MAX_PRICE_CHOICE],
      query = "",
      replica = "recommended",
      mode = "client",
      algoliaFilters = "",
    }: QueryModelType,
    deviceMode: "mobile" | "web",
    locale: Locale,
    currency: SupportedCurrencies | null,
    algoliaIds: SearchType
  ) {
    try {
      const { algolia } = this.repository;
      const repository = {
        algolia: algolia(algoliaIds),
      } as SearchRepository;
      this.setCurrentPage(page);
      this.setHitsPerPage(itemsPerPage);
      this.setCurrency(currency || undefined);
      this.setLocale(locale);

      const input = {
        bathrooms,
        bedrooms,
        capacity,
        currency: this.currency,
        destinationIds,
        housesIds,
        facetFilters,
        initializelist,
        maxPrice: prices[1] || PRICES.MAX_PRICE_CHOICE,
        minPrice: prices[0] || PRICES.MIN_PRICE_CHOICE,
        page,
        query,
        replicaValue: replica,
        mode,
        deviceMode,
        algoliaFilters,
      };

      if (initializelist) {
        this.initializeList();
        this.setCurrentPage(page);

        const hits = await this.getSearchHousesInfo(
          { ...input },
          deviceMode,
          mode,
          repository,
          locale,
          algoliaFilters
        );

        this.facetsFilters = await this.getSearchFacetsWithPeriod({
          input: { ...input },
          mode,
          getSearchParams: () =>
            this.getSearchParams(input, deviceMode, mode, locale as Locale),
          repository,
          locale,
          algoliaFilters,
        });
        const sortedHits = this.sortHitsByPublicPrice(hits);
        this.setSortedHits(sortedHits);

        this.chunkedHits = this.chunkHits(sortedHits);
        this.setNbHits(hits.length);
        this.setNbPages(this.chunkedHits.length);

        presenter.displayFacets(this.facetsFilters);
        presenter.displayNbPages(this.nbPages);
        presenter.displayNbHouses(this.nbHits);
        presenter.displayAlgoliaQueryId();
      }

      presenter.displaySearchCurrentPage(this.currentPage);

      if (this.canPaginate() && this.chunkedHits.length) {
        this.currentHits = this.getDistinctHits([
          ...this.currentHits,
          ...this.getHits(housesIds),
        ]);
        presenter.displaySearchHousesResult(this.currentHits);
      }

      if (!this.chunkedHits.length) {
        this.currentHits = [];
        presenter.displaySearchHousesResult(this.currentHits);
      }
    } catch (err) {
      console.log("SearchHousesWithoutDatesUseCase", err);
    }
  }
}
