<script setup lang="ts">
import type { DestinationGroup } from '~/types/header/megaMenuDestinations'

const { t, locale } = useI18n()
const { destinationGroups, linkGroups, getHref, sendTracking } =
  useMegaMenuDestinations()

const { data: getDestinationsData } = await useFetch(
  `/api/${locale.value}/header-destinations`,
)

const selectedGroup = ref<DestinationGroup>('france')

const setSelectedGroup = (group: DestinationGroup) => {
  selectedGroup.value = group

  sendTracking(group)
}
</script>

<template>
  <div class="h-[38rem]">
    <div class="lc-container mt-16">
      <div class="mb-8 flex justify-center">
        <div
          v-for="group in destinationGroups"
          :key="`group-${group.key}`"
          :class="[
            'ml-7 cursor-pointer hover:font-bold hover:underline hover:underline-offset-[14px]',
            {
              'font-bold underline underline-offset-[14px]':
                group.key === selectedGroup,
            },
          ]"
          @click="setSelectedGroup(group.key)"
        >
          {{ group.name }}
        </div>
      </div>

      <NuxtLink
        v-for="group in linkGroups"
        :key="`link-group-${group.key}`"
        :class="[
          'mt-6 font-bold text-gray-700',
          { hidden: group.key !== selectedGroup },
        ]"
        :to="group.link"
      >
        {{ t('headerDestination.seeAllProperties') }}
      </NuxtLink>

      <div
        :class="[
          'mt-7 gap-5',
          {
            'laptop:columns-6 desktop:columns-8':
              selectedGroup !== 'restOfTheWorld',
            'columns-1': selectedGroup === 'restOfTheWorld',
          },
        ]"
      >
        <div
          v-for="destination in getDestinationsData"
          :key="destination.id"
          :class="[{ hidden: selectedGroup !== destination.group }]"
        >
          <TheHeaderDesktopDropdownsDestinationLink
            v-if="destination.name"
            :class="['mb-2.5', { 'underline-link': getHref(destination.link) }]"
            :link="getHref(destination.link)"
            :name="destination.name"
            :setup-style="destination.style"
          />

          <ul v-if="destination.children?.length" class="mb-0">
            <li
              v-for="subRegion in destination.children"
              :key="`subRegion-${subRegion.id}-${subRegion.order}`"
              :class="[
                'elem last:pb-5',
                { 'elem-bold': subRegion.style === 'bold' },
              ]"
            >
              <TheHeaderDesktopDropdownsDestinationLink
                v-if="subRegion.name"
                :class="{ 'mb-4': subRegion.style === 'title' }"
                :link="getHref(subRegion.link)"
                :name="subRegion.name"
                :setup-style="subRegion.style"
              />

              <ul v-if="subRegion.children?.length" class="mb-0">
                <li
                  v-for="region in subRegion.children"
                  :key="`region-${region.id}-${region.order}`"
                  :class="['elem', { 'elem-bold': region.style === 'bold' }]"
                >
                  <TheHeaderDesktopDropdownsDestinationLink
                    v-if="region.name"
                    :link="getHref(region.link)"
                    :name="region.name"
                    :setup-style="region.style"
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.elem:has(+ .elem-bold) {
  @apply pb-4;
}
</style>
