<script setup lang="ts">
const {
  public: { hubspotMeetingSchedulerEmbed },
} = useRuntimeConfig()
const container = ref<HTMLDivElement | null>(null)
const { closeScheduler, schedulerIsOpen } = useMeetingScheduler(container)
</script>

<template>
  <BaseModalV2
    v-model:model-value="schedulerIsOpen"
    size="m"
    has-close-button
    mobile-full-height
    @close="closeScheduler"
  >
    <template #content>
      <div
        ref="container"
        class="meetings-iframe-container sm:min-h-[615px]"
        :data-src="hubspotMeetingSchedulerEmbed"
      />
    </template>
  </BaseModalV2>
</template>
