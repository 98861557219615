import type { FetchOptions } from 'ofetch'

export const useCustomFetch = <T = unknown>({
  baseURL,
  url,
  method = 'GET',
  options = {},
}: {
  baseURL: string
  url: string
  method?:
    | 'options'
    | 'get'
    | 'GET'
    | 'HEAD'
    | 'PATCH'
    | 'POST'
    | 'PUT'
    | 'DELETE'
    | 'CONNECT'
    | 'OPTIONS'
    | 'TRACE'
    | 'head'
    | 'patch'
    | 'post'
    | 'put'
    | 'delete'
    | 'connect'
    | 'trace'
  options: Omit<FetchOptions, 'method'>
}) => {
  // Define default options
  const defaults = {
    baseURL,
    key: url,
    method,
    ...options,
  }

  if (options.params) {
    const queryString = new URLSearchParams(options.params).toString()

    defaults.key = `${url}?${queryString}`
  }

  return $fetch<T>(url, defaults)
}
