import { InputSelect } from '~/components/BaseForm/core/primitives'

const Identity: typeof InputSelect = {
  ...InputSelect,
  attr: {
    ...InputSelect.attr,
    labelTextTranslateKey: 'form.status',
    name: 'identity',
    rules: 'required',
  },
  containerInputClass: 'w-full md:w-1/2',
  options: [
    {
      value: 'individual',
      labelTranslateKey: 'form.individual',
    },
    { value: 'company', labelTranslateKey: 'form.company' },
  ],
}

export default Identity
