import { hasOwnProperty } from '~/helpers/objectHelper'

const recursiveDeepCopy = (o: any): any => {
  let newO, i

  if (typeof o !== 'object') {
    return o
  }
  if (!o) {
    return o
  }

  if (Array.isArray(o)) {
    newO = []
    for (i = 0; i < o.length; i += 1) {
      newO[i] = recursiveDeepCopy(o[i])
    }

    return newO
  }

  if (typeof o === 'object') {
    newO = {} as any
    for (i in o) {
      if (hasOwnProperty(o, i)) {
        newO[i] = recursiveDeepCopy(o[i]) as any
      }
    }

    return newO as any
  }
}
export { recursiveDeepCopy }
