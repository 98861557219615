import { ClientContractVM } from "./ViewModel";
import { GetClientContractPresentation } from "../usecases/GetClientContract/interfaces/GetClientContractPresentation";
import { ConfirmClientContractPresentation } from "../usecases/ConfirmClientContract/interfaces/ConfirmClientContractPresentation";
import {
  IdentityDocumentInformation,
  VerifyIdentityDocumentPresentation,
} from "../usecases/VerifyIdentityDocument/interfaces/VerifyIdentityDocumentPresentation";
import { SendClientContractInformationPresentation } from "../usecases/SendClientContractInformation/interfaces/SendClientContractInformationPresentation";
import { ApiError, ClientContractFormatted, ToastInfo } from "../../../types";
import Presenter from "../../../abstracts/Presenter";

export default class ClientContractPresenter
  extends Presenter<ClientContractVM>
  implements
    GetClientContractPresentation,
    ConfirmClientContractPresentation,
    VerifyIdentityDocumentPresentation,
    SendClientContractInformationPresentation
{
  constructor() {
    super(new ClientContractVM());
  }

  displayContractClient(clientContract: ClientContractFormatted): void {
    this.vm.clientContract = clientContract;
    this.notifyVM();
  }

  displayToast(info: ToastInfo | null): void {
    this.vm.toast = info;
    this.notifyVM();
  }

  displayError(error: ApiError | undefined): void {
    this.vm.clientContractError = error;
    this.notifyVM();
  }

  displayMessage(msg: string): void {
    this.vm.msg = msg;
    this.vm.loading = false;
    this.notifyVM();
  }

  displayErrors(errors: { fieldName: string; error: string }[]): void {
    this.vm = {
      ...this.vm,
      errors,
    };
    this.notifyVM();
  }

  displayInitialize(): void {
    this.vm = {
      ...this.vm,
      errors: [],
      msg: "",
      navigation: null,
    };
    this.notifyVM();
  }

  displayLoading(): void {
    this.vm.loading = true;
    this.notifyVM();
  }

  displayNavigation(navigation: string): void {
    this.vm.navigation = navigation;
  }

  displayIdentityDocumentInformation(
    identityDocumentInformation: IdentityDocumentInformation
  ): void {
    this.vm.contactInformation = {
      ...this.vm.contactInformation,
      ...identityDocumentInformation,
    };
  }
}
