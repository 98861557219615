// require('./analytics/gtm') // google tag manager

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      metaBase: () => {
        return {
          title: nuxtApp.head.title,
          titleTemplate: nuxtApp.head.titleTemplate,
        }
      },
    },
  }
})
