import { ModifyRequestWishlistRepository } from "./interfaces/ModifyRequestWishlistRepository";
import { ModifyRequestWishlistPresentation } from "./interfaces/ModifyRequestWishlistPresentation";

import type { ModifyRequestWishlistInput } from "../../../../types/common/wishlist";

export default class ModifyRequestWishlistUseCase {
  constructor(private repository: ModifyRequestWishlistRepository) {}

  async execute(
    presenter: ModifyRequestWishlistPresentation,
    input: ModifyRequestWishlistInput
  ) {
    const response = await this.repository.modifyRequestWishlist({
      client_comment: input.client_comment,
      number_of_adults: input.number_of_adults,
      number_of_babies: input.number_of_babies,
      number_of_children: input.number_of_children,
      private_token: input.private_token,
    });

    if (response?.errors) {
      presenter.displayModifyRequestWishlistSucceeded(false);
    } else {
      presenter.displayModifyRequestWishlistSucceeded(true);
    }
  }
}
