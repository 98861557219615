import {
  HouseDetailsResponse,
  HouseIncluded,
} from '../usecases/GetHouseDetails/types'

export class KeyFeatures {
  constructor(
    protected houseResponse: HouseDetailsResponse,
    protected houseInclude: HouseIncluded
  ) {}

  public getKeyFeatures() {
    const orderedKeyFeatures = [
      { type: 'equipmentType', identifier: 'pool' },
      { type: 'surroundingInformation', identifier: 'privateBeach' },
      { type: 'surroundingInformation', identifier: 'beach' },
      { type: 'surroundingInformation', identifier: 'skiTrail' },
      { type: 'surroundingInformation', identifier: 'viewType' },
      { type: 'equipmentType', identifier: 'air_conditioning' },
      { type: 'area', identifier: 'tennis_court' },
      { type: 'area', identifier: 'padel_court' },
      { type: 'house', identifier: 'elevator' },
      { type: 'equipmentType', identifier: 'jacuzzi' },
      { type: 'equipmentType', identifier: 'hammam' },
      { type: 'equipmentType', identifier: 'sauna' },
      { type: 'equipmentType', identifier: 'nordic_bath' },
      { type: 'area', identifier: 'petanque' },
      { type: 'area', identifier: 'football_field' },
      { type: 'area', identifier: 'badminton_court' },
      { type: 'area', identifier: 'volleyball_court' },
      { type: 'area', identifier: 'basketball_court' },
      { type: 'area', identifier: 'fitness_room' },
      { type: 'area', identifier: 'movie_room' },
      { type: 'area', identifier: 'massage_room' },
      { type: 'area', identifier: 'conference_room' },
      { type: 'equipmentType', identifier: 'barbecue' },
      { type: 'equipmentType', identifier: 'grill' },
      { type: 'equipmentType', identifier: 'fireplace' },
      { type: 'includedServices', identifier: 'chef' },
      { type: 'includedServices', identifier: 'butler' },
      { type: 'includedServices', identifier: 'staff_at_home' },
      { type: 'includedServices', identifier: 'car_with_driver' },
      { type: 'includedServices', identifier: 'breakfast_preparation' },
      { type: 'includedServices', identifier: 'guardian' },
      { type: 'includedServices', identifier: 'villa_manager' },
      { type: 'includedServices', identifier: 'chalet_manager' },
      { type: 'includedServices', identifier: 'home_fitting_of_ski_equipment' },
      { type: 'includedServices', identifier: 'security_guard' },
      { type: 'includedServices', identifier: 'transfer' },
      { type: 'includedServices', identifier: 'free_access_to_a_golf_course' },
      { type: 'surroundingInformation', identifier: 'golf' },
      {
        type: 'includedServices',
        identifier: 'free_access_to_a_shared_tennis_court',
      },
      { type: 'includedServices', identifier: 'free_access_to_a_shared_gym' },
      { type: 'area', identifier: 'garden' },
      { type: 'area', identifier: 'pool_house' },
      { type: 'area', identifier: 'game_room' },
      { type: 'area', identifier: 'billiard_room' },
      { type: 'surroundingInformation', identifier: 'naturalReserve' },
      { type: 'surroundingInformation', identifier: 'privateDomain' },
      { type: 'equipmentType', identifier: 'vineyard' },
      { type: 'surroundingInformation', identifier: 'surroundedByFields' },
      { type: 'house', identifier: 'floorHeatingSystem' },
      { type: 'house', identifier: 'coolingFloorSystem' },
      { type: 'equipmentType', identifier: 'vineyard' },
      { type: 'equipmentType', identifier: 'jacuzzi_bathtub' },
      { type: 'equipmentType', identifier: 'hammam_shower' },
      { type: 'area', identifier: 'ski_room' },
      { type: 'equipmentType', identifier: 'boot_warmer' },
    ]

    const coolingFloorSystem = this.houseResponse.coolingFloorSystem
      ? {
          identifier: 'coolingFloorSystem',
          name: {
            en: 'Cooling floor system',
            fr: 'Système de refroidissement au sol',
          },
        }
      : {}

    const floorHeatingSystem = this.houseResponse.floorHeatingSystem
      ? {
          identifier: 'floorHeatingSystem',
          name: {
            en: 'Floor heating system',
            fr: 'Système de chauffage au sol',
          },
        }
      : {}

    const allFeatures = [
      ...this.houseIncludedElementFormatted(this.houseInclude.areas),
      ...this.houseIncludedElementFormatted(this.houseInclude.includedServices),
      ...this.houseIncludedElementFormatted(this.houseInclude.equipmentTypes),
      coolingFloorSystem,
      floorHeatingSystem,
    ]

    const filteredFeatures = orderedKeyFeatures
      .map((orderFeature) =>
        allFeatures
          .filter(Boolean)
          .find((feature) => feature.identifier === orderFeature.identifier)
      )
      .filter(Boolean)

    return filteredFeatures
  }

  protected houseIncludedElementFormatted(
    houseIncludeElement: object | object[]
  ) {
    return Array.isArray(houseIncludeElement)
      ? houseIncludeElement
      : [houseIncludeElement]
  }
}
