<script setup lang="ts">
const { headerMobile, setMenuIsOpen, setSlidePageActive } = useHeaderMobile()

const menuMobileIsOpen = computed(() => headerMobile.value.menuIsOpen)
const menuMobileSlidePageActive = computed(
  () => headerMobile.value.slidePageActive,
)
const showSlideMenu = computed({
  get() {
    return menuMobileIsOpen.value
  },
  set(value) {
    setMenuIsOpen(value)
  },
})
const slidePageActive = computed({
  get() {
    return menuMobileSlidePageActive.value
  },
  set(value) {
    setSlidePageActive(value)
  },
})
const pageSlided = computed(() => Boolean(slidePageActive.value))

const toggleNav = () => {
  showSlideMenu.value = false
}
</script>

<template>
  <TheMenuMobileScreenSkeleton
    :menu-open="menuMobileIsOpen"
    :page-slided="pageSlided"
  >
    <template #main-screen>
      <TheMenuMobileScreenLinks />
    </template>
    <template #secondary-screen>
      <TransitionGroup name="fade" tag="div">
        <TheMenuMobileScreenDestinations
          v-show="slidePageActive === 'destinations'"
          key="destinations"
          @close-menu-mobile="toggleNav"
        />
        <TheMenuMobileScreenInspirations
          v-show="slidePageActive === 'inspirations'"
          key="inspirations"
          @close-menu-mobile="toggleNav"
        />
        <TheMenuMobileScreenAbout
          v-show="slidePageActive === 'aboutUs'"
          key="about-us"
          @close-menu-mobile="toggleNav"
        />
      </TransitionGroup>
    </template>
  </TheMenuMobileScreenSkeleton>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
