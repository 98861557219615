<script setup lang="ts">
import type { RouteLocation } from 'vue-router'

type SearchLink = {
  id: number
  name: string
  href: string
  image: string
  search?: { destinationId: number; name: { en: string; fr: string } }
  subName?: string
}

const props = withDefaults(
  defineProps<{
    title?: string
    links?: SearchLink[]
    searchQuery?: string
  }>(),
  {
    title: '',
    links: () => [],
    searchQuery: '',
  },
)
const emits = defineEmits<{
  'link-clicked': [RouteLocation]
}>()
const vueRoute = useRoute()
const { trackEvent } = useTracking()
const { setSearchDestination } = useHeaderNavigation()

const linkClicked = (r: RouteLocation, link: SearchLink) => {
  if (link.search) setSearchDestination(link.search)

  emits('link-clicked', r)

  const productId = link?.id || link?.search?.destinationId

  trackEvent({
    event: 'search_suggestion_click',
    house_id: productId,
    house_name: link.name,
    label: {
      label: 'search_header_suggestion',
      source: String(vueRoute?.name ?? '').slice(0, -5),
      product_name: link.name,
    },
    source: 'header - loop',
    suggestion: 'location',
    search_query: props.searchQuery,
    is_autocomplete: Boolean(props.searchQuery),
  })
}
</script>

<template>
  <div>
    <p
      v-if="title"
      class="mb-4 border-b border-gray-200 pb-2.5 text-sm font-bold uppercase leading-none text-gray-300 laptop:mb-6 laptop:border-0 laptop:pb-0"
      data-testid="search-title"
    >
      {{ title }}
    </p>
    <ul v-if="links.length > 0" class="m-0">
      <li
        v-for="(link, index) in links"
        :key="link.name"
        :class="['flex', { 'mt-4': index > 0 }]"
      >
        <NuxtLink v-slot="{ href, route }" :to="link.href" custom>
          <a
            data-testid="search-link"
            class="search-link"
            :data-v-inspector="$attrs['data-v-inspector']"
            :href="href"
            @click.prevent="linkClicked(route, link)"
          >
            <BaseNuxtImg
              :alt="link.name"
              :src="link.image"
              aria-hidden
              class="wrap-image-with-border max-h-[60px] max-w-[60px] flex-none rounded-md"
              height="60"
              loading="lazy"
              width="60"
            />
            <span class="block grow pl-8">
              <span class="block font-medium">{{ link.name }}</span>
              <template v-if="link.subName">{{ link.subName }}</template>
            </span>
          </a>
        </NuxtLink>
      </li>
    </ul>
    <p v-else class="text-gray-500">
      {{ $t('headerSearch.noResult') }}
    </p>
  </div>
</template>

<style scoped>
.search-link {
  user-select: none;
  @apply flex items-center text-gray-700 bg-transparent outline-none focus:outline-none hover:text-gray-700 hover:no-underline;
}
</style>
