import { Password } from '../fields'

const PasswordConfirmation: typeof Password = {
  ...Password,
  attr: {
    ...Password.attr,
    labelTranslateKey: 'form.passwordConfirmation',
    name: 'passwordConfirmation',
    rules: 'required|confirmed:@password',
  },
}

export default PasswordConfirmation
