import { GetUserWishlistRepository } from "./interfaces/GetUserWishlistRepository";
import { GetUserWishlistPresentation } from "./interfaces/GetUserWishlistPresentation";
import { WishlistDetails } from "../../domain/WishlistDetails";

export default class GetUserWishlistUseCase {
  constructor(private repository: GetUserWishlistRepository) {}

  async execute(presenter: GetUserWishlistPresentation, wishlistToken: string) {
    const response = await this.repository.getWishlist({
      wishlistToken,
    });

    if (response.data) {
      const wishlist = new WishlistDetails(response.data).build();
      presenter.displayWishlist(wishlist);
    }
  }
}
