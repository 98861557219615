import { HttpService } from '~/repository/config/http-service'

const strapiApi = ({ baseURL, locale, $cookies, fetcher }) => {
  const clientApi = new HttpService({
    $cookies,
    baseURL,
    hasHeader: false,
    hasHttpSource: false,
    fetcher,
  })

  return {
    getAllCollections: (lpDestination = null, lpInspiration = null) => {
      const filters = []

      filters.push(
        lpDestination
          ? `filters[lp_destination]=${lpDestination}`
          : 'filters[lp_destination]',
      )
      filters.push(
        lpInspiration
          ? `filters[lp_inspiration]=${lpInspiration}`
          : 'filters[lp_inspiration]',
      )

      return clientApi.get(
        `/api/collection-groups?sort=id&${filters.join('&')}`,
      )
    },
    getAllIconics: () => clientApi.get('/api/iconics?populate=*&sort=position'),
    getAllPopularDestinations: (lpDestination = null, lpInspiration = null) => {
      const filters = []

      filters.push(
        lpDestination
          ? `filters[lp_destination]=${lpDestination}`
          : 'filters[lp_destination]',
      )
      filters.push(
        lpInspiration
          ? `filters[lp_inspiration]=${lpInspiration}`
          : 'filters[lp_inspiration]',
      )

      return clientApi.get(
        `/api/popular-destinations?sort=position&${filters.join('&')}`,
      )
    },
    getAllInspirations: () =>
      clientApi.get('/api/inspirations?populate=*&sort=position'),
    getLpInspirationBySlug: (slug) =>
      clientApi.get(
        `/api/lp-inspirations/find-by-slug/${slug}?locale=${locale}`,
      ),
    getLpDestinationBySlug: (slug) =>
      clientApi.get(
        `/api/lp-destinations/find-by-slug/${slug}?locale=${locale}`,
      ),
    getAllLpInspiration: () =>
      clientApi.get('/api/lp-inspirations?populate=*&sort=name'),
    getAllLpDestination: () =>
      clientApi.get('/api/lp-destinations?populate=*&sort=name'),
    getParentTopDestination: (pagination = 'pagination[limit]=-1') =>
      clientApi.get(
        `/api/top-destinations?populate=*&filters[parent_destination_id]&${pagination}&sort=order`,
      ),
    getChildrenTopDestination: (pagination = 'pagination[limit]=-1') =>
      clientApi.get(
        `/api/top-destinations?populate=*&filters[parent_destination_id][$null]&${pagination}&sort=order`,
      ),
    getFacetsFilterByUrl: (slug) =>
      clientApi.get(`/api/facets-filter/find-by-url/${slug}?locale=${locale}`),
    getFacetsFilterByDestinationUrl: (destination_slug, slug) =>
      clientApi.get(
        `/api/facets-filter/find-by-destination-url/${destination_slug}/${slug}?locale=${locale}`,
      ),
    getFacetsFilterByDestinationId: (destinationId) => {
      const filters = `filters[destination_id]=${destinationId}`

      return clientApi.get(`/api/facets-filters?${filters}`)
    },
  }
}

export { strapiApi }
