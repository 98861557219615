export default defineNuxtPlugin((nuxtApp) => {
  const { $httpCookies } = nuxtApp
  const regex = /\b[a-z]{2}-([A-Z]{2})\b(?=[,;]|$)/g
  const headers = useRequestHeaders()
  const browserLanguageCookie = $httpCookies?.get('browser_language')
  const browserLanguage = useState('browserLanguage')

  if (import.meta.server) {
    if (browserLanguageCookie) {
      browserLanguage.value = browserLanguageCookie
    } else if (headers['accept-language']) {
      // en-US,en;q=0.9 => US
      const match = regex.exec(headers['accept-language'])
      if (match !== null) browserLanguage.value = match[1]
    }
  } else if (!browserLanguageCookie) {
    $httpCookies?.set('browser_language', browserLanguage.value)
  }
})
