<script setup lang="ts">
import type { RouteLocation } from 'vue-router'

const { closeDropdown } = useHeaderDesktop()
const { setNextRoute } = useHeaderNavigation()

const closeDropdownAndRedirect = (route: RouteLocation) => {
  // trigger async navigation
  setNextRoute(route)
}
</script>

<template>
  <div class="dropdown-search flex flex-col pt-14">
    <the-header-search class="grow" @link-clicked="closeDropdownAndRedirect" />

    <div class="mb-6 flex justify-center">
      <the-header-close-button
        aria-controls="header-dropdown"
        @close="closeDropdown"
      />
    </div>
  </div>
</template>

<style scoped>
.dropdown-search {
  height: 38rem;
}
</style>
