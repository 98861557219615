import Controller from "./adapters/Controller";
import Presenter from "./adapters/Presenter";
import { GetUserPropertiesByLocationUseCase } from "./usecases";
import { UserLocation } from "lc-repository";

export const userPropertiesBuild = (repository: UserLocation) => {
  const presenter = new Presenter();
  const controller = new Controller(
    {
      getUserPropertiesByLocation: new GetUserPropertiesByLocationUseCase({
        getUserLocation: async () => repository.getUserLocation(),
      }),
    },
    presenter
  );

  return { controller, presenter };
};
