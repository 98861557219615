import type { UserHouse } from '~/repository/entities/user'

export const useUserHouses = () => {
  const { $api } = useNuxtApp()

  const userHousesState = useState<{
    properties: UserHouse[]
    signatoryProperties: UserHouse[]
  }>('state-user-houses', () => ({
    properties: [],
    signatoryProperties: [],
  }))

  const properties = computed(() => userHousesState.value.properties)
  const propertyIds = computed(() => properties.value.map((p) => Number(p.id)))
  const signatoryPropertyIds = computed(() =>
    userHousesState.value.signatoryProperties.map((p) => Number(p.id)),
  )

  const fetchProperties = async () => {
    const properties = await $api.v1.userHouses.read()

    userHousesState.value.properties = properties?.data?.length
      ? properties.data
      : []
  }

  const fetchSignatoryProperties = async () => {
    const signatoryProperties =
      await $api.v2.userSignatoryHouses.read('?per=100')

    userHousesState.value.signatoryProperties = signatoryProperties?.data
      ?.length
      ? signatoryProperties.data
      : []
  }

  return {
    fetchProperties,
    fetchSignatoryProperties,
    properties,
    propertyIds,
    signatoryPropertyIds,
  }
}
