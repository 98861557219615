<script setup lang="ts">
import TheHeaderDropdownTemplate from '~/components/TheHeader/Desktop/Dropdowns/TheHeaderDropdownTemplate.vue'

import { aboutLinks } from '~/assets/js/aboutLinks'
import { formatRoute } from '~/helpers/formatRoute.js'
import type { RouteLocation } from 'vue-router'

const Route = useRoute()
const localePath = useLocalePath()
const { setNextRoute } = useHeaderNavigation()

const activeLink = ref('customHolidays')
const links = ref(aboutLinks)

const onLinkClicked = (route: RouteLocation) => {
  // trigger async navigation
  setNextRoute(route)
}

const setActiveLink = (link: string) => {
  activeLink.value = link
}

onMounted(() => {
  // need to set active link for pages contained in the nav
  const formattedRoute = formatRoute(String(Route.name ?? ''))

  if (links.value.some((link) => link.href === formattedRoute)) {
    activeLink.value = formattedRoute
  }
})
</script>

<template>
  <TheHeaderDropdownTemplate>
    <template #content-left>
      <div class="mt-6 flex h-full items-center">
        <div class="relative size-[300px]">
          <template v-for="link in links" :key="link.img">
            <div
              class="wrap-image-with-border absolute left-0 top-0 mx-auto rounded-md"
            >
              <BaseNuxtImg
                :key="link.img"
                :alt="$t(link.text)"
                :src="link.img"
                height="300"
                loading="lazy"
                width="300"
                :class="[
                  'duration-300',
                  {
                    'opacity-100': link.href === activeLink,
                    'opacity-0': link.href !== activeLink,
                  },
                ]"
              />
            </div>
          </template>
        </div>

        <nav class="w-3/5 pl-12">
          <p
            id="universe"
            class="font-monospace text-6xl font-bold text-gray-700"
          >
            {{ $t('headerAbout.universe') }}
          </p>

          <ul aria-labelledby="universe">
            <li
              v-for="link in links"
              :key="link.href"
              class="relative mt-4 h-6 max-w-max"
            >
              <NuxtLink
                v-slot="{ href, isActive, route }"
                :to="localePath(link.href)"
                custom
              >
                <a
                  data-testid="dropdown-about__links"
                  :class="[
                    'dropdown-link',
                    { 'dropdown-link--active': isActive },
                  ]"
                  :data-v-inspector="$attrs['data-v-inspector']"
                  :href="href"
                  @mouseover="setActiveLink(link.href)"
                  @click.prevent="onLinkClicked(route)"
                >
                  {{ $t(link.text) }}
                </a>
              </NuxtLink>
            </li>
          </ul>
        </nav>
      </div>
    </template>
    <template #content-right>
      <div
        class="ml-16 mt-12 flex h-full flex-col items-center justify-center text-white"
      >
        <p class="font-monospace text-2xl font-bold">
          {{ $t('headerAbout.submitProperty') }}
        </p>
        <p class="mx-auto mt-4 w-1/2 text-center">
          {{ $t('headerAbout.joinCollection') }}
        </p>
        <NuxtLink v-slot="{ href, route }" :to="localePath('owners')" custom>
          <a
            class="lc-btn--secondary lc-btn--md lc-btn lc-btn--font-bold mt-8"
            :data-v-inspector="$attrs['data-v-inspector']"
            :href="href"
            @click.prevent="onLinkClicked(route)"
          >
            {{ $t('headerAbout.cta') }}
          </a>
        </NuxtLink>
      </div>
    </template>
  </TheHeaderDropdownTemplate>
</template>

<style>
.dropdown-link {
  user-select: none;
  transition: color 0.2s ease-in-out;
  @apply text-gray-700 bg-transparent p-0 inline-block outline-none focus:outline-none hover:text-secondary-500 hover:font-semibold hover:no-underline;
}

.dropdown-link--active {
  @apply text-secondary-500 font-bold;
}

.dropdown-link:after {
  content: '';
  bottom: -4px;
  transform: scale(0);
  @apply absolute left-0 right-0 w-full h-px mx-0 my-auto bg-secondary-500 origin-left transition-transform ease-in-out duration-500;
}

.dropdown-link:not(.dropdown-link--active):hover:after {
  transform: scale(1);
}
</style>
