import type { CoreGroupFields, Field } from '~/components/BaseForm/types'

const useFormattedTranslate = (
  groupFields: CoreGroupFields[],
  t: (key: string, values?: Record<string, unknown>) => string,
) => {
  groupFields.map((group: CoreGroupFields) => {
    //  group title
    if (group.titleTranslateKey) {
      group.title = computed(() => t(`${group.titleTranslateKey}`))
    }

    // group description
    if (group.descriptionTranslateKey) {
      group.description = computed(() => t(`${group.descriptionTranslateKey}`))
    }

    // fields
    group.fields.map((field: Field) => {
      // label
      if (field.attr.labelTranslateKey) {
        field.attr.label = computed(() => t(`${field.attr.labelTranslateKey}`))
      }

      // placeholder
      if (field.attr.placeholderTranslateKey) {
        field.attr.placeholder = computed(() =>
          t(`${field.attr.placeholderTranslateKey}`),
        )
      }

      // basepicker button text
      if (field.attr.buttonTextTranslateKey) {
        field.attr.buttonText = computed(() =>
          t(`${field.attr.buttonTextTranslateKey}`),
        )
      }

      // calendar placeholder
      if (field.attr.placeholderCalendarTranslatedKeys) {
        if (typeof field.attr.placeholderCalendarTranslatedKeys === 'object') {
          field.attr.placeholder = {
            checkIn: computed(() =>
              t(`${field.attr.placeholderCalendarTranslatedKeys?.startDate}`),
            ),

            checkOut: computed(() =>
              t(`${field.attr.placeholderCalendarTranslatedKeys?.endDate}`),
            ),
          }
        }
      }

      // infoText
      if (field?.infoTextTranslateKey) {
        if (
          typeof field?.infoTextTranslateKey === 'object' &&
          field?.infoTextTranslateKey?.key
        ) {
          const key = field.infoTextTranslateKey.key
          const values = field.infoTextTranslateKey?.values

          if (values) {
            field.infoText = computed(() => t(`${key}`, values))
          } else {
            field.infoText = computed(() => t(`${key}`))
          }
        } else {
          field.infoText = computed(() => t(`${field.infoTextTranslateKey}`))
        }
      }

      // input radio
      if (field.inputType === 'radio-group' && field.options) {
        field.options.map((option) => {
          if (option.labelTranslateKey) {
            option.label = computed(() => t(`${option.labelTranslateKey}`))
          }

          return option
        })
      }

      // input checkbox
      if (field.inputType === 'checkbox' && field.fields) {
        field.fields.map((option) => {
          if (option.labelTranslateKey) {
            option.label = computed(() => t(`${option.labelTranslateKey}`))
          }

          return option
        })
      }

      // input select
      if (['multiselect', 'multiselect-search'].includes(field.inputType)) {
        if (field?.attr?.labelTextTranslateKey) {
          field.attr.labelText = computed(() =>
            t(`${field.attr.labelTextTranslateKey}`),
          )
        }

        if (field.options && field.options.length > 0) {
          field.options.map((option) => {
            if (option.labelTranslateKey) {
              option.label = computed(() => t(`${option.labelTranslateKey}`))
            }

            return option
          })
        }
      }

      return field
    })

    return group
  })
}

export default useFormattedTranslate
