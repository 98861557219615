export default class FormError {
  protected errors: { fieldName: string; error: string }[] = [];

  protected testEmailFormat(value: string) {
    const regexp =
      // eslint-disable-next-line no-useless-escape
      /^((([a-z]|\d|[!#$%&'*+\-\/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-\/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i; // eslint-disable-line no-control-regex
    const regexpAccent =
      /[àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]/i;
    return regexp.test(value.trim()) && !regexpAccent.test(value.trim())
      ? null
      : "notEmail";
  }

  protected testPasswordFormat(value: string) {
    const lowerCase = /(?=.*[a-z])/.test(value);
    const uppercase = /(?=.*[A-Z])/.test(value);
    const specialCaracterOrNumber =
      /(?=.*\W)/.test(value) || /(?=.*[0-9])/.test(value);
    const minCaracter = /.{8}/.test(value);

    return lowerCase && uppercase && specialCaracterOrNumber && minCaracter;
  }

  apiErrors(
    errors:
      | {
          response: {
            data: {
              errors: unknown[];
            };
          };
        }
      | undefined,
    fieldName: string
  ) {
    if (errors) this.errors = [{ fieldName, error: "form.apiErrorOccured" }];

    return this;
  }

  phoneWrongFormat(isValidPhoneNumber: boolean, fieldName?: string) {
    if (!isValidPhoneNumber) {
      this.errors.push({
        fieldName: fieldName ? fieldName : "phone",
        error: "phone.phoneFormatHasNotCorrect",
      });
    }

    return this;
  }
}
