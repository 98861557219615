<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    infoText?: string
    label?: string
    labelHtml?: string
    modelValue: boolean
    name: string
    rules?: string
    vertical?: boolean
    wrapperClass?: string
  }>(),
  {
    disabled: false,
    infoText: '',
    label: '',
    labelHtml: '',
    rules: '',
    vertical: false,
    wrapperClass: 'w-full mb-4',
  },
)

const emits = defineEmits<{
  'update:model-value': [boolean]
}>()

const { value: inputValue } = useField(props.name, '', {
  initialValue: props.modelValue,
})

watch(inputValue, () => {
  emits('update:model-value', inputValue.value)
})
</script>

<template>
  <div :class="wrapperClass">
    <div
      :class="[
        'checkbox-toggle-container',
        { 'checkbox-toggle-container--vertical': vertical },
      ]"
      data-testid="checkbox-toggle-input-container"
    >
      <div
        :class="{
          'mr-2': !vertical,
          'mb-2': vertical,
          'text-gray-300': disabled,
        }"
      >
        <BaseFormComponentsBaseFormLabel
          :label="label"
          :label-html="labelHtml"
        />
        <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
      </div>

      <label class="checkbox-toggle">
        <input
          :id="name"
          v-model="inputValue"
          :data-cy="name"
          :disabled="disabled"
          data-testid="checkbox-toggle-input"
          type="checkbox"
        />

        <span
          :class="[
            'checkbox-toggle__slider',
            { 'checkbox-toggle__slider--disabled': disabled },
          ]"
        />

        <div
          :class="[
            'checkbox-toggle__sliding-button',
            { 'checkbox-toggle__sliding-button--disabled': disabled },
          ]"
        >
          <BaseIcon
            class="checkbox-toggle__icon checkbox-toggle__icon--unchecked"
            :class="{ 'checkbox-toggle__icon--disabled': disabled }"
            name="cancel"
          />
          <BaseIcon
            class="checkbox-toggle__icon checkbox-toggle__icon--checked"
            name="check"
          />
        </div>
      </label>
    </div>
    <div class="-ml-2">
      <BaseFormComponentsBaseFieldInfo v-if="infoText" :text="infoText" />
    </div>
    <error-message as="div" :name="name" class="base-form--error" />
  </div>
</template>

<style>
.checkbox-toggle-container {
  @apply flex items-center;
}
.checkbox-toggle-container--vertical {
  @apply flex-col items-start;
}
.checkbox-toggle {
  @apply relative inline-block w-14 h-8;
}
.checkbox-toggle input {
  @apply opacity-0 w-0 h-0;
}
.checkbox-toggle__slider {
  @apply absolute cursor-pointer rounded-2xl top-0 right-0 bottom-0 left-0 bg-gray-200;
}
.checkbox-toggle__slider--disabled {
  @apply bg-white border border-gray-300;
}
.checkbox-toggle__sliding-button {
  @apply absolute w-6 h-6 left-1.5 bottom-1 bg-gray-100 rounded-full pointer-events-none duration-500;
}
.checkbox-toggle__sliding-button--disabled {
  @apply bg-gray-300;
}
.checkbox-toggle__icon {
  @apply absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2;
}
.checkbox-toggle__icon--checked {
  @apply hidden;
}
.checkbox-toggle__icon--unchecked {
  @apply block text-error;
}
input:checked ~ .checkbox-toggle__sliding-button {
  @apply bg-success transform translate-x-5;
}
input:checked
  ~ .checkbox-toggle__sliding-button
  .checkbox-toggle__icon--checked {
  @apply block text-white;
}
input:checked
  ~ .checkbox-toggle__sliding-button
  .checkbox-toggle__icon--unchecked {
  @apply hidden;
}
.checkbox-toggle__icon--unchecked.checkbox-toggle__icon--disabled {
  @apply text-white;
}
</style>
