import { CreateAutomaticProposalCommentRepository } from "./interfaces/CreateAutomaticProposalCommentRepository";
import { CreateAutomaticProposalCommentPresentation } from "./interfaces/CreateAutomaticProposalCommentPresentation";
import { AutomaticProposalCommentDomain } from "../../domain/AutomaticProposalCommentDomain";

import type { CreateAutomaticProposalCommentInput } from "../../../../types/common/automaticProposal";

export default class CreateAutomaticProposalCommentUseCase {
  constructor(private repository: CreateAutomaticProposalCommentRepository) {}

  async execute(
    presenter: CreateAutomaticProposalCommentPresentation,
    input: CreateAutomaticProposalCommentInput
  ) {
    const response = await this.repository.createAutomaticProposalComment({
      automaticProposalId: input.automaticProposalId,
      automaticProposalHouseId: input.automaticProposalHouseId,
      automatic_proposal_comment: input.automatic_proposal_comment,
    });

    if (response?.data) {
      const comment = new AutomaticProposalCommentDomain(response.data).build();
      presenter.displayAutomaticProposalCommentCreated(comment);
    } else {
      presenter.displayAutomaticProposalCommentCreated(null);
    }
  }
}
