import { iconPaths } from '~/assets/js/iconPaths'
import { oldIllustrationPaths } from '~/assets/js/oldIllustrationPaths'
import { newIllustrationPaths } from '~/assets/js/newIllustrationPaths'

const paths = { ...iconPaths, ...oldIllustrationPaths, ...newIllustrationPaths }

export type IconPath = keyof typeof paths

export const useIconPaths = () => {
  return {
    iconPaths,
    oldIllustrationPaths,
    newIllustrationPaths,
    paths,
  }
}
