import { CreateUserWishlistRepository } from "./interfaces/CreateUserWishlistRepository";
import { CreateUserWishlistPresentation } from "./interfaces/CreateUserWishlistPresentation";
import { Wishlists } from "../../domain/Wishlists";

import type { CreateWishlistInput } from "../../../../types/common/wishlist";

export default class CreateUserWishlistUseCase {
  constructor(private repository: CreateUserWishlistRepository) {}

  async execute(
    presenter: CreateUserWishlistPresentation,
    input: CreateWishlistInput
  ) {
    const response = await this.repository.createWishlist({
      title: input.title,
      house_ids: input.house_ids,
    });

    if (response?.data) {
      const wishlists = new Wishlists(response.data).build();
      presenter.displayWishlistCreated(wishlists[0]);
    }
  }
}
