import { GetHouseTruthLinksRepository } from "./interfaces/GetHouseTruthLinksRepository";
import { GetHouseTruthLinksPresentation } from "./interfaces/GetHouseTruthLinksPresentation";
import { HouseTruthLinksDomain } from "../../domain/HouseTruthLinks";

export default class GetHouseTruthLinksUseCase {
  constructor(private repository: GetHouseTruthLinksRepository) {}

  async execute(
    presenter: GetHouseTruthLinksPresentation,
    input: { houseId: string | number; privateToken? : string }
  ) {
    const response = await this.repository.getHouseTruthLinks(input);

    if (response?.data) {
      const houseTruthLinks = new HouseTruthLinksDomain(
        response.data
      ).build();
      presenter.displayHouseTruthLinks(houseTruthLinks);
    } else {
      presenter.displayHouseTruthLinks([]);
    }
  }
}
