import SignInFormError from "../../domain/SignInFormError";
import { dispatch } from "../../domain";
import { SignInWithEmailRepository } from "./interfaces/SignInWithEmailRepository";
import { SignInWithEmailPresentation } from "./interfaces/SignInWithEmailPresentation";
import { SignInWithInput, EmailInput } from "./types";
import { Locale } from "lc-repository/dist/services";
import Formatter from "../../../../services/Formatter";

export default class SignInWithEmailUseCase extends Formatter {
  constructor(private repository: SignInWithEmailRepository) {
    super();
  }

  private async handleEmailNotFound(
    email: string,
    locale: Locale | undefined,
    formError: SignInFormError,
    presenter: SignInWithEmailPresentation
  ) {
    const signUpInput = { user: { email, locale: locale ? locale : "fr" } };
    const { success, errors } = await this.repository.emailSignUp(signUpInput);
    const invalidEmail = formError.emailInvalid(errors).build()[0];

    dispatch.signUpFromEmail(presenter, success, invalidEmail);
  }

  private async getTokenFromEmail(
    input: EmailInput,
    presenter: SignInWithEmailPresentation
  ) {
    const { email, locale } = input;
    const formError = new SignInFormError();
    const emailFormatted = this.emailFormatter(email);
    const inputEmail = { user: { email: emailFormatted } };
    const emailFormErrors = formError.emailWrongFormat(emailFormatted).build();

    if (emailFormErrors.length === 0) {
      const { success, errors } = await this.repository.signIn(inputEmail);
      const emailLocked = formError.emailLoked(errors).build()[0];
      const emailNotFound = formError.emailNotFound(errors).build()[0];
      const accountNotComplete = formError
        .accountNotComplete(errors)
        .build()[0];
      if (success) dispatch.getTokenFromEmail(presenter, success);
      if (emailNotFound || accountNotComplete) {
        await this.handleEmailNotFound(
          emailFormatted,
          locale,
          formError,
          presenter
        );
      }
      if (emailLocked) presenter.displayErrors(emailLocked);
    } else presenter.displayErrors(emailFormErrors[0]);
  }

  async execute(
    presenter: SignInWithEmailPresentation,
    input: SignInWithInput
  ) {
    if (input?.email) {
      const emailInput = { email: input.email, locale: input.locale };
      await this.getTokenFromEmail(emailInput, presenter);
    }
  }
}
