import {
  AutomaticProposalComment,
  AutomaticProposalCommentFormatted,
} from "../../../types/common/automaticProposal";

export class AutomaticProposalCommentDomain {
  private commentFormatted: AutomaticProposalCommentFormatted;

  constructor(protected comment: AutomaticProposalComment) {
    this.commentFormatted = {
      accepted: this.comment.accepted,
      clientComment: this.comment.clientComment,
      email: this.comment.email,
      fullName: this.comment.fullName,
      id: this.comment.id,
    };
  }

  build() {
    return this.commentFormatted;
  }
}
