<script setup lang="ts">
import type { SupportedCurrencies } from 'lc-services/types'

const { currency, setCurrency } = useUser()

withDefaults(
  defineProps<{
    size?: string
    disabled?: boolean
  }>(),
  {
    size: 'sm',
    disabled: false,
  },
)

const { $httpCookies } = useNuxtApp()

const newCurrency = ref<SupportedCurrencies | null>(null)
const currencies = ref(['CHF', 'EUR', 'GBP', 'USD'])

const currencyDisplayed = computed({
  get() {
    return newCurrency.value || currency.value
  },
  set(val) {
    newCurrency.value = val
  },
})

const handleCurrency = () => {
  if (newCurrency.value) setCurrency(newCurrency.value)
  $httpCookies?.set('currency', newCurrency.value)
}
</script>

<template>
  <LazyBaseFormComponentsBaseMultiselect
    v-model="currencyDisplayed"
    :can-clear="false"
    :can-deselect="false"
    :caret="false"
    :options="currencies"
    class="switch-currency"
    input-class="w-4/5"
    name="switch-currency"
    @input="handleCurrency"
  >
    <template #multiselect-singlelabel>
      <div class="multiselect-single-label flex items-center">
        <span class="text-primary-500">
          {{ currencyDisplayed }}
        </span>
      </div>
    </template>
  </LazyBaseFormComponentsBaseMultiselect>
</template>

<style>
/* to overwrite vue-multiselect css */
.switch-currency {
  @apply bg-transparent !border-gray-600;
}

#switch-currency-dropdown {
  @apply bg-gray-700 border-gray-600 text-white;
}

#switch-currency-multiselect-options {
  @apply bg-transparent;
}

#switch-currency-multiselect-options li {
  @apply bg-gray-700 text-white;
}

#switch-currency-multiselect-options li.is-selected {
  @apply bg-primary-600;
}

#switch-currency-multiselect-options li:hover:not(.is-selected) {
  @apply bg-gray-600;
}
</style>
