import type { BaseFormButton } from '~/components/BaseForm/types'

export const useButtons = () => {
  const { t } = useI18n()

  const btnPrimary: BaseFormButton = reactive({
    attrs: {
      color: 'secondary',
      class: 'mt-4 md:mt-0',
    },
    text: computed(() => t('global.form.submitSave')),
  })

  const btnSecondary: BaseFormButton = reactive({
    attrs: {
      color: 'secondary',
      class: '',
    },
    text: computed(() => t('global.form.cancel')),
  })

  return {
    btnPrimary,
    btnSecondary,
  }
}
