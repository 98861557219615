import { HousePeriodsResponseDataFormatted } from "../usecases/GetHousePeriods/types";
import { HousePeriodsResponseData } from "../../../types";

export class HousePeriods {
  private housePeriodsFormatted: HousePeriodsResponseDataFormatted;

  constructor(
    private housePeriodsResponse: HousePeriodsResponseData,
    private houseId: string
  ) {
    this.housePeriodsFormatted = {
      fullyCovered: this.housePeriodsResponse.fullyCovered,
      houseId: this.houseId,
      price: this.housePeriodsResponse.publicPrice,
      rangeDate: {
        endDate: this.housePeriodsResponse.endAt,
        startDate: this.housePeriodsResponse.startAt,
      },
      screenToRedirect: "InquirySummary",
    };
  }

  build() {
    return this.housePeriodsFormatted;
  }
}
