import { GetAutomaticProposalsRepository } from "./interfaces/GetAutomaticProposalsRepository";
import { GetAutomaticProposalsPresentation } from "./interfaces/GetAutomaticProposalsPresentation";
import { AutomaticProposals } from "../../domain/AutomaticProposals";
import { GetAutomaticProposalsInput } from "lc-repository/dist/repositories/lc/v3/automaticProposals/getAutomaticProposals";
import { GetHouseIncludedServicesRepository } from "modules/search/usecases/GetHouseIncludedServices/interfaces/GetHouseIncludedServicesRepository";
import { LocalizedString } from "lc-repository/dist/entities";
import { AutomaticProposalSorted } from "../../domain/AutomaticProposalSorted";

export default class GetAutomaticProposalsUseCase {
  constructor(
    private repository: GetAutomaticProposalsRepository,
    private includedServicesRepository: GetHouseIncludedServicesRepository
  ) {}

  async execute(
    presenter: GetAutomaticProposalsPresentation,
    input: GetAutomaticProposalsInput
  ) {
    const response = await this.repository.getAutomaticProposals({
      ...input,
      include: {
        include: [
          "houses",
          "houses.destination",
          "houses.surrounding_information",
          "houses.stay_information",
        ],
        fields: {
          house: [
            "bathrooms",
            "bedrooms",
            "capacity",
            "conciergeServiceOffer",
            "destination",
            "firstPhotoUrl",
            "gpslatitude",
            "gpslongitude",
            "id",
            "name",
            "privateToken",
            "slug",
            "surface",
            "surroundingInformation",
          ],
          destination: ["clusterizedName", "timezone"],
          stayInformation: [
            "house",
            "householdFrequency",
            "housekeepingHours",
            "petFriendly",
            "suitableForChildren",
            "suitableForPeopleWithReducedMobility",
          ],
        },
      },
    });
    if (response?.data && response?.included) {
      const automaticProposalFormatted = new AutomaticProposals(
        response.data,
        response.included,
        this.includedServicesRepository.getHouseIncludedServices
      ).build();

      const getIncludedServices = async (houseId: string) => {
        let includedServicesFormatted: LocalizedString[] = [];
        const includedServicesResponse =
          await this.includedServicesRepository.getHouseIncludedServices({
            houseId,
          });
        if (includedServicesResponse?.data?.length) {
          includedServicesFormatted = includedServicesResponse.data.map(
            (service) => service.name
          );
        }
        return includedServicesFormatted;
      };

      const formattedResponse = {
        ...response.data,
        automaticProposalHouses: await Promise.all(
          response.data.automaticProposalHouses.map(async (house, index) => {
            return {
              ...house,
              houseDetails: {
                ...automaticProposalFormatted[index],
                includedServices: await getIncludedServices(
                  house.house_id.toString()
                ),
              },
            };
          })
        ),
      };

      const automaticProposalHousesSorted = new AutomaticProposalSorted(
        formattedResponse
      ).build();

      const automaticProposal = {
        ...formattedResponse,
        automaticProposalHouses: automaticProposalHousesSorted,
      };

      presenter.displayAutomaticProposals(automaticProposal);
    } else {
      presenter.displayAutomaticProposals(undefined);
    }
  }
}
