import { GetAreaEquipmentsRepository } from "./interfaces/GetAreaEquipmentsRepository";
import { GetAreaEquipmentsPresentation } from "./interfaces/GetAreaEquipmentsPresentation";
import { GetHouseAreaEquipmentsResponse } from "../GetHouseDetails/types";

export default class GetAreaEquipmentsUseCase {
  constructor(private repository: GetAreaEquipmentsRepository) {}

  async execute(
    presenter: GetAreaEquipmentsPresentation,
    houseId: string,
    privateToken?: string
  ) {
    try {
      const houseAreaEquipments = await this.repository.getHouseAreaEquipments({
        houseId,
        privateToken,
      });

      let houseArea = undefined;
      if (houseAreaEquipments?.data) {
        houseArea = houseAreaEquipments.data;
      } else if (houseAreaEquipments && !houseAreaEquipments?.errors) {
        houseArea = houseAreaEquipments;
      }

      if (houseArea) {
        presenter.displayHouseAreaEquipments(
          houseArea as Required<GetHouseAreaEquipmentsResponse>["data"]
        );
      }
    } catch (err) {
      console.log("GetAreaEquipmentsUseCase", err);
    }
  }
}
