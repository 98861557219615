import { ModifyAutomaticProposalCommentRepository } from "./interfaces/ModifyAutomaticProposalCommentRepository";
import { ModifyAutomaticProposalCommentPresentation } from "./interfaces/ModifyAutomaticProposalCommentPresentation";
import { AutomaticProposalCommentDomain } from "../../domain/AutomaticProposalCommentDomain";

import type { ModifiyAutomaticProposalCommentInput } from "../../../../types/common/automaticProposal";

export default class ModifyAutomaticProposalCommentUseCase {
  constructor(private repository: ModifyAutomaticProposalCommentRepository) {}

  async execute(
    presenter: ModifyAutomaticProposalCommentPresentation,
    input: ModifiyAutomaticProposalCommentInput
  ) {
    const response = await this.repository.modifyAutomaticProposalComment({
      automaticProposalId: input.automaticProposalId,
      automaticProposalHouseId: input.automaticProposalHouseId,
      automatic_proposal_comment: input.automatic_proposal_comment,
    });

    if (response?.data) {
      const comment = new AutomaticProposalCommentDomain(response.data).build();
      presenter.displayAutomaticProposalCommentUpdated(comment);
    } else {
      presenter.displayAutomaticProposalCommentUpdated(null);
    }
  }
}
