import camelCase from 'lodash.camelcase'

import type {
  BaseFormApiError,
  ErrorsCustom,
  TypeErrorApi,
  ErrorApiScope,
} from '~/components/BaseForm/types'
type Locale = 'fr' | 'en'

type HandleErrorsFunction = (
  keysPayload: string[],
  errorsApi: ErrorApiScope[],
  errorsCustom: ErrorsCustom,
) => BaseFormApiError[]

type ScrollToErrorFunction = (
  refForm: HTMLElement,
  errors: BaseFormApiError[],
) => void

type UseErrors = {
  clearErrorsNotContain: () => void
  errorsNotContainPayload: Ref<string[]>
  handleErrors: HandleErrorsFunction
  scrollToError: ScrollToErrorFunction
}

type State = {
  errorsNotContainPayload: string[]
}

export function useErrors(): UseErrors {
  const { t, locale } = useI18n()

  const state = reactive<State>({
    errorsNotContainPayload: [],
  })

  const handleErrors: HandleErrorsFunction = (
    keysPayload,
    errorsApi,
    errorsCustom,
  ) => {
    const errorsFormatted: BaseFormApiError[] = []
    state.errorsNotContainPayload.splice(
      0,
      state.errorsNotContainPayload.length,
    )

    errorsApi.forEach((error: ErrorApiScope) => {
      for (const scope in error) {
        for (const field in error[scope]) {
          const count = error[scope][field][0]?.count
          const destinations = error[scope][field][0]?.destinations
          const fieldCamelCase = camelCase(field)
          const typeErrorCamelCase = camelCase(
            error[scope][field][0].error,
          ) as unknown as TypeErrorApi

          if (keysPayload.includes(fieldCamelCase)) {
            if (errorsCustom?.[typeErrorCamelCase]) {
              setErrorCustom({
                errorsCustom,
                errorsFormatted,
                fieldCamelCase,
                typeErrorCamelCase,
              })
            } else {
              setError({
                count,
                destinations,
                errorsFormatted,
                fieldCamelCase,
                typeErrorCamelCase,
              })
            }
          } else {
            state.errorsNotContainPayload.push(fieldCamelCase)
          }
        }
      }
    })

    return errorsFormatted
  }

  const scrollToError: ScrollToErrorFunction = (refForm, errors) => {
    // get all offset fields
    const offsetFields = errors
      .map((error: BaseFormApiError) => {
        const elementDOM = refForm?.querySelector(`#${error.fieldName}`)

        return elementDOM?.closest('div')?.getBoundingClientRect().top || 0
      })
      .filter((offset) => offset !== 0)

    const offsetMin = Math.min(...offsetFields)
    const main = document.getElementById('main')

    if (offsetMin && main) {
      const scrollTop = offsetMin + main.scrollTop
      let heightHeader = document?.getElementById('header')?.offsetHeight || 0
      const heightHeaderSticky =
        document?.querySelector<HTMLElement>('.header-sticky')

      if (heightHeaderSticky) {
        heightHeader = heightHeaderSticky.offsetHeight
      }
      main?.scrollTo({ top: scrollTop - heightHeader, behavior: 'smooth' })
    }
  }

  const setError = ({
    count,
    destinations,
    errorsFormatted,
    fieldCamelCase,
    typeErrorCamelCase,
  }: {
    count: number | undefined
    destinations: { fr: string; en: string }[] | undefined
    errorsFormatted: BaseFormApiError[]
    fieldCamelCase: string
    typeErrorCamelCase: TypeErrorApi
  }) => {
    const destinationsName = destinations
      ?.map((desti) => {
        return desti[locale.value as Locale]
      })
      .join(', ')

    errorsFormatted.push({
      fieldName: fieldCamelCase,
      message: t(`global.errors.${typeErrorCamelCase}`, {
        field: t(`global.fields.${fieldCamelCase}`),
        count,
        destinationsName,
      }),
    })
  }

  const setErrorCustom = ({
    errorsCustom,
    errorsFormatted,
    fieldCamelCase,
    typeErrorCamelCase,
  }: {
    errorsCustom: ErrorsCustom
    errorsFormatted: BaseFormApiError[]
    fieldCamelCase: string
    typeErrorCamelCase: TypeErrorApi
  }) => {
    const type = errorsCustom[typeErrorCamelCase]

    if (type?.[fieldCamelCase]) {
      const message = type[fieldCamelCase]

      errorsFormatted.push({
        fieldName: fieldCamelCase,
        message,
      })
    }
  }

  const clearErrorsNotContain = () => {
    state.errorsNotContainPayload.splice(
      0,
      state.errorsNotContainPayload.length,
    )
  }

  return {
    ...toRefs(state),
    clearErrorsNotContain,
    handleErrors,
    scrollToError,
  }
}
