import { DeleteUserWishlistRepository } from "./interfaces/DeleteUserWishlistRepository";
import { DeleteUserWishlistPresentation } from "./interfaces/DeleteUserWishlistPresentation";
import { Wishlists } from "../../domain/Wishlists";

import type { DeleteWishlistInput } from "../../../../types/common/wishlist";

export default class DeleteUserWishlistUseCase {
  constructor(private repository: DeleteUserWishlistRepository) {}

  async execute(
    presenter: DeleteUserWishlistPresentation,
    input: DeleteWishlistInput
  ) {
    const response = await this.repository.deleteWishlist({
      wishlistToken: input.wishlistToken,
    });

    if (response?.data) {
      const wishlists = new Wishlists(response.data).build();
      presenter.displayWishlistDeleted(wishlists[0]);
    }

    if (response?.errors) {
      presenter.displayDeleteWishlistError("wishlist.notFound");
    }
  }
}
