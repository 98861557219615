import { getDestinations } from './api'
import { addPhotosToDestination, defineTree } from './utils'

export default (clientHttp, apiVersion) => ({
  async getAll({ fields, id }) {
    try {
      const res = await getDestinations({
        apiVersion,
        clientHttp,
        fields,
        id,
        params: { page: 'all' },
      })
      const response = res?.data

      return response
    } catch (err) {
      console.error(err)

      throw err
    }
  },
  async getTree({ id }) {
    const includes = ['country', 'cluster']

    try {
      const res = await getDestinations({
        apiVersion,
        clientHttp,
        id,
        includes,
      })
      const response = res?.data
      const destinations = res?.included?.destinations
      // Define tree
      const { cluster, country, desti } = defineTree(response, destinations)

      return [
        {
          name: desti?.name,
          slug: desti?.slug,
          searchUrl: desti?.searchUrl,
        },
        {
          name: cluster?.name,
          slug: cluster?.slug,
          searchUrl: cluster?.searchUrl,
        },
        {
          name: country?.name,
          slug: country?.slug,
          searchUrl: country?.searchUrl,
        },
      ].filter((x) => x.name !== undefined)
    } catch (err) {
      console.error(err)

      throw err
    }
  },
  async getAncestries({ slug }) {
    const includes = ['country', 'cluster']
    const fields = {
      destination: ['cluster', 'country', 'isCluster', 'isCountry', 'name'],
    }

    try {
      const res = await getDestinations({
        apiVersion,
        clientHttp,
        slug,
        includes,
        fields,
      })

      const response = res?.data
      const destinations = Array.isArray(res?.included?.destinations)
        ? res?.included?.destinations
        : [{ ...res?.included?.destinations }]

      const { cluster, country, desti } = defineTree(response, destinations)

      return { cluster, country, desti }
    } catch (err) {
      console.error(err)

      throw err
    }
  },
  /**
   * @return {PromiseLike<{
      cluster: string | null
      country: string
      coverFeaturedPhoto: string
      coverPhotoUrl: string
      clusterizedName: { en: string; fr: string }
      id: string
      isCluster: boolean
      isCountry: boolean
      metaDescription: string
      metaTitle: string
      name: string
      searchTextTitle: string
      seoFeaturedPhoto: string
      seoPhotoUrl: string
      searchUrl: { en: string; fr: string }
      slug: { en: string; fr: string }
      timezone: string
    }[]>}
   */
  async getPhoto({ id, fields = {}, slug = '', params = {} }) {
    try {
      const includes = [
        'cluster.cover_featured_photo',
        'cluster',
        'country.cover_featured_photo',
        'country',
        'cover_featured_photo',
        'seo_featured_photo',
      ]
      const res = await getDestinations({
        apiVersion,
        clientHttp,
        fields,
        id,
        includes,
        params,
        slug,
      })
      const response = res?.data
      const destinations = res?.included?.destinations
      const photos = res?.included?.photos

      if (Array.isArray(response)) {
        return response.map((d) => {
          return addPhotosToDestination(d, photos, destinations)
        })
      } else {
        return addPhotosToDestination(response, photos, destinations)
      }
    } catch (err) {
      console.error(err)

      throw err
    }
  },
  async getPhotoAndAncestries({ id, fields, slug, params }) {
    try {
      const includes = [
        'cluster.cover_featured_photo',
        'cluster',
        'country.cover_featured_photo',
        'country',
        'cover_featured_photo',
        'seo_featured_photo',
      ]
      const res = await getDestinations({
        apiVersion,
        clientHttp,
        fields,
        id,
        includes,
        params,
        slug,
      })
      const response = res?.data
      const photos = res?.included?.photos
      const destinations = Array.isArray(res?.included?.destinations)
        ? res?.included?.destinations
        : [{ ...res?.included?.destinations }]

      const { cluster, country, desti } = defineTree(response, destinations)

      if (Array.isArray(response)) {
        return {
          destinations: [
            ...response.map((d) => {
              return addPhotosToDestination(
                d,
                photos,
                res?.included?.destinations,
              )
            }),
          ],
          cluster,
          country,
          desti,
        }
      } else {
        return {
          ...addPhotosToDestination(
            response,
            photos,
            res?.included?.destinations,
          ),
          cluster,
          country,
          desti,
        }
      }
    } catch (err) {
      console.error(err)

      throw err
    }
  },
  async getOneBySlug({ slug, fields, includes, params }) {
    const res = await getDestinations({
      apiVersion,
      clientHttp,
      fields,
      includes,
      params,
      slug,
    })

    return res
  },
  async getById({ id, fields = {}, includes = [], params = {} }) {
    const res = await getDestinations({
      apiVersion,
      clientHttp,
      fields,
      id,
      includes,
      params,
    })

    return res
  },
})
