import { UserInput } from "lc-repository/dist/repositories/lc/v3/user/information";
import FormError from "./GenericFormError";

export default class UserFormError extends FormError {
  private civility = ["mrs", "mr", "unspecified"];

  constructor(private input: UserInput) {
    super();
  }

  firstnameRequired() {
    if (!this.input.user.first_name) {
      this.errors.push({ fieldName: "first_name", error: "firstname.missing" });
    }

    return this;
  }

  lastnameRequired() {
    if (!this.input.user.last_name) {
      this.errors.push({ fieldName: "last_name", error: "lastname.missing" });
    }

    return this;
  }

  civilityRequired() {
    if (
      this.input.user.civility &&
      !this.civility.includes(this.input.user.civility)
    ) {
      this.errors.push({ fieldName: "civility", error: "civility.missing" });
    }

    return this;
  }

  birthDateWrongFormat(isValidDate: (value: Date) => boolean) {
    if (this.input.user.birthdate && !isValidDate(this.input.user.birthdate)) {
      this.errors.push({
        fieldName: "birthdate",
        error: "birthdate.wrongFormat",
      });
    }

    return this;
  }

  build() {
    return [...this.errors];
  }
}
