/**
 * toCamelCase transforms string snake_case or kebab-case to camelCase
 * @param {String} string
 * @return {string}
 */

const toCamelCase = (string) => {
  return string.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

/**
 * toPascalCase transforms string snake_case or kebab-case to PascalCase
 * @param {string} string
 * @return {string}
 */

const toPascalCase = (string) => {
  const camelCaseString = toCamelCase(string)

  return camelCaseString.charAt(0).toUpperCase() + camelCaseString.slice(1)
}

/**
 * RemoveWhitespacesAndCommas removes white spaces and commas
 * @param {string} string
 * @return {string}
 */

const removeWhitespacesAndCommas = (string) => {
  return string.replace(/[,\s]+/g, '')
}

const slugify = (str, separator = '-') => {
  return str
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036F]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator)
}

export { removeWhitespacesAndCommas, toCamelCase, toPascalCase, slugify }
