<script setup lang="ts">
const route = useRoute()
const { isDesktop } = useBreakpoint()
const { headerMobile } = useHeaderMobile()
const { headerDesktop } = useHeaderDesktop()
const { headerHeight, headerTheme, pageTheme, setTemporaryTheme } =
  useHeaderNavigation()

const colors = {
  gray: {
    100: '#F7F7F7',
    200: '#EAEAEA',
    300: '#CACACA',
    400: '#AAAAAA',
    500: '#757575',
    600: '#4C4C4C',
    700: '#202020',
  },
  primary: {
    100: '#F7F2EA',
    200: '#EBDECA',
    300: '#DECAAA',
    400: '#D6BD95',
    500: '#CCAD7B',
    600: '#C29C61',
    700: '#B78C47',
    800: '#976D29',
    900: '#836433',
  },
  secondary: {
    100: '#F3F6F6',
    200: '#356464',
    300: '#1C5050',
    400: '#033D3D',
    500: '#022B2B',
  },
  error: '#DC6869',
  green: '#008e75',
  orange: '#ee7f53',
  success: '#479B9B',
  transparent: 'transparent',
  white: '#ffffff',
  yellow: '#d4a45f',
}

const props = withDefaults(
  defineProps<{
    disableAffix?: boolean
  }>(),
  {
    disableAffix: false,
  },
)

const affix = ref(false)
const affixAnimate = useState('affixAnimate', () => false)
const lastScrollTop = ref(0)
const scrollTop = ref(0)

const activeDropdown = computed(() => headerDesktop.value.activeDropdown)
const menuIsOpen = computed(() => headerMobile.value.menuIsOpen)
const searchIsOpen = computed(() => headerMobile.value.searchIsOpen)

const cssVar = computed(() => {
  const isHeaderThemeWhite = headerTheme.value === 'white'

  return {
    '--header-text-color': isHeaderThemeWhite ? colors.gray[700] : colors.white,
    '--header-bg-color': isHeaderThemeWhite ? colors.white : colors.transparent,
    '--header-border-color': isHeaderThemeWhite
      ? colors.gray[300]
      : colors.transparent,
    '--header-mobile-shadow': isHeaderThemeWhite
      ? '1px 1px 6px 0px rgba(202, 202, 202, 0.5)'
      : '',
  }
})

const containerClass = computed(() =>
  affix.value || menuOrSearhIsOpen.value ? `h-[${headerHeight.value}px]` : '',
)

const menuOrSearhIsOpen = computed(() => menuIsOpen.value || searchIsOpen.value)

const affixHandle = () => {
  scrollTop.value = window.pageYOffset || document.documentElement.scrollTop

  if (scrollTop.value > lastScrollTop.value || scrollTop.value === 0) {
    // scroll down
    affixAnimate.value = false
  } else {
    // scroll up
    affixAnimate.value = true
  }

  if (scrollTop.value === 0) {
    affix.value = false

    if (
      pageTheme.value !== 'white' &&
      !menuIsOpen.value &&
      !searchIsOpen.value &&
      !activeDropdown.value.name
    ) {
      setTemporaryTheme('')
    }
  } else if (scrollTop.value > headerHeight.value) {
    affix.value = true

    if (pageTheme.value !== 'white') {
      setTemporaryTheme('white')
    }
  }

  // For Mobile or negative scrolling
  lastScrollTop.value = scrollTop.value <= 0 ? 0 : scrollTop.value
}

const setAffix = () => {
  if (!props.disableAffix) {
    document.addEventListener('scroll', affixHandle, { passive: true })
  } else {
    affix.value = false
    document.removeEventListener('scroll', affixHandle)
  }
}

watch(
  () => route.path,
  () => {
    setAffix()
  },
)

onMounted(() => {
  setAffix()
})
</script>

<template>
  <div
    id="the-header"
    :class="['the-header z-modal', containerClass]"
    :style="cssVar"
    data-testid="the-header"
  >
    <div
      :class="[
        {
          'affix fixed w-full': affix && scrollTop > 0,
          'affix-animate': affixAnimate,
        },
      ]"
      data-testid="the-header--affix"
    >
      <the-header-desktop v-if="isDesktop" :affix="affix" />
      <the-header-mobile
        v-else
        :class="[{ 'fixed inset-x-0 top-0': menuOrSearhIsOpen }]"
      />
    </div>
  </div>
</template>

<style>
.the-header .affix {
  top: calc(-1 * var(--header-height) - 3px);
  transition: top 0.5s ease-in-out;
}
.the-header .affix-animate {
  top: 0;
}
</style>
