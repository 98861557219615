import { Wishlist, WishlistFormatted, HouseWishlistType } from "../../../types";

export class Wishlists {
  private newWishlists: WishlistFormatted[] = [];

  constructor(private wishlistRepository: Wishlist | Wishlist[]) {
    const wishlists = Array.isArray(this.wishlistRepository)
      ? this.wishlistRepository
      : [this.wishlistRepository];

    this.newWishlists = wishlists.map((wishlist) => ({
      houseCount: wishlist.multiInquiryHouses.housesCount,
      houseIds: this.getHouseIds(wishlist.multiInquiryHouses.houses),
      id: wishlist.id,
      images: this.imagesFormatted(wishlist.multiInquiryHouses.houses),
      legacy: wishlist.legacy,
      privateToken: wishlist.privateToken,
      title: wishlist.title,
    }));
  }

  private imagesFormatted(houses: HouseWishlistType[]) {
    return houses.map((house) => house.firstPhotoUrl).splice(0, 4);
  }

  private getHouseIds(houses: HouseWishlistType[]) {
    return houses.map((house) => house.id);
  }

  build() {
    return this.newWishlists;
  }
}
