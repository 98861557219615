import { getDestinationSlug } from '~/helpers/getDestinationSlug'
import type { Locale } from 'lc-services/types'
import type { DestinationGroup } from '~/types/header/megaMenuDestinations'

export const useMegaMenuDestinations = () => {
  const { t, locale } = useI18n()
  const { trackEvent } = useTracking()
  const localePath = useLocalePath()
  const route = useRoute()

  const destinationSlugs = {
    fr: 'location-villas-luxe',
    en: 'luxury-villas-rentals',
  }

  const destinationGroups = computed(() => [
    {
      key: 'france' as const,
      name: t('static.luxuryDestinations.france'),
      link: localePath({
        name: 'destination-name',
        params: {
          destination: destinationSlugs[locale.value as Locale],
          name: 'france',
        },
      }),
    },
    {
      key: 'europe' as const,
      name: t('static.luxuryDestinations.europe'),
      link: localePath({
        name: 'destination-name',
        params: {
          destination: destinationSlugs[locale.value as Locale],
          name: 'europe',
        },
      }),
    },
    {
      key: 'restOfTheWorld' as const,
      name: t('static.luxuryDestinations.restOfTheWorld'),
    },
  ])

  const linkGroups = computed(() =>
    destinationGroups.value.filter((d) => d.key !== 'restOfTheWorld'),
  )

  const getHref = (slug: string) =>
    slug
      ? getDestinationSlug({ fr: slug, en: slug })[locale.value as Locale].path
      : ''

  const sendTracking = (group: DestinationGroup) => {
    trackEvent({
      event: 'click_in_destination_menu',
      source: route.name?.toString().slice(0, -5),
      destination_area: group,
    })
  }

  return {
    destinationGroups,
    getHref,
    linkGroups,
    sendTracking,
  }
}
