import { UserType } from "../../../types";

type GenericNav<T extends string | null = null> = T;
export type NavType =
  | "App"
  | "SignUp"
  | "Token"
  | "EmailAuth"
  | "UserPhoneUpdate"
  | "EnableNotifications"
  | null;

export class AuthVM {
  errors: { fieldName: string; error: string }[] = [];
  loading = false;
  msg = "";
  navigation: GenericNav<NavType> = null;
  sendTokenByEmailResponse: boolean | null = null;
  verifyTokenResponse: UserType | null = null;
  signUpResponse: UserType | null = null;
  toast: { type: "success" | "error"; msg: string } | null = null;
  userCreated: UserType | null = null;
}
