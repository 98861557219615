import { InputPassword } from '~/components/BaseForm/core/primitives'

const Password: typeof InputPassword = {
  ...InputPassword,
  attr: {
    ...InputPassword.attr,
    labelTranslateKey: 'form.password',
    name: 'password',
    rules: 'required',
  },
}

export default Password
