import { InputPhone } from '~/components/BaseForm/core/primitives'

const Phone: typeof InputPhone = {
  ...InputPhone,
  attr: {
    ...InputPhone.attr,
    labelTranslateKey: 'form.phone',
    name: 'phone',
    rules: 'required',
  },
}

export default Phone
