import { FacetFiltersValues, Locale, Mode } from "../../../../types";

export const generateDefaultFacets = (locale: Locale, mode: Mode) => {
  const facetsIndexClient = [1, 2, 3, 4, 5, 7, 10];
  const facetsIndexAdminAndB2b2c = [1, 2, 3, 4, 5, 7, 8, 9, 11];
  const facetIndexes =
    mode === "client" ? facetsIndexClient : facetsIndexAdminAndB2b2c;
  const defaultFacets: string[] = [];

  for (const facetIndex of facetIndexes) {
    defaultFacets.push(`searchable_tags.${facetIndex}.${locale}`);
  }

  if (mode === "admin" || mode === "b2b2c")
    return [...defaultFacets, `other_tags.${locale}`];

  return defaultFacets;
};

export const formatFacetFilters = (facetFilters: FacetFiltersValues) => {
  return Array.from(
    facetFilters,
    ([value, category]) => `${category}:${value}`
  );
};
