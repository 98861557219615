<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'

import type { RadioOption } from '~/components/BaseForm/types'

const props = withDefaults(
  defineProps<{
    label?: string
    labelHtml?: string
    modelValue: string | number
    name: string
    options: RadioOption[]
    rules?: string
    vertical?: boolean
    wrapperClass?: string
  }>(),
  {
    label: '',
    labelHtml: '',
    rules: '',
    vertical: false,
    wrapperClass: 'w-full flex flex-col mb-4',
  },
)
const emits = defineEmits<{
  'update:model-value': [string]
}>()
const rules = toRef(props, 'rules')

const { value: inputValue, handleChange } = useField<number | string>(
  props.name,
  rules,
  {
    initialValue: props.modelValue,
  },
)

const onChange = (payload: string): void => {
  handleChange(payload)
  emits('update:model-value', payload)
}
const cleanOption = (option: RadioOption) => {
  return {
    ...option,
    value: option.value.toString(),
    label: option.label?.toString(),
  }
}
</script>

<template>
  <div :class="wrapperClass">
    <label
      v-if="label"
      class="base-form-label mb-2"
      data-testid="base-radiogroup-label"
    >
      <BaseFormComponentsBaseFormLabel :label="label" :label-html="labelHtml" />
      <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
    </label>

    <div
      :class="[
        'base-radiogroup-layout',
        { 'base-radiogroup-layout--vertical': vertical },
      ]"
    >
      <BaseFormComponentsBaseRadio
        v-for="option in options"
        :key="option.value"
        :model-value="inputValue"
        :name="name"
        :vertical="vertical"
        v-bind="{ ...cleanOption(option) }"
        @update:model-value="onChange"
      />
    </div>

    <error-message :name="name" as="span" class="base-form--error" />
  </div>
</template>

<style>
.base-radiogroup-layout {
  @apply flex;
}

.base-radiogroup-layout--vertical {
  @apply flex-col;
}
</style>
