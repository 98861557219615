import { InputSelect } from '~/components/BaseForm/core/primitives'
import { timezone } from '~/helpers/BaseForm/timezone'

const options = timezone.map((x: string) => ({ label: x, value: x }))

const Timezone: typeof InputSelect = {
  ...InputSelect,
  attr: {
    ...InputSelect.attr,
    canClear: true,
    labelTextTranslateKey: 'form.timezone',
    name: 'timezone',
    searchable: true,
  },
  options,
}

export default Timezone
