<script setup lang="ts">
import TheHeaderSearchInput from './TheHeaderSearchInput.vue'
import { getDestinationSlug } from '~/helpers/getDestinationSlug'
import type { RouteLocation } from 'vue-router'
import type { HouseSlug, Locale } from 'lc-services/types'

const emits = defineEmits<{
  'link-clicked': [RouteLocation]
}>()
const { t, locale } = useI18n()
const localePath = useLocalePath()
const { headerMobile } = useHeaderMobile()
const { headerDesktop } = useHeaderDesktop()

const destinationsResults = ref([])
const destinationsSuggestions = ref([
  {
    name: 'Courchevel',
    image:
      'https://cdn.lecollectionist.com/lc/production/uploads/photos/destination-117/2019-11-12-71176b7fb30ffd9f48e29fddceb57274.jpg',
    cluster: {
      en: 'French alps',
      fr: 'Alpes françaises',
    },
    href: 'destinationId=117',
    search: {
      name: {
        en: 'Courchevel, French Alps',
        fr: 'Courchevel, Alpes Françaises',
      },
      destinationId: 117,
    },
  },
  {
    name: 'Méribel',
    image:
      'https://cdn.lecollectionist.com/__lecollectionist__/production/lp-destinations/34/ViQJsKZKReCzjUYUgtub_7fa6890a-d12b-4ef0-d16d-1858a8a9dbda.jpg',
    cluster: {
      en: 'French alps',
      fr: 'Alpes françaises',
    },
    href: 'destinationId=122',
    search: {
      name: { en: 'Méribel, French Alps', fr: 'Méribel, Alpes Françaises' },
      destinationId: 122,
    },
  },
  {
    name: 'Cap Ferret',
    image:
      'https://cdn.lecollectionist.com/lc/production/uploads/photos/destination-60/2019-12-04-09164db5411bb02d7cd6c8ad52cb811f.jpg',
    cluster: {
      en: 'Southwest, France',
      fr: 'Sud-Ouest, France',
    },
    href: 'destinationId=59',
    search: {
      name: { en: 'Cap Ferret, Southwest', fr: 'Cap Ferret, Sud-Ouest' },
      destinationId: 59,
    },
  },
])
const hasQuery = ref(false)
const searchQuery = ref('')
const housesResults = ref([])
const housesSuggestions = ref([
  {
    id: 6758,
    name: 'Casa Calella',
    image:
      'https://cdn.lecollectionist.com/__lecollectionist__/production/houses/6758/photos/MZFToi2YT9aPUhWj0kob_5a498098-380f-4558-d34c-7ea3e3adfdf9.jpg',
    cluster: {
      en: 'Region of Porto-Vecchio',
      fr: 'Région de Porto-Vecchio',
    },
    href: {
      en: 'luxury-rental/casa-calella-region-of-porto-vecchio',
      fr: 'location-luxe/casa-calella-region-de-porto-vecchio',
    },
  },
  {
    id: 4234,
    name: 'Chalet Renaissance',
    image:
      'https://cdn.lecollectionist.com/__collectionist__/production/uploads/photos/house-4234/2021-03-29-d28afe1c191764f22cc208d64e46d8a8.jpg',
    cluster: {
      en: 'Megève, French Alps',
      fr: 'Megève, Alpes Françaises',
    },
    href: {
      en: 'luxury-rental/chalet-renaissance-megeve',
      fr: 'location-luxe/chalet-renaissance-megeve',
    },
  },
  {
    id: 3708,
    name: 'Villa Basquiat',
    image:
      'https://cdn.lecollectionist.com/__lecollectionist__/production/houses/8459/photos/nyXVgFqYTeOz49zRckB4_31c7b15d-ced4-4766-ddb0-74fd5de98dd8.jpg',
    cluster: {
      en: 'Ibiza',
      fr: 'Ibiza',
    },
    href: {
      en: 'luxury-rental/villa-basquiat-ibiza',
      fr: 'location-luxe/villa-basquiat-ibiza',
    },
  },
])
const showInputSearch = ref(false)
const activeDropdown = computed(() => headerDesktop.value.activeDropdown)
const searchIsOpen = computed(() => headerMobile.value.searchIsOpen)
const hasDestinations = computed(() => destinations.value.length > 0)
const hasHouses = computed(() => houses.value.length > 0)
const destinations = computed(() =>
  hasQuery.value
    ? destinationsResults.value
    : destinationsSuggestionsFormated.value,
)
const destinationsSuggestionsFormated = computed(() => {
  const d: typeof housesSuggestions extends Ref<infer T> ? T : never =
    JSON.parse(JSON.stringify(destinationsSuggestions.value))

  return d.map((desti) => ({
    ...desti,
    href: `${localePath({ name: 'search' })}?${desti.href}`,
    subName: desti.cluster[locale.value as Locale],
  }))
})
const destinationsTitle = computed(() =>
  hasQuery.value
    ? t('headerSearch.destinations')
    : t('headerSearch.topDestinations'),
)
const houses = computed(() =>
  hasQuery.value ? housesResults.value : housesSuggestionsFormated.value,
)
const housesSuggestionsFormated = computed(() => {
  const houses: typeof housesSuggestions extends Ref<infer T> ? T : never =
    JSON.parse(JSON.stringify(housesSuggestions.value))

  return houses.map((house) => ({
    ...house,
    href: getPathDestination(house.href),
    subName: house.cluster[locale.value as Locale],
  }))
})
const housesTitle = computed(() =>
  hasQuery.value
    ? t('headerSearch.houses')
    : t('headerSearch.housesOurSelection'),
)
const searchOpened = computed(
  () => activeDropdown.value?.name === 'search' || searchIsOpen.value,
)
const title = computed(() =>
  hasQuery.value ? t('headerSearch.results') : t('headerSearch.suggestions'),
)

watch(searchOpened, (val) => {
  if (val) {
    showInputSearch.value = true
  } else {
    showInputSearch.value = false

    // wait until dropdown closed for reset values
    setTimeout(() => {
      setHasQuery(false)
      setDestinations([])
      setHouses([])
    }, 300)
  }
})

const getPathDestination = (destinationNames: HouseSlug) =>
  getDestinationSlug(destinationNames)[locale.value as Locale].path
const linkClicked = (route: RouteLocation) => {
  emits('link-clicked', route)
}
const setDestinations = (
  result: typeof destinationsResults extends Ref<infer T> ? T : never,
) => {
  destinationsResults.value = result
}
const setHasQuery = (result: boolean, query = '') => {
  searchQuery.value = query
  hasQuery.value = result
}
const setHouses = (
  result: typeof housesResults extends Ref<infer T> ? T : never,
) => {
  housesResults.value = result
}
</script>

<template>
  <div class="laptop:grid laptop:grid-cols-12">
    <div class="laptop:col-span-8 laptop:col-start-3">
      <TheHeaderSearchInput
        v-if="showInputSearch"
        @has-query="setHasQuery"
        @houses="setHouses"
        @destinations="setDestinations"
      />
      <!-- Placeholder -->
      <div
        v-else
        class="relative mb-6 h-[58px] rounded-full bg-gray-100 laptop:mb-14"
      />

      <p
        class="font-monospace mb-6 text-4xl font-bold leading-none text-gray-700 laptop:text-6xl"
      >
        {{ title }}
      </p>

      <div class="flex flex-col laptop:flex-row">
        <TheHeaderSearchGroupLinks
          :class="[
            'destinations mb-6 laptop:mb-0 laptop:w-1/2',
            {
              'order-1 md:order-none': hasDestinations,
              'order-2 md:order-none': !hasDestinations && hasHouses,
            },
          ]"
          :links="destinations"
          :search-query="searchQuery"
          :title="destinationsTitle"
          @link-clicked="linkClicked"
        />

        <div
          class="search-group-links-sep relative hidden w-32 shrink laptop:block"
        />

        <TheHeaderSearchGroupLinks
          :class="[
            'houses laptop:w-1/2',
            {
              'order-1 mb-6 md:order-none': !hasDestinations && hasHouses,
              'order-2 md:order-none': hasDestinations,
            },
          ]"
          :links="houses"
          :search-query="searchQuery"
          :title="housesTitle"
          @link-clicked="linkClicked"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.search-group-links-sep:before {
  content: '';
  transform: rotate(15deg);
  @apply border-r border-gray-200 h-full w-px top-0 absolute origin-bottom;
}
</style>
