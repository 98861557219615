import { MakeRequestWishlistRepository } from "./interfaces/MakeRequestWishlistRepository";
import { MakeRequestWishlistPresentation } from "./interfaces/MakeRequestWishlistPresentation";

import type { MakeRequestWishlistInput } from "../../../../types/common/wishlist";

export default class MakeRequestWishlistUseCase {
  constructor(private repository: MakeRequestWishlistRepository) {}

  async execute(
    presenter: MakeRequestWishlistPresentation,
    input: MakeRequestWishlistInput
  ) {
    const response = await this.repository.makeRequestWishlist({
      currency: input.currency,
      end_date: input.end_date,
      private_token: input.private_token,
      start_date: input.start_date,
    });

    if (response?.errors) {
      presenter.displayMakeRequestWishlistSucceeded(false);
    } else {
      presenter.displayMakeRequestWishlistSucceeded(true);
    }
  }
}
