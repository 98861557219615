<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    fluid?: boolean
  }>(),
  {
    fluid: false,
  },
)
defineSlots<{ default(): any }>()

const containerClass = computed(() => {
  const baseClass = 'w-full max-w-full mx-auto px-4 sm:px-6'

  if (!props.fluid) {
    return `${baseClass} lg:max-w-container-xl`
  }

  return `${baseClass}`
})
</script>

<template>
  <div :class="containerClass">
    <slot />
  </div>
</template>
