import {
  WishlistFormatted,
  WishlistDetailsFormatted,
  WishlistDetailsSharedFormatted,
  HouseAvailable,
} from "../../../types";

export class WishlistVM {
  houseAvailables: HouseAvailable[] = [];
  makeRequestWishlistSucceeded: boolean | null = null;
  modifyRequestWishlistSucceeded: boolean | null = null;
  wishlist: WishlistDetailsFormatted | null = null;
  wishlistDeleted: WishlistFormatted | null = null;
  wishlistError = "";
  wishlistHouseDeleted: number | null = null;
  wishlists: WishlistFormatted[] = [];
  wishlistShared: WishlistDetailsSharedFormatted | null = null;
  wishlistUpdated: WishlistFormatted | null = null;
}
