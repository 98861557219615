import { defineHeader } from '~/helpers/defineHeader'
import { transparentPagesNames } from '~/utils/headerTheme'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { setCustomPageTheme, setTemporaryTheme, setRoute, setUrlFrom } =
    useHeaderNavigation()

  if (from?.fullPath) setUrlFrom(from.fullPath)
  if (to.name) {
    const color = transparentPagesNames.includes(to.name.slice(0, -5))
      ? 'transparent'
      : 'white'

    setRoute(to.name)
    setTemporaryTheme(color)
    setCustomPageTheme(color)
  }

  defineHeader({ routeName: to.name })
})
