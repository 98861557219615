/*  set of regex for password validation  */

const min8Characters = /.{8}/
const lowercase = /[a-z]/
const uppercase = /[A-Z]/
const specialCharacter = /\W/
const number = /[0-9]/
const specialCharacterOrNumber = /[^a-zA-Z_]/

const gdprRulesDefault = [
  { state: null, name: 'min8', regex: min8Characters },
  { state: null, name: 'lowercase', regex: lowercase },
  { state: null, name: 'uppercase', regex: uppercase },
  {
    state: null,
    name: 'specialCharacterOrNumber',
    regex: specialCharacterOrNumber,
  },
] as {
  state: 'valid' | 'invalid' | 'failed' | null
  name: string
  regex: RegExp
}[]

export {
  lowercase,
  min8Characters,
  number,
  specialCharacter,
  specialCharacterOrNumber,
  uppercase,
  gdprRulesDefault,
}
