<script setup lang="ts">
import type { ModalTypeSummary } from '~/types/wishlist'

const props = defineProps<{
  step: ModalTypeSummary
}>()

const emits = defineEmits<{
  'change-step': [step: ModalTypeSummary] | []
}>()

const { wishlistActive, setWishlistActive } = useMultipleWishlistSummary()

const open = computed(() => !!props.step)

const wishlistDeleted = () => {
  setWishlistActive(null)
  closeModal()
}

const changeStep = (
  step: Extract<ModalTypeSummary, 'delete' | 'edit' | 'share'>,
) => {
  emits('change-step', step)
}

const closeModal = () => {
  emits('change-step')
}
</script>

<template>
  <LazyBaseModalV2 v-model="open" active-esc-mode size="s" @close="closeModal">
    <template #content>
      <WishlistMultipleModalWrapperParameters
        v-if="step === 'parameters'"
        @step="changeStep"
      />
      <WishlistMultipleModalWrapperShare
        v-else-if="step === 'share' && wishlistActive"
        :wishlist-token="wishlistActive.privateToken"
      />
      <WishlistMultipleModalWrapperConfirmDelete
        v-else-if="step === 'delete' && wishlistActive"
        :wishlist-title="wishlistActive.title"
        :wishlist-token="wishlistActive.privateToken"
        @wishlist-deleted="wishlistDeleted"
      />
      <WishlistMultipleModalWrapperForm
        v-else-if="step === 'edit' && wishlistActive"
        edition
        :edtion-token="wishlistActive.privateToken"
        :title="wishlistActive.title"
        @wishlist-updated="closeModal"
      />
      <WishlistMultipleModalWrapperForm
        v-else-if="step === 'add'"
        :title="$t('wishlistMultiple.createNewList')"
        @wishlist-created="closeModal"
      />
    </template>
  </LazyBaseModalV2>
</template>
