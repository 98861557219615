import {
  MultiInquiryWishlistsRepositoryType,
  RequestWishlistsRepositoryType,
  UserRepositoryType,
} from "../../types";
import Controller from "./adapters/Controller";
import Presenter from "./adapters/Presenter";

import {
  CreateUserWishlistUseCase,
  DeleteUserHouseWishlistUseCase,
  DeleteUserWishlistUseCase,
  GetUserWishlistHouseAvailablesUseCase,
  GetUserWishlistsUseCase,
  GetUserWishlistUseCase,
  GetWishlistSharedUseCase,
  MakeRequestWishlistUseCase,
  ModifyRequestWishlistUseCase,
  ModifyUserWishlistUseCase,
} from "./usecases";

export const wishlistBuild = (
  userRepository: UserRepositoryType,
  multiInquiryWishlistsRepository: MultiInquiryWishlistsRepositoryType,
  requestWishlistsRepository: RequestWishlistsRepositoryType
) => {
  const presenter = new Presenter();
  const controller = new Controller(
    {
      createUserWishlist: new CreateUserWishlistUseCase({
        createWishlist: userRepository.multiInquiryWishlists.createWishlist,
      }),
      deleteUserWishlist: new DeleteUserWishlistUseCase({
        deleteWishlist: userRepository.multiInquiryWishlists.deleteWishlist,
      }),
      getUserWishlists: new GetUserWishlistsUseCase({
        getWishlists: userRepository.multiInquiryWishlists.getWishlists,
      }),
      getUserWishlist: new GetUserWishlistUseCase({
        getWishlist: userRepository.multiInquiryWishlists.getWishlist,
      }),
      getUserWishlistHouseAvailables: new GetUserWishlistHouseAvailablesUseCase(
        {
          getWishlistAvailabilities:
            userRepository.multiInquiryWishlistAvailabilities
              .getWishlistAvailability,
        }
      ),
      getWishlistShared: new GetWishlistSharedUseCase({
        getWishlistShared: multiInquiryWishlistsRepository.getWishlistShared,
      }),
      modifyUserWishlist: new ModifyUserWishlistUseCase({
        modifyWishlist: userRepository.multiInquiryWishlists.modifyWishlist,
      }),
      deleteUserHouseWishlist: new DeleteUserHouseWishlistUseCase({
        deleteHouseWishlist:
          userRepository.houseMultiInquiryWishlists.deleteHouseWishlist,
      }),
      makeRequestWishlist: new MakeRequestWishlistUseCase({
        makeRequestWishlist: requestWishlistsRepository.makeRequestWishlist,
      }),
      modifyRequestWishlist: new ModifyRequestWishlistUseCase({
        modifyRequestWishlist: requestWishlistsRepository.modifyRequestWishlist,
      }),
    },
    presenter
  );

  return { controller, presenter };
};
