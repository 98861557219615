import { AuthController, AuthPresenter } from "./adapters";
import {
  SignInRepositoryType,
  AuthRepositoryType,
  UserRepositoryType,
  PhoneNumberValidator,
} from "../../types";
import { isValidPhoneNumber } from "libphonenumber-js";

import {
  RemoveUserSessionUseCase,
  SignInWithEmailUseCase,
  TokenAuthUseCase,
  SignUpUseCase,
  LoginWithProvidersUseCase,
} from "./usecases";

export const authBuild = (
  authRepository: AuthRepositoryType,
  signInRepository: SignInRepositoryType,
  userRepository: UserRepositoryType
) => {
  const usecases = {
    loginWithProviders: new LoginWithProvidersUseCase({
      signIn: signInRepository.phoneSignIn,
    }),
    removeUserSession: new RemoveUserSessionUseCase(authRepository),
    signInWithEmail: new SignInWithEmailUseCase({
      emailSignUp: signInRepository.emailSignUp,
      signIn: signInRepository.phoneSignIn,
    }),
    signUp: new SignUpUseCase(
      {
        finishSignUp: userRepository.auth.finishSignUp,
        modifyUserInfo: userRepository.information.oldModifyUserInfo,
      },
      isValidPhoneNumber as PhoneNumberValidator
    ),
    tokenAuth: new TokenAuthUseCase(signInRepository),
  };
  const presenter = new AuthPresenter();
  const controller = new AuthController(usecases, presenter);

  return {
    controller,
    presenter,
  };
};
