import {
  InputSelect,
  InputTextarea,
} from '~/components/BaseForm/core/primitives'
import {
  Firstname,
  Lastname,
  Email,
  Phone,
} from '~/components/BaseForm/core/fields'
import type { CoreGroupFields } from '~/components/BaseForm/types'

export default (): CoreGroupFields => {
  const firstname: typeof Firstname = {
    ...Firstname,
    attr: {
      ...Firstname.attr,
      name: 'firstname',
      labelTranslateKey: 'field.firstName',
    },
    containerInputClass: 'w-1/2 px-2',
  }

  const lastname: typeof Lastname = {
    ...Lastname,
    attr: {
      ...Lastname.attr,
      name: 'lastname',
      labelTranslateKey: 'field.lastName',
    },
    containerInputClass: 'w-1/2 px-2',
  }

  const email: typeof Email = {
    ...Email,
    attr: {
      ...Email.attr,
      labelTranslateKey: 'field.email',
    },
    containerInputClass: 'w-full md:w-1/2 px-2',
  }

  const phone: typeof Phone = {
    ...Phone,
    attr: {
      ...Phone.attr,
      labelTranslateKey: 'field.phone',
    },
    containerInputClass: 'w-full md:w-1/2 px-2',
  }

  const kind: typeof InputSelect = {
    ...InputSelect,
    attr: {
      ...InputSelect.attr,
      labelTextTranslateKey: 'field.kind',
      name: 'kind',
      placeholderTranslateKey: 'field.placeholder.select',
      rules: 'required',
    },
    options: [
      {
        value: 'inquiry',
        labelTranslateKey: 'contact.reasons.inquiry',
      },
      {
        value: 'press',
        labelTranslateKey: 'contact.reasons.press',
      },
      { value: 'support', labelTranslateKey: 'contact.reasons.support' },
    ],
    containerInputClass: 'w-full px-2',
  }

  const message: typeof InputTextarea = {
    ...InputTextarea,
    attr: {
      ...InputTextarea.attr,
      labelTranslateKey: 'field.message',
      rules: 'required',
      placeholderTranslateKey: 'field.partnersmessage',
      rows: 4,
      name: 'message',
    },
    containerInputClass: 'w-full px-2',
  }

  return {
    containerGroupFieldsClass: 'flex flex-wrap -mx-2',
    fields: [firstname, lastname, email, phone, kind, message],
  }
}
