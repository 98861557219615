import { GetClientContractRepository } from "./interfaces/GetClientContractRepository";
import { GetClientContractPresentation } from "./interfaces/GetClientContractPresentation";
import ClientContractBuild from "../../domain/ClientContract";

export default class GetClientContractUseCase {
  constructor(private repository: GetClientContractRepository) {}

  async execute(
    presenter: GetClientContractPresentation,
    input: { clientContractId: string }
  ) {
    const {
      data: clientContractData,
      included: clientContractIncluded,
      errors,
    } = await this.repository.getClientContract({
      ...input,
      includes: {
        include: [
          "house",
          "house.destination",
          "reviewer",
          "contract_payments",
          "payment_transactions",
        ],
      },
    });

    if (errors || !clientContractData || !clientContractIncluded) {
      presenter.displayError(errors);
      return;
    }

    const clientContractFormatted = new ClientContractBuild({
      clientContractData,
      clientContractIncluded,
    }).build();

    presenter.displayContractClient(clientContractFormatted);
  }
}
