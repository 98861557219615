import { InputText } from '~/components/BaseForm/core/primitives'

const Bic: typeof InputText = {
  ...InputText,
  attr: {
    ...InputText.attr,
    rules: 'required|min:8|max:11',
    labelTranslateKey: 'form.bic',
    name: 'bic',
  },
  containerInputClass: 'w-full md:w-1/2',
}

export default Bic
