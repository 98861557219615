import {
  GetAutomaticProposalIncluded,
  GetAutomaticProposalsData,
  HouseDetails,
  HouseIncludedService,
  NearbyAttributes,
  SurroundingInformationAttributes,
} from "../../../types";
import { ApiError, Response } from "lc-repository/dist/repositories/types";
import {
  AutomaticProposalHouse,
  LocalizedString,
} from "lc-repository/dist/entities";

export class AutomaticProposals {
  private automaticProposalsFormatted: HouseDetails[] = [];

  constructor(
    protected proposalData: GetAutomaticProposalsData,
    protected proposalIncluded: GetAutomaticProposalIncluded,
    protected getIncludedServices: (input: {
      houseId: string | number;
    }) => Promise<Response<HouseIncludedService[], undefined, ApiError>>
  ) {
    this.proposalData.automaticProposalHouses.forEach((house) => {
      const houseIncludeFound = this.getHouseIncluded(
        this.proposalIncluded,
        house
      );
      if (houseIncludeFound) {
        const surroundingFound = this.getSurroundingInformation(
          this.proposalIncluded,
          house
        );
        const stayInfoFound = this.getStayInformation(
          this.proposalIncluded,
          house
        );

        this.automaticProposalsFormatted.push({
          bathrooms: houseIncludeFound.bathrooms,
          bedrooms: houseIncludeFound.bedrooms,
          capacity: houseIncludeFound.capacity,
          coordinates: {
            latitude: houseIncludeFound.gpslatitude,
            longitude: houseIncludeFound.gpslongitude,
          },
          conciergerie: houseIncludeFound.conciergeServiceOffer,
          destination: this.getDestination(
            this.proposalIncluded?.destinations,
            houseIncludeFound.destinationId
          ),
          surface: houseIncludeFound.surface,
          firstPhotoUrl: houseIncludeFound.firstPhotoUrl,
          householdFrequency: stayInfoFound?.householdFrequency || [],
          housekeepingHours: stayInfoFound?.housekeepingHours || 0,
          id: houseIncludeFound.id,
          includedServices: [],
          name: houseIncludeFound.name,
          privateToken: houseIncludeFound?.privateToken,
          slug: houseIncludeFound.slug,
          surrounding: this.getNearbyInfos(surroundingFound),
          petFriendly:
            this.proposalData.petFriendly && stayInfoFound?.petFriendly
              ? true
              : false,
          suitableForChildren:
            this.proposalData.suitableForChildren &&
            stayInfoFound?.suitableForChildren
              ? true
              : false,
          suitableForPeopleWithReducedMobility:
            this.proposalData.suitableForPeopleWithReducedMobility &&
            stayInfoFound?.suitableForPeopleWithReducedMobility
              ? true
              : false,
          timezone: this.getTimezone(
            this.proposalIncluded?.destinations,
            houseIncludeFound.destinationId
          ),
        });
      }
    });
  }

  private getHouseIncluded(
    included: GetAutomaticProposalIncluded,
    house: AutomaticProposalHouse
  ) {
    if (Array.isArray(included.houses)) {
      return this.proposalIncluded.houses.find(
        (houseInclude) => house.house_id.toString() === houseInclude.id
      );
    }
    return included.houses;
  }

  private getSurroundingInformation(
    included: GetAutomaticProposalIncluded,
    house: AutomaticProposalHouse
  ) {
    if (Array.isArray(included.surroundingInformations)) {
      return this.proposalIncluded.surroundingInformations.find(
        (surrounding) => house.house_id.toString() === surrounding.houseId
      );
    }
    return included.surroundingInformations;
  }

  private getStayInformation(
    included: GetAutomaticProposalIncluded,
    house: AutomaticProposalHouse
  ) {
    if (Array.isArray(included.stayInformations)) {
      return this.proposalIncluded.stayInformations.find(
        (stay) => house.house_id.toString() === stay.houseId
      );
    }
    return included.stayInformations;
  }

  private getDestination(
    destination:
      | {
          id: string;
          clusterizedName: LocalizedString;
        }[]
      | { id: string; clusterizedName: LocalizedString },
    destinationId: string
  ) {
    if (Array.isArray(destination)) {
      return destination?.find((dest) => dest.id === destinationId)
        ?.clusterizedName;
    }
    return destination?.clusterizedName;
  }

  private getTimezone(
    destination:
      | {
          id: string;
          timezone: string;
        }[]
      | { id: string; timezone: string },
    destinationId: string
  ) {
    if (Array.isArray(destination)) {
      return destination?.find((dest) => dest.id === destinationId)?.timezone;
    }
    return destination?.timezone;
  }

  private getNearbyInfos(
    surroundingInformations?: SurroundingInformationAttributes
  ) {
    const surroundingInformationsformatted: string[] = [];
    if (surroundingInformations) {
      const surroundingInformationsformatter: { [key: string]: string } = {
        beach: "beachAccessibility",
        downtown: "downtownAccessibility",
        golf: "golfAccessibility",
        restaurantsAndBars: "restaurantsAndBarsAccessibility",
        shops: "shopsAccessibility",
        skiTrail: "skiTrailAccessibility",
      };

      Object.keys(surroundingInformationsformatter).forEach(
        (surroundingInformationKey: string) => {
          const shouldCheck =
            surroundingInformations[
              surroundingInformationKey as keyof NearbyAttributes
            ];
          if (!shouldCheck) {
            return;
          }
          surroundingInformationsformatted.push(surroundingInformationKey);
        }
      );
    }
    return surroundingInformationsformatted;
  }

  build() {
    return this.automaticProposalsFormatted;
  }
}
