export default (clientHttp, apiVersion) => ({
  read(contractId, query) {
    return clientHttp.get(
      `${apiVersion}/client_contracts/${contractId}/review${query}`,
    )
  },
  create(contractId, params) {
    return clientHttp.post(
      `${apiVersion}/client_contracts/${contractId}/review`,
      params,
    )
  },
  update(contractId, params) {
    return clientHttp.put(
      `${apiVersion}/client_contracts/${contractId}/review`,
      params,
    )
  },
})
