import { strapiApi } from '~/repository/strapi/index'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { $i18n, $httpCookies } = nuxtApp

  return {
    provide: {
      strapiApi: strapiApi({
        baseURL: new URL(config.public.strapiApi).origin,
        $cookies: $httpCookies,
        config,
        locale: $i18n.locale.value,
        fetcher: useCustomFetch,
      }),
    },
  }
})
