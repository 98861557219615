import { useURL } from '~/composables/useURL'

export const getApiURL = ({
  config,
  locale,
}: {
  config: ReturnType<typeof useRuntimeConfig>
  locale: string
}) => {
  const { getApiURL } = useURL()

  return getApiURL({ locale, path: config.public.apiPrefix })
}
