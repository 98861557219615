import type { UserVariant } from '~/types/amplitude/types'

export const useExperimentCondition = (flagKey: string) => {
  const userVariant = useState<UserVariant>('user-variant')

  return computed(() => {
    return {
      isControl: userVariant.value[flagKey] === 'control',
      isTreatment: userVariant.value[flagKey] === 'treatment',
    }
  })
}
