<script setup lang="ts">
import {
  getPriceWithCurrency,
  weeklyPriceRangeText,
} from '~/helpers/priceHelper'

import type BaseCalendar from '~/components/BaseForm/components/BaseCalendar.vue'
import type { Locale } from 'lc-services/types'
import type { PriceByCurrency } from '~/types/types'

type CookieUserWishlistDates = { start: string; end: string }

const emits = defineEmits<{
  'inquiry-sended': []
  'open-modal': [step: 'parameters' | 'add']
}>()

const { $httpCookies, $lcServicesWishlist } = useNuxtApp()
const { wishlistController, wishlistPresenter } = $lcServicesWishlist
const { locale, t } = useI18n<unknown, Locale>()
const { isMobile } = useBreakpoint()
const { currency } = useUser()
const { trackEvent } = useTracking()
const {
  addFavorite,
  lastWishlistTokenActive,
  removeFavorite,
  setLastWishlistTokenActive,
  wishlists,
} = useMultipleWishlist()
const {
  fetchAvailaibilityHouses,
  fetchWishlist,
  housesAvailable,
  housesUnavailable,
  loading,
  setSummaryOpen,
  wishlistActive,
} = useMultipleWishlistSummary()

$dayjs.locale(locale.value)

const token =
  lastWishlistTokenActive.value || wishlists.value?.[0]?.privateToken || ''
const modelValue = ref(token)

const inquirySent = ref(false)
const calendarRef = ref<InstanceType<typeof BaseCalendar> | null>(null)
const checkIn = ref(
  $httpCookies.get<CookieUserWishlistDates>('user_wishlist_dates')?.start || '',
)
const checkOut = ref(
  $httpCookies.get<CookieUserWishlistDates>('user_wishlist_dates')?.end || '',
)

const options = computed(() =>
  wishlists.value.map((w) => ({
    label: w.title,
    value: w.privateToken,
  })),
)

const favoritesIdsInWishlist = computed(() => {
  if (wishlistActive.value) {
    return (
      wishlists.value.find((w) => w.id === wishlistActive.value?.id)
        ?.houseIds || []
    )
  }

  return []
})

const hasDate = computed(
  () => Boolean(checkIn.value) && Boolean(checkOut.value),
)

const textButtonCalendar = computed(() => {
  if (hasDate.value) {
    const dateCheckIn = $dayjs(checkIn.value, 'YYYY-MM-DD')
    const dateCheckOut = $dayjs(checkOut.value, 'YYYY-MM-DD')

    if (dateCheckIn.isValid() && dateCheckOut.isValid()) {
      return `${dateCheckIn.format('D MMM')} - ${dateCheckOut.format('D MMM YYYY')}`
    }
  }

  return t('wishlistMultiple.summary.selectDates')
})

const priceFormatted = (price?: PriceByCurrency) => {
  if (price) {
    if (price[currency.value]) {
      return getPriceWithCurrency({
        price: price[currency.value],
        currency: currency.value,
        i18n: { locale },
      })
    }

    return t('house.price_upon_request')
  }

  return ''
}

const rangePriceFormatted = (
  minPrice: PriceByCurrency,
  maxPrice: PriceByCurrency,
) => {
  if (minPrice?.[currency.value] && maxPrice?.[currency.value]) {
    return weeklyPriceRangeText(
      { locale, t },
      minPrice[currency.value],
      maxPrice[currency.value],
      currency.value,
      true,
    )
  }

  return ''
}

const handleWishlist = (token: string, houseId: number) => {
  if (favoritesIdsInWishlist.value.includes(houseId)) {
    removeFavorite(token, houseId)
  } else {
    addFavorite(token, houseId)
  }
}

const handleCheckIn = (date: Date) => {
  checkIn.value = date ? $dayjs(date).format('YYYY-MM-DD') : ''
}

const handleChekout = async (date: Date) => {
  checkOut.value = date ? $dayjs(date).format('YYYY-MM-DD') : ''

  if (hasDate.value) {
    $httpCookies.set('user_wishlist_dates', {
      start: checkIn.value,
      end: checkOut.value,
    })

    await fetchAvailaibilityHouses(
      modelValue.value,
      checkIn.value,
      checkOut.value,
    )
  }
}

const handleClearDates = () => {
  $httpCookies.remove('user_wishlist_dates')
  checkIn.value = ''
  checkOut.value = ''
}

const openCalendar = () => {
  setTimeout(() => {
    calendarRef.value?.openCalendar()
  }, 100)
}

const handleInquiry = async () => {
  if (!hasDate.value) {
    openCalendar()
    return
  }

  if (!wishlistActive.value?.privateToken) return

  await wishlistController.makeRequestWishlist({
    start_date: checkIn.value,
    end_date: checkOut.value,
    currency: currency.value,
    private_token: wishlistActive.value.privateToken,
  })

  if (wishlistPresenter.vm.makeRequestWishlistSucceeded) {
    trackEvent({
      source: 'wishlist',
      event: 'wishlist_inquiry_sent',
      wishlist_id: wishlistActive.value.privateToken,
    })

    inquirySent.value = true
    emits('inquiry-sended')
  }
}

const fetchAll = async (token: string) => {
  await Promise.all([
    fetchWishlist(token),
    hasDate.value
      ? fetchAvailaibilityHouses(token, checkIn.value, checkOut.value)
      : undefined,
  ])
}

watch(modelValue, async (token) => {
  setLastWishlistTokenActive(token)
  inquirySent.value = false

  await fetchAll(token)
})

onMounted(async () => {
  await fetchAll(modelValue.value)
})
</script>

<template>
  <div>
    <p class="mb-3 text-xl font-bold lg:text-md">
      {{ $t('wishlistMultiple.title') }}
    </p>

    <div class="flex">
      <div class="grow">
        <LazyBaseFormComponentsBaseMultiselect
          id="wishlist-list"
          v-model="modelValue"
          :can-clear="false"
          :can-deselect="false"
          :height="44"
          :options="options"
          name="wishlist-list"
          wrapper-class="mb-1"
        >
          <template #multiselect-singlelabel="{ value }">
            <div class="multiselect-single-label">
              <span class="text-md">{{ value.label }}</span>
            </div>
          </template>
          <template #multiselect-option="{ option }">
            <span class="text-md">{{ option.label }}</span>
          </template>
          <template #multiselect-afterlist>
            <div class="p-3">
              <BaseButton
                class="!text-md"
                color="secondary"
                size="sm"
                variant="outline"
                @click="$emit('open-modal', 'add')"
              >
                {{ $t('wishlistMultiple.createNewList') }}
              </BaseButton>
            </div>
          </template>
        </LazyBaseFormComponentsBaseMultiselect>
      </div>

      <button type="button" @click="$emit('open-modal', 'parameters')">
        <BaseIcon name="moreVert" />
      </button>
    </div>

    <WishlistMultipleSummaryEmptyList
      v-if="!wishlistActive?.houses?.length"
      empty-favorites
    />
    <template v-else>
      <button
        type="button"
        class="text-xs underline"
        @click.prevent="openCalendar"
      >
        {{ textButtonCalendar }}
      </button>

      <BaseFormComponentsBaseCalendar
        ref="calendarRef"
        :end-date="checkOut"
        :has-footer="false"
        :has-header="isMobile"
        :is-affixed="isMobile"
        :show-input-calendar="false"
        :start-date="checkIn"
        disabled-days-before-day-date
        label=""
        name="wishlist-multipe-summary-calendar"
        position="right"
        @update:start-date="handleCheckIn"
        @update:end-date="handleChekout"
        @clear-date="handleClearDates"
      >
        <template #lc-calendar-header-mobile="{ clearDates, closeDatePicker }">
          <div class="mb-4 flex justify-between">
            <button
              class="flex items-center"
              type="button"
              @click="closeDatePicker"
            >
              <BaseIcon class="mr-2" name="navArrowLeft" />
              <div class="text-md font-medium">
                {{ $t('global.go_back') }}
              </div>
            </button>
            <button
              :class="[
                'text-md font-bold underline',
                { 'text-gray-400': !hasDate },
                { 'text-gray-700': hasDate },
              ]"
              type="button"
              @click="clearDates"
            >
              {{ $t('inquiry.clearDates') }}
            </button>
          </div>
        </template>
      </BaseFormComponentsBaseCalendar>

      <div
        class="mt-3.5 max-h-[calc(100vh-27rem)] overflow-y-scroll lg:max-h-[calc(100vh-22rem)]"
      >
        <template v-if="loading">
          <WishlistMultipleSummaryHouseCardSkeleton
            v-for="index in 3"
            :key="index"
            class="mb-2 last:mb-0"
          />
        </template>
        <template v-else-if="hasDate">
          <WishlistMultipleSummaryHouseCard
            v-for="house in housesAvailable"
            :key="house.id"
            :destination="house.destination[locale]"
            :image="house.image"
            :name="house.name"
            :price="priceFormatted(house.price)"
            :slug="house.slug[locale]"
            :wishlist-active="favoritesIdsInWishlist.includes(house.id)"
            class="mb-2 last:mb-0"
            @handle-wishlist="
              handleWishlist(wishlistActive.privateToken, house.id)
            "
            @house-clicked-mobile="setSummaryOpen(false)"
          />

          <p
            v-if="housesUnavailable.length"
            class="mb-2 mt-4 text-md font-medium leading-5"
          >
            {{ $t('wishlistMultiple.summary.propertiesNotAvailable') }}
          </p>

          <WishlistMultipleSummaryHouseCard
            v-for="house in housesUnavailable"
            :key="house.id"
            :destination="house.destination[locale]"
            :image="house.image"
            :name="house.name"
            :price="$t('wishlistMultiple.summary.unavailable')"
            :slug="house.slug[locale]"
            :wishlist-active="favoritesIdsInWishlist.includes(house.id)"
            class="mb-2 opacity-50 last:mb-0"
            @handle-wishlist="
              handleWishlist(wishlistActive.privateToken, house.id)
            "
            @house-clicked-mobile="setSummaryOpen(false)"
          />
        </template>
        <template
          v-for="house in wishlistActive?.houses"
          v-else
          :key="house.id"
        >
          <WishlistMultipleSummaryHouseCard
            :destination="house.destination[locale]"
            :image="house.image"
            :name="house.name"
            :price="rangePriceFormatted(house.minPrice, house.maxPrice)"
            :slug="house.slug[locale]"
            :wishlist-active="favoritesIdsInWishlist.includes(house.id)"
            class="mb-2 last:mb-0"
            @handle-wishlist="
              handleWishlist(wishlistActive.privateToken, house.id)
            "
            @house-clicked-mobile="setSummaryOpen(false)"
          />
        </template>
      </div>

      <div v-if="inquirySent" class="mt-3 text-center">
        <div
          class="mb-1 flex items-center justify-center text-md leading-5 text-success"
        >
          <BaseIcon :size="1" class="mr-1 text-success" name="checkCircle" />
          {{ $t('wishlistMultiple.summary.sharedList') }}
        </div>
        <p class="mb-0 text-md leading-5">
          {{ $t('wishlistMultiple.summary.additionalInformationText[0]') }}
        </p>
      </div>
      <div v-else class="mt-3">
        <p class="mb-2 whitespace-break-spaces text-center text-md leading-5">
          {{ $t('wishlistMultiple.summary.letUsGuide') }}
        </p>
        <BaseButton
          :disabled="hasDate && !housesAvailable.length"
          block-full
          class="!text-md"
          color="secondary"
          @click="handleInquiry"
        >
          {{ $t('wishlistMultiple.summary.sendToAnAdvisor') }}
        </BaseButton>
      </div>
    </template>
  </div>
</template>

<style>
#wishlist-list {
  --ms-option-py: 0.5rem;
  --ms-option-px: 0.75rem;
  min-height: 44px;
}
</style>
