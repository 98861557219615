<script setup lang="ts">
defineProps<{
  error: { data: { message: string } }
}>()

const localePath = useLocalePath()
const { isDesktop } = useBreakpoint()

const bgError = computed(() =>
  isDesktop.value
    ? 'https://cdn.lecollectionist.com/lc/production/assets/images/error/photo-page-erreur-404-desktop.jpg'
    : 'https://cdn.lecollectionist.com/lc/production/assets/images/error/photo-page-erreur-404-mobile.jpg',
)
</script>

<template>
  <div
    :style="{ backgroundImage: `url(${bgError})` }"
    class="background-error flex min-h-[600px] flex-1 bg-cover py-16 laptop:min-h-[750px]"
  >
    <div class="lc-container flex flex-col">
      <div class="mt-0 text-center md:mt-14 md:text-left">
        <p class="mb-7 uppercase tracking-widest md:mb-4">
          {{ $t('error_layout.error404') }}
        </p>
        <h1 class="mb-6 max-w-[650px] text-4xl font-normal md:text-8xl">
          {{ $t('error_layout.title') }}
        </h1>
        <p class="mb-6 max-w-[650px] text-xl font-normal">
          {{ error.data.message }}
        </p>
        <BaseButton color="secondary" :to="localePath('index')" size="lg">
          {{ $t('error_layout.btn') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style scoped>
.background-error {
  background-position: 70% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

@screen tablet {
  .background-error {
    background-position: 100% 70%;
  }
}

@screen desktop {
  .background-error {
    background-position: 100% 100%;
  }
}
</style>
