<script setup lang="ts">
const emits = defineEmits<{ close: [] }>()

const close = () => {
  emits('close')
}
</script>

<template>
  <button
    aria-expanded="true"
    class="col-span-2 col-start-6 flex cursor-pointer flex-col items-center"
    type="button"
    @click="close"
  >
    <BaseIcon aria-hidden="true" name="navArrowUp" />
    <span
      class="mx-auto mb-1 flex items-center text-center text-sm font-medium"
    >
      {{ $t('global.close') }}
    </span>
  </button>
</template>
