import { siretMask } from '~/helpers/BaseForm/formMasks'
import { InputMask } from '~/components/BaseForm/core/primitives'

const Siret: typeof InputMask = {
  ...InputMask,
  attr: {
    ...InputMask.attr,
    mask: siretMask,
    rules: { required: false, regex: /^[0-9]{3} [0-9]{3} [0-9]{3} [0-9]{5}$/ },
    labelTranslateKey: 'form.registrationNumber',
    name: 'siret',
  },
  containerInputClass: 'w-full md:w-1/2 self-end',
}

export default Siret
