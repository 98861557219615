import { HouseWishlistType } from "../../../../types";

export const housesFormatted = (houses: HouseWishlistType[]) => {
  return houses.map((house) => ({
    id: house.id,
    name: house.name,
    destination: house.destination,
    image: house.firstPhotoUrl,
    maxPrice: house.maxPrice,
    minPrice: house.minPrice,
    capacity: house.capacity,
    bathrooms: house.bathrooms,
    bedrooms: house.bedrooms,
    slug: house.slug,
  }));
};
