import type {
  GetFormDataType,
  PayloadFormData,
} from '~/components/BaseForm/types'

export const getFormData: GetFormDataType = (data) => {
  const formData = new FormData()

  data.forEach((element: { parent: string; payload: PayloadFormData }) => {
    if (typeof element.payload === 'object') {
      const keys = Object.keys(element.payload)
      keys.forEach((key) => {
        const value = element.payload[key]
        if (Array.isArray(value)) {
          value.forEach((v) =>
            formData.append(`${element.parent}[${key}][]`, v),
          )
        } else {
          formData.append(`${element.parent}[${key}]`, value)
        }
      })
    } else {
      formData.append(element.parent, element.payload)
    }
  })

  return formData
}

export const getFile = (elementId: string): File => {
  const element = document.getElementById(elementId) as HTMLInputElement
  const file = element?.files?.[0] as File

  return file
}
