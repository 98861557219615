import algoliasearch from 'algoliasearch'
import type {
  SearchResponse,
  MultipleQueriesQuery,
} from '@algolia/client-search'
import type { RuntimeConfig } from 'nuxt/schema'

/**
 * An utility function to use AlgoliaSearch with specific index
 */
export const algoliaSearch = ({
  algoliaSearchKey,
  indexName,
  config,
}: {
  algoliaSearchKey?: string
  indexName: string
  config: RuntimeConfig
}) => {
  const CLIENT = algoliasearch(
    config.public.algoliaId,
    algoliaSearchKey || config.public.algoliaSearchKey,
  )
  const INDEX = CLIENT.initIndex(
    `${config.public.algoliaIndexPrefix}_${indexName}`,
  )

  return INDEX
}

/**
 * An utility function to use AlgoliaMultipleQueries with specific queries
 */
export const algoliaQueries = <T = unknown>({
  algoliaSearchKey,
  queries,
  config,
}: {
  algoliaSearchKey?: string
  queries: MultipleQueriesQuery[]
  config: RuntimeConfig
}) => {
  const CLIENT = algoliasearch(
    config.public.algoliaId,
    algoliaSearchKey || config.public.algoliaSearchKey,
  )
  const MULTIPLE_QUERIES = CLIENT.multipleQueries<T>(queries)

  return MULTIPLE_QUERIES as Promise<{ results: Array<SearchResponse<T>> }>
}
