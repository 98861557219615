<script setup lang="ts">
const props = defineProps<{
  destination: string
  image: string
  name: string
  price: string
  slug: string
  wishlistActive: boolean
}>()

defineEmits<{
  'handle-wishlist': []
  'house-clicked-mobile': []
}>()

const localePath = useLocalePath()
const { isMobile } = useBreakpoint()

const housePath = computed(() =>
  localePath({
    name: 'house-slug',
    params: { slug: props.slug },
  }),
)
</script>

<template>
  <NuxtLink
    :to="housePath"
    :target="isMobile ? '_self' : '_blank'"
    class="relative flex rounded border border-gray-200 text-gray-700 hover:no-underline"
    @click="isMobile ? $emit('house-clicked-mobile') : undefined"
  >
    <span class="size-[88px] bg-gray-200">
      <BaseNuxtImg
        :src="image"
        alt="img"
        class="rounded-l"
        height="88"
        width="88"
      />
    </span>
    <span
      class="flex w-[calc(100%-88px)] grow flex-col justify-center px-4 py-2"
    >
      <span class="font-monospace truncate pr-3 leading-6">
        {{ name }}
      </span>
      <span class="mb-2 truncate text-xs text-gray-600">
        {{ destination }}
      </span>
      <span class="truncate text-xs font-bold">
        {{ price }}
      </span>
    </span>
    <span class="absolute right-2 top-2">
      <BaseButton
        class="!border-0"
        :color="wishlistActive ? 'white' : 'black'"
        has-icon
        no-padding
        type="button"
        @click.prevent="$emit('handle-wishlist')"
      >
        <BaseIcon
          name="heart"
          :class="[
            'icon-transition',
            { 'fill-primary-500 stroke-primary-500': wishlistActive },
          ]"
          :stroke-width="1"
          :size="1"
        />
      </BaseButton>
    </span>
  </NuxtLink>
</template>
