import { GetAutomaticProposalsUseCase } from "../usecases";
import { GetAutomaticProposalsPresentation } from "../usecases/GetAutomaticProposals/interfaces/GetAutomaticProposalsPresentation";

import {
  CreateAutomaticProposalCommentInput,
  ModifiyAutomaticProposalCommentInput,
} from "../../../types/common/automaticProposal";

import {
  CreateAutomaticProposalCommentUseCase,
  ModifyAutomaticProposalCommentUseCase,
} from "../usecases";
import { CreateAutomaticProposalCommentPresentation } from "../usecases/CreateAutomaticProposalComment/interfaces/CreateAutomaticProposalCommentPresentation";
import { ModifyAutomaticProposalCommentPresentation } from "../usecases/ModifyAutomaticProposalComment/interfaces/ModifyAutomaticProposalCommentPresentation";

type Usecases = {
  getAutomaticProposals: GetAutomaticProposalsUseCase;
  createAutomaticProposalComment: CreateAutomaticProposalCommentUseCase;
  modifyAutomaticProposalComment: ModifyAutomaticProposalCommentUseCase;
};

type Presenter = GetAutomaticProposalsPresentation &
  CreateAutomaticProposalCommentPresentation &
  ModifyAutomaticProposalCommentPresentation;

export default class AutomaticProposalsController {
  constructor(
    private usecases: Partial<Usecases>,
    private presenter: Presenter
  ) {}

  async getAutomaticProposals(privateToken: string) {
    await this.usecases.getAutomaticProposals?.execute(this.presenter, {
      privateToken,
    });
  }

  async createAutomaticProposalComment(
    input: CreateAutomaticProposalCommentInput
  ) {
    await this.usecases.createAutomaticProposalComment?.execute(
      this.presenter,
      input
    );
  }

  async modifyAutomaticProposalComment(
    input: ModifiyAutomaticProposalCommentInput
  ) {
    await this.usecases.modifyAutomaticProposalComment?.execute(
      this.presenter,
      input
    );
  }
}
