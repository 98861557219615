import { InputSelect } from '~/components/BaseForm/core/primitives'

const Currency: typeof InputSelect = {
  ...InputSelect,
  attr: {
    ...InputSelect.attr,
    rules: 'required',
    labelTextTranslateKey: 'form.currency',
    name: 'currency',
  },
  options: [
    {
      label: 'EUR',
      value: 'EUR',
    },
    {
      label: 'GBP',
      value: 'GBP',
    },
    {
      label: 'CHF',
      value: 'CHF',
    },
    {
      label: 'USD',
      value: 'USD',
    },
  ],
}

export default Currency
