import { MakeHouseRequestRepository } from "./interfaces/MakeHouseRequestRepository";
import { MakeHouseRequestPresentation } from "./interfaces/MakeHouseRequestPresentation";
import { MakeHouseRequestInput } from "./types";

export default class MakeHouseRequestUseCase {
  constructor(private repository: MakeHouseRequestRepository) {}

  async execute(
    presenter: MakeHouseRequestPresentation,
    request: MakeHouseRequestInput
  ) {
    const response = await this.repository.makeHouseRequest(request);
    if (response?.data) {
      presenter.displayMakeHouseRequest(response.data);
    } else {
      presenter.displayHouseRequestError(
        "houseDetails.inquiry.errorOnMakeRequest"
      );
    }
  }
}
