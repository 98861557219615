export default (clientHttp, apiVersion) => ({
  read(id) {
    return clientHttp.get(`${apiVersion}/user/houses/${id}/sale_information`)
  },

  update(id, params) {
    return clientHttp.put(`${apiVersion}/user/houses/${id}/sale_information`, {
      ...params,
    })
  },
})
