import { GetUserWishlistHouseAvailablesRepository } from "./interfaces/GetUserWishlistHouseAvailablesRepository";
import { GetUserWishlistHouseAvailablesPresentation } from "./interfaces/GetUserWishlistHouseAvailablesPresentation";
import { WishlistHouseAvailables } from "../../domain/WishlistHouseAvailables";
import { GetUserWishlistHouseAvailablesInput } from "../../../../types";

export default class GetUserWishlistsHouseAvailablesUseCase {
  constructor(private repository: GetUserWishlistHouseAvailablesRepository) {}

  async execute(
    presenter: GetUserWishlistHouseAvailablesPresentation,
    input: GetUserWishlistHouseAvailablesInput
  ) {
    const response = await this.repository.getWishlistAvailabilities(input);

    if (response?.data) {
      const housesAvailable = new WishlistHouseAvailables(
        response.data
      ).build();
      presenter.displayHouseAvailables(housesAvailable);
    } else {
      presenter.displayHouseAvailables([]);
    }
  }
}
