import { GetHousePeriodsAndBookingsRepository } from "./interfaces/GetHousePeriodsAndBookingsRepository";
import { GetHousePeriodsAndBookingsPresentation } from "./interfaces/GetHousePeriodsAndBookingsPresentation";

export default class GetHousePeriodsAndBookingsUseCase {
  constructor(private repository: GetHousePeriodsAndBookingsRepository) {}

  async execute(
    presenter: GetHousePeriodsAndBookingsPresentation,
    input: {
      houseId: string;
      startAt: string;
      endAt: string;
      privateToken?: string;
    }
  ) {
    const bookingsResponse = await this.repository.getBookingsByHouseId(input);
    const periodsDurationsResponse = await this.repository.getPeriodDurations(
      input
    );

    if (bookingsResponse.data) {
      presenter.displayBookings(
        bookingsResponse.data.map((booking) => ({
          checkInTime: booking.checkInTime,
          checkOutTime: booking.checkOutTime,
          startDate: booking.checkInDate,
          endDate: booking.checkOutDate,
          id: booking.id,
          type: "contract",
          otherType: "Bookings::Contract",
        }))
      );
    } else {
      presenter.displayBookings(undefined);
    }

    if (periodsDurationsResponse.data) {
      presenter.displayPeriodDurations(periodsDurationsResponse.data);
    } else {
      presenter.displayPeriodDurations(undefined);
    }
  }
}
