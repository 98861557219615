<script setup lang="ts">
import Error404 from './components/Error/Error404.vue'
import Error500 from './components/Error/Error500.vue'
import ErrorContractBadAccount from './components/Error/ErrorContractBadAccount.vue'
import ErrorClientContractNotSignedByOwner from './components/Error/ErrorClientContractNotSignedByOwner.vue'
import ErrorWhiteBrand from './components/Error/ErrorWhiteBrand.vue'
import type { NuxtError } from '#app'

const { isSmallAndMediumScreen } = useBreakpoint()
const { headerHeight, setRoute } = useHeaderNavigation()
const { trackPageViewErrorPage } = useTracking()

const props = withDefaults(
  defineProps<{
    error?: NuxtError & { url?: string }
  }>(),
  {
    error: () => ({}) as NuxtError,
  },
)

const {
  public: { environment },
} = useRuntimeConfig()

// handle header theme
setRoute('destinations___fr')
trackPageViewErrorPage()

const cssVar = computed(() => ({
  '--header-height': `${headerHeight.value}px`,
}))

const data = computed(() =>
  props.error?.data
    ? typeof props.error.data === 'string'
      ? JSON.parse(props.error.data)
      : props.error.data
    : {},
)

const components = reactive({
  404: {
    componentName: shallowRef(Error404),
  },
  500: {
    componentName: shallowRef(Error500),
  },
  contractBadAccount: {
    componentName: shallowRef(ErrorContractBadAccount),
  },
  contractNotSignedByOwner: {
    componentName: shallowRef(ErrorClientContractNotSignedByOwner),
  },
  whitebrand: {
    componentName: shallowRef(ErrorWhiteBrand),
  },
})

const type = computed<keyof typeof components>(() => {
  if (props.error?.statusCode === 404) {
    if (data.value.page === 'whitebrand') {
      return 'whitebrand'
    }

    return data?.value?.page || 404
  }

  return 500
})

const isProduction = computed(() => ['production'].includes(environment))
</script>

<template>
  <div :style="cssVar" class="flex min-h-screen flex-col">
    <TheHeader />
    <TheMenuMobile v-if="isSmallAndMediumScreen" />
    <div>
      <component
        :is="components[type].componentName"
        :error="error"
        :data="data"
      />

      <div
        v-if="!isProduction"
        class="mx-auto flex w-[1280px] items-center justify-center py-10"
      >
        <div>
          <div v-if="error.url" class="mb-4">
            <strong class="mb-4 block">Url</strong>
            <p>{{ error.url }}</p>
          </div>
          <div v-if="error.statusCode" class="mb-4">
            <strong class="mb-4 block">StatusCode</strong>
            <p>{{ error.statusCode }}</p>
          </div>
          <div v-if="error.statusMessage" class="mb-4">
            <strong class="mb-4 block">StatusMessage</strong>
            <p class="w-[1280px] bg-gray-100 p-4">{{ error.statusMessage }}</p>
          </div>
          <div v-if="error.message" class="mb-4">
            <strong class="mb-4 block">Message</strong>
            <p class="w-[1280px] bg-gray-100 p-4">{{ error.message }}</p>
          </div>
          <div v-if="error.stack" class="mb-4">
            <strong class="mb-4 block">Stack</strong>
            <p class="w-[1280px] bg-gray-100 p-4">{{ error.stack }}</p>
          </div>
        </div>
      </div>
    </div>

    <TheFooter />
  </div>
</template>
