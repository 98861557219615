import type { ConditionsField, Rules } from '~/components/BaseForm/types'

type InputPhone = {
  inputType: 'tel-input'
  containerInputClass?: string
  conditions?: ConditionsField
  hidden?: boolean
  viewModeRow?: boolean
  infoText?: string
  order?: number
  attr: {
    disabled: boolean
    placeholderTranslateKey?: string
    hasBorder?: boolean
    inputHtml?: string
    labelHtml?: string
    labelTranslateKey: string
    name: string
    rules?: Rules
    wrapperClass?: string
  }
}

const inputPhone: InputPhone = {
  inputType: 'tel-input',
  attr: {
    name: 'phone',
    labelTranslateKey: 'form.label',
    rules: 'required',
    disabled: false,
  },
}

export default inputPhone
