<script setup lang="ts">
const localePath = useLocalePath()
const { setSummaryOpen } = useMultipleWishlistSummary()

withDefaults(
  defineProps<{
    emptyFavorites?: boolean
  }>(),
  {
    emptyFavorites: false,
  },
)

const closeAndRedirect = () => {
  setSummaryOpen(false)
  navigateTo(localePath({ name: 'search' }))
}
</script>

<template>
  <div class="text-center">
    <BaseIcon name="favorisFilled" :size="4" class="mx-auto" />
    <p class="mb-3 text-xs text-gray-500">
      {{
        $t(
          `wishlistMultiple.summary.${emptyFavorites ? 'noFavorites' : 'noList'}`,
        )
      }}
    </p>
    <p class="text-md leading-5">
      {{ $t('wishlistMultiple.summary.addToFavourites') }}
    </p>
    <BaseButton
      color="secondary"
      block-full
      class="!text-md"
      @click="closeAndRedirect"
    >
      {{ $t('wishlistMultiple.summary.addProperties') }}
    </BaseButton>
  </div>
</template>
