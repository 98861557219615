/** *   CURRENCY SETTER 💰   ***/

/*
  Gets the user's currency choice if there's one,
  or sets it as a cookie based on the browser's language
*/
export default defineNuxtPlugin((nuxtApp) => {
  const { $httpCookies } = nuxtApp
  const { setCurrency } = useUser()
  const userCurrency = $httpCookies?.get('currency')

  const browserLanguage = useState('browserLanguage')

  const getCurrencyFromBrowser = () => {
    let newCurrency = ''

    switch (browserLanguage.value) {
      case 'US':
        newCurrency = 'USD'
        break
      case 'GB':
        newCurrency = 'GBP'
        break
      case 'CH':
        newCurrency = 'CHF'
        break
      default:
        newCurrency = 'EUR'
        break
    }

    return newCurrency
  }

  if (userCurrency) {
    setCurrency(userCurrency)
  } else {
    setCurrency(getCurrencyFromBrowser())
    $httpCookies?.set('currency', getCurrencyFromBrowser())
  }
})
