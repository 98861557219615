import { isEqual } from '~/utils/isEqual'

export const defineTree = (data, destinations) => {
  // Define tree
  let country = {}
  let cluster = {}
  let desti = {}

  // If country, cluster and destination to be {}
  if (data.isCountry) {
    country = data
    // If cluster, define country and destination to be {}
  } else if (data.isCluster) {
    cluster = data
    if (destinations) {
      country = destinations
    }
  } else if (Array.isArray(destinations)) {
    const clusterIds = Array.isArray(data)
      ? data.filter((d) => !d.isCountry && d.clusterId).map((d) => d.clusterId)
      : []

    // Desti
    if (Array.isArray(data)) {
      desti = data.find((d) => !d.isCountry && !clusterIds.includes(d.id))
    } else {
      desti = data
    }

    // Cluster
    if (Array.isArray(data)) {
      cluster = data.find((d) => clusterIds.includes(d.id)) || {}
    } else {
      cluster =
        destinations.find((x) => x.isCluster && isEqual(x.id, data.cluster)) ||
        {}
    }

    // Country
    if (Array.isArray(data)) {
      country = data.find((x) => x.isCountry) || {}
    } else {
      country =
        destinations.find((x) => x.isCountry && isEqual(x.id, data.country)) ||
        {}
    }
  }

  return {
    country,
    cluster,
    desti,
  }
}

const defineCoverPhotoUrl = (
  photos,
  destiPhoto,
  clusterPhoto,
  countryPhoto,
) => {
  let coverPhoto = {}

  if (photos?.length) {
    if (destiPhoto) {
      coverPhoto = photos.find((p) => isEqual(p.id, destiPhoto))
    } else if (clusterPhoto) {
      coverPhoto = photos.find((p) => isEqual(p.id, clusterPhoto))
    } else {
      coverPhoto = photos.find((p) => isEqual(p.id, countryPhoto))
    }
  } else if (photos) {
    coverPhoto = photos
  }

  return coverPhoto?.url || ''
}

export const getCoverPhotoUrl = (destination, photos, destinations) => {
  const { cluster, country } = defineTree(destination, destinations)
  const destiCoverPhoto = destination.coverFeaturedPhoto

  const clusterCoverPhoto =
    cluster.coverFeaturedPhoto || cluster.coverFeaturedPhotoId
  const countryCoverPhoto =
    country.coverFeaturedPhoto || country.coverFeaturedPhotoId

  return defineCoverPhotoUrl(
    photos,
    destiCoverPhoto,
    clusterCoverPhoto,
    countryCoverPhoto,
  )
}

const getSeoPhotoUrl = (destination, photos) => {
  let seoPhoto = {}

  if (photos?.length) {
    seoPhoto = photos.find((p) => isEqual(p.id, destination.seoFeaturedPhoto))
  } else {
    seoPhoto = photos
  }

  return seoPhoto?.url || ''
}

export const addPhotosToDestination = (destination, photos, destinations) => {
  const coverPhotoUrl = getCoverPhotoUrl(destination, photos, destinations)
  const seoPhotoUrl = getSeoPhotoUrl(destination, photos)

  return {
    coverPhotoUrl,
    seoPhotoUrl,
    ...destination,
  }
}

export const getDestinationSlug = (searchUrl) => {
  const slug = Object.assign({}, searchUrl)

  for (const locale in slug) {
    if (searchUrl[locale]) {
      const slugArr = searchUrl[locale].split('/')
      const destination = slugArr[0]
      const name = slugArr[slugArr.length - 1]

      slug[locale] = {
        name,
        destination,
        path: `/${locale}/${destination}/${name}`,
      }
    }
  }

  return slug
}
