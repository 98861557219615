import { SendClientContractInformationRepository } from "./interfaces/SendClientContractInformationRepository";
import { SendClientContractInformationPresentation } from "./interfaces/SendClientContractInformationPresentation";
import ClientContractBuild from "../../domain/ClientContract";
import { ApiFormErrors, ErrorsForm } from "../../../../types";
import { convertApiFormErrorsToErrorsForm } from "../../../../services/FormError/FormatApiFormErrorToErrorsForm";

export default class SendClientContractInformationUseCase {
  constructor(private repository: SendClientContractInformationRepository) {}
  errorFormFieldNameByApiFieldName(apiFieldName: string) {
    const fieldName = apiFieldName
      .replace("contract.", "")
      .replace("client.", "");
    const fieldNameByApiFieldName: Record<string, string> = {
      client_address: "address",
      client_city: "city",
      client_postal_code: "postalCode",
      client_country: "country",
      client_civility: "civility",
      client_firstname: "firstname",
      client_lastname: "lastname",
      client_email: "email",
      client_birthdate: "birthdate",
      client_identity_document_type: "identityDocumentType",
      client_identity_document_number: "identityDocumentNumber",
      client_identity_document_expiration_date:
        "identityDocumentExpirationDate",
      client_identity_document_files: "identityDocumentFiles",
      client_nationality: "nationality",
      client_phone: "phone",
      client_company_address: "address",
      client_company_city: "city",
      client_company_postal_code: "postalCode",
      client_company_country: "country",
      client_company_representative_civility: "civilty",
      client_company_representative_firstname: "firstname",
      client_company_representative_lastname: "lastname",
      client_company_email: "email",
      client_company_representative_birthdate: "birthdate",
      client_company_representative_identity_document_type:
        "identityDocumentType",
      client_company_representative_identity_document_number:
        "identityDocumentNumber",
      client_company_representative_identity_document_expiration_date:
        "expirationDate",
      client_company_representative_nationality: "nationality",
      client_company_phone: "phone",
      client_company_name: "companyName",
      client_company_siret: "companyNumber",
      "client_company.siret": "companyNumber",
    };

    return fieldNameByApiFieldName[fieldName] ?? fieldName;
  }

  errorFormValueByApiError(fieldName: string, error: string) {
    if (error === "blank") {
      return "missing";
    }
    if (
      error === "greater_than_or_equal_to" &&
      fieldName === "identityDocumentExpirationDate"
    ) {
      return "expiredAtCheckout";
    }
    return error;
  }

  mapErrorsFormFields(errorsForm: ErrorsForm) {
    return errorsForm.map((error) => {
      const fieldName = this.errorFormFieldNameByApiFieldName(error.fieldName);
      return {
        fieldName,
        error: this.errorFormValueByApiError(fieldName, error.error),
      };
    });
  }

  async execute(
    presenter: SendClientContractInformationPresentation,
    input: {
      clientContractId: string;
      information: BodyInit;
    },
    options?: Record<string, unknown>
  ) {
    try {
      const {
        data: clientContractData,
        included: clientContractIncluded,
        errors,
      } = await this.repository.sendClientContractInformation(
        {
          ...input,
          includes: {
            include: [
              "house",
              "house.destination",
              "reviewer",
              "contract_payments",
            ],
          },
        },
        options
      );

      if (errors || !clientContractData || !clientContractIncluded) {
        if ((errors?.response?.data?.errors ?? []).length === 0) {
          presenter.displayToast({
            type: "error",
            msg: "clientContract.clientContractEditInformationForm.toast.unknown",
          });
        }

        const apiErrors = (errors?.response?.data?.errors ??
          []) as ApiFormErrors;

        const errorsForm = convertApiFormErrorsToErrorsForm(apiErrors);

        const mappedErrors = this.mapErrorsFormFields(errorsForm);

        if (mappedErrors.length === 0) {
          presenter.displayToast({
            type: "error",
            msg: "clientContract.clientContractEditInformationForm.toast.unknown",
          });
        } else if (
          mappedErrors.find(
            (error) => error.error === "already_signed_contract"
          )
        ) {
          presenter.displayToast({
            type: "error",
            msg: "clientContract.clientContractEditInformationForm.toast.already_signed_contract",
          });
        }

        return { errors: mappedErrors };
      }

      const clientContractFormatted = new ClientContractBuild({
        clientContractData,
        clientContractIncluded,
      }).build();

      presenter.displayContractClient(clientContractFormatted);

      return { errors: [] };
    } catch (err) {
      presenter.displayToast({
        type: "error",
        msg: "clientContract.clientContractEditInformationForm.toast.unknown",
      });
      return { errors: [] };
    }
  }
}
