import { buildRepositories } from "lc-repository";
import Controller from "./adapters/Controller";
import Presenter from "./adapters/Presenter";

import {
  CreateAutomaticProposalCommentUseCase,
  GetAutomaticProposalsUseCase,
  ModifyAutomaticProposalCommentUseCase,
} from "./usecases";
import { UserRepositoryType } from "../../types";

export type AutomaticProposalsBuildType = {
  automaticProposalsRepository: ReturnType<typeof buildRepositories.lc>;
  userRepository: UserRepositoryType;
};

export const automaticProposalsBuild = ({
  userRepository,
  automaticProposalsRepository,
}: AutomaticProposalsBuildType) => {
  const { automaticProposals, houses } = automaticProposalsRepository;

  const presenter = new Presenter();
  const controller = new Controller(
    {
      getAutomaticProposals: new GetAutomaticProposalsUseCase(
        automaticProposals,
        houses
      ),
      createAutomaticProposalComment: new CreateAutomaticProposalCommentUseCase(
        {
          createAutomaticProposalComment:
            userRepository.automaticProposal.automaticProposalComments
              .createAutomaticProposalComment,
        }
      ),
      modifyAutomaticProposalComment: new ModifyAutomaticProposalCommentUseCase(
        {
          modifyAutomaticProposalComment:
            userRepository.automaticProposal.automaticProposalComments
              .modifyAutomaticProposalComment,
        }
      ),
    },
    presenter
  );

  return { controller, presenter };
};
