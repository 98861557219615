import {
  AutomaticProposalCommentFormatted,
  GetAutomaticProposalsDataSorted,
} from "../../../types/common/automaticProposal";

export class AutomaticProposalsVM {
  autoProposals?: GetAutomaticProposalsDataSorted = {
    id: "",
    b2b2c: false,
    hidePrices: false,
    conciergeService :false,
    housesIds: [],
    sortedBy: null,
    clients: [],
    current: false,
    language: "",
    message: "",
    petFriendly: false,
    state: "",
    suitableForChildren: false,
    suitableForPeopleWithReducedMobility: false,
    automaticProposalHouses: [],
    salesInformations: {
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
    },
  };
  automaticProposalCommentCreated: AutomaticProposalCommentFormatted | null =
    null;
  automaticProposalCommentUpdated: AutomaticProposalCommentFormatted | null =
    null;
  msg: string = "";
}
