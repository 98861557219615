<script setup lang="ts">
const { trackEvent } = useTracking()

const trackNavigationLink = (label: string) => {
  trackEvent({
    event: 'social_link_clicked',
    social_network: label,
  })
}
</script>

<template>
  <ul class="mt-6">
    <li class="list-inline-item">
      <BaseButton
        aria-label="facebook"
        class="footer-social-network__link"
        href="https://fr-fr.facebook.com/lecollectionist/"
        rel="nofollow noopener"
        target="_blank"
        variant="link"
        @click="trackNavigationLink('Facebook')"
      >
        <BaseIcon class="stroke-white" name="facebook" :size="2" />
      </BaseButton>
    </li>
    <li class="list-inline-item">
      <BaseButton
        aria-label="instagram"
        class="footer-social-network__link"
        href="https://www.instagram.com/lecollectionist/"
        rel="nofollow noopener"
        target="_blank"
        variant="link"
        @click="trackNavigationLink('Instagram')"
      >
        <BaseIcon class="stroke-white" name="instagram" :size="2" />
      </BaseButton>
    </li>
    <li class="list-inline-item">
      <BaseButton
        aria-label="linkedin"
        class="footer-social-network__link"
        href="https://fr.linkedin.com/company/le-collectionist"
        rel="nofollow noopener"
        target="_blank"
        variant="link"
        @click="trackNavigationLink('Linkedin')"
      >
        <BaseIcon class="stroke-white" name="linkedin" :size="2" />
      </BaseButton>
    </li>
  </ul>
</template>

<style>
.footer-social-network {
  @apply pl-0;
  list-style: none;
}

.footer-social-network__link,
.footer-social-network__link:hover,
.footer-social-network__link:focus {
  @apply text-white;
}
</style>
