import dayjs from 'dayjs'
import 'dayjs/locale/fr'

import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

export const $dayjs = dayjs
