import { AuthRepositoryType } from "../../../../types";

import { dispatch } from "../../domain";
import { RemoveUserSessionPresentation } from "./interfaces/RemoveUserSessionPresentation";

export default class RemoveUserSessionUseCase {
  constructor(private repositories: AuthRepositoryType) {}

  async execute(presenter: RemoveUserSessionPresentation) {
    presenter.displayLoading();
    dispatch.removeSession(
      await this.repositories.session.signOut(),
      presenter
    );
  }
}
