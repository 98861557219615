import type { VariantKey } from '~/types/amplitude/types'
export type ExperimentTranslation = {
  [key: string]: {
    [key: VariantKey]: {
      [key: string]: string
    }
  }
}

export const experimentTranslations = <
  {
    en: ExperimentTranslation
    fr: ExperimentTranslation
  }
>{
  en: {
    test: {
      control: {
        debug: 'CONTROL',
      },
      treatment: {
        debug: 'TREATMENT',
      },
    },
  },
  fr: {
    test: {
      control: {
        debug: 'CONTROL',
      },
      treatment: {
        debug: 'TREATMENT',
      },
    },
  },
}
