import { InputSelect } from '~/components/BaseForm/core/primitives'

const Nationality: typeof InputSelect = {
  ...InputSelect,
  attr: {
    ...InputSelect.attr,
    rules: 'required',
    labelTextTranslateKey: 'form.nationality',
    name: 'nationality',
    searchable: true,
  },
  containerInputClass: 'w-full md:w-1/2 md:pr-10',
}

export default Nationality
