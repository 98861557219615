import type { ConditionsField, Rules } from '~/components/BaseForm/types'

type InputPassword = {
  inputType: 'input-password'
  conditions?: ConditionsField
  hidden?: boolean
  containerInputClass?: string
  viewModeRow?: boolean
  order?: number
  attr: {
    autocomplete?: 'new-password' | 'current-password'
    disableAutocomplete?: boolean
    disabled: boolean
    inputHtml?: string
    labelHtml?: string
    placeholderTranslateKey?: string
    labelTranslateKey: string
    name: string
    placeholder?: string
    rules?: Rules
    wrapperClass?: string
  }
}

const inputPassword: InputPassword = {
  inputType: 'input-password',
  attr: {
    disabled: false,
    labelTranslateKey: 'form.label',
    name: 'inputPassword',
  },
}

export default inputPassword
