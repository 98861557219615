import type {
  ConditionsField,
  InfoTextTranslate,
  Rules,
} from '~/components/BaseForm/types'

type InputMask = {
  inputType: 'mask-input'
  conditions?: ConditionsField
  containerInputClass?: string
  hidden?: boolean
  viewModeRow?: boolean
  attr: {
    disabled: boolean
    hasBorder?: boolean
    inputHtml?: string
    inputmode?: 'numeric'
    labelHtml?: string
    labelTranslateKey: string
    placeholderTranslateKey?: string
    mask?: string | string[]
    name: string
    pattern?: RegExp
    placeholder?: string
    rawValue: boolean
    rules?: Rules
    wrapperClass?: string
  }
  order?: number
  infoText?: string
  infoTextTranslateKey?: string | InfoTextTranslate
}

const inputMask: InputMask = {
  inputType: 'mask-input',
  attr: {
    labelTranslateKey: 'form.label',
    name: 'mask',
    rules: 'required',
    disabled: false,
    rawValue: false,
  },
}

export default inputMask
