import {
  ClientContractFormatted,
  ClientContractIncludedType,
  ClientContractType,
} from "../../../types";

type ClientContractBuildInput = {
  clientContractData: ClientContractType;
  clientContractIncluded: ClientContractIncludedType;
};

export default class ClientContractBuild {
  private clientContractFormatted: ClientContractFormatted;

  constructor(private clientContractBuildInput: ClientContractBuildInput) {
    const included = this.clientContractBuildInput.clientContractIncluded;
    const contractPayments = Array.isArray(included.contractPayments)
      ? included.contractPayments.sort((a, b) => a.position - b.position)
      : [included.contractPayments];

    const paymentTransactions = Array.isArray(included.paymentTransactions)
      ? included.paymentTransactions.sort(
          (a, b) => new Date(a.dueOn).getTime() - new Date(b.dueOn).getTime()
        )
      : [included.paymentTransactions];

    this.clientContractFormatted = {
      ...this.clientContractBuildInput.clientContractData,
      type: "clientContract",
      houses: included.houses,
      destinations: included.destinations,
      reviewers: included.reviewers,
      contractPayments,
      paymentTransactions,
      conciergerieOffer: this.getConciergerieOfferType(
        this.clientContractBuildInput.clientContractData.conciergerieOffer
      ),
    };
  }

  build() {
    return this.clientContractFormatted;
  }

  getConciergerieOfferType(conciergerieOffer: string) {
    switch (conciergerieOffer) {
      case "Essentielle":
      case "Essential":
        return "essential";
      case "Tailor":
        return "tailor";
      case "Club":
        return "club";
      default:
        return "essential";
    }
  }
}
