export default (clientHttp, apiVersion) => ({
  /**
   *
   * @param {String} query
   * @returns {Promise<{ data: import('~/types/reservationAgreement/types').UserData}>}
   */
  read(query = '') {
    return clientHttp.get(`${apiVersion}/user/informations${query}`)
  },
})
