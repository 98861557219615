import { ModifyUserWishlistRepository } from "./interfaces/ModifyUserWishlistRepository";
import { ModifyUserWishlistPresentation } from "./interfaces/ModifyUserWishlistPresentation";
import { Wishlists } from "../../domain/Wishlists";

import type { ModifyWishlistInput } from "../../../../types/common/wishlist";

export default class ModifyUserWishlistUseCase {
  constructor(private repository: ModifyUserWishlistRepository) {}

  async execute(
    presenter: ModifyUserWishlistPresentation,
    input: ModifyWishlistInput
  ) {
    const response = await this.repository.modifyWishlist({
      title: input.title,
      house_ids: input.house_ids,
      wishlistToken: input.wishlistToken,
    });

    if (response?.data) {
      const wishlists = new Wishlists(response.data).build();
      presenter.displayWishlistUpdated(wishlists[0]);
    }

    if (response?.errors) {
      presenter.displayModifyWishlistError("wishlist.badRequest");
    }
  }
}
