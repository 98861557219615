import { UserType } from "../../../types";
import Presenter from "../../../abstracts/Presenter";

import { AuthVM, NavType } from "./ViewModel";
import { SignInWithEmailPresentation } from "../usecases/SignInWithEmail/interfaces/SignInWithEmailPresentation";
import { TokenAuthPresentation } from "../usecases/TokenAuth/interfaces/TokenAuthPresentation";
import { SignUpPresentation } from "../usecases/SignUp/interfaces/SignUpPresentation";
import { RemoveUserSessionPresentation } from "../usecases/RemoveUserSession/interfaces/RemoveUserSessionPresentation";

export default class AuthPresenter
  extends Presenter<AuthVM>
  implements
    SignInWithEmailPresentation,
    RemoveUserSessionPresentation,
    SignUpPresentation,
    TokenAuthPresentation
{
  constructor() {
    super(new AuthVM());
  }

  displayMessage(msg: string): void {
    this.vm = {
      ...this.vm,
      errors: [],
      loading: false,
      msg,
    };
    this.notifyVM();
  }

  displayErrors(error: { fieldName: string; error: string }): void {
    this.vm = {
      ...this.vm,
      errors: [error],
      loading: false,
      msg: "",
      navigation: null,
    };
    this.notifyVM();
  }

  displayLoading(): void {
    this.vm.loading = true;
    this.notifyVM();
  }

  displayNavigation(name: NavType): void {
    this.vm = {
      ...this.vm,
      loading: false,
      navigation: name,
    };
    this.notifyVM();
  }

  displayToast(info: { type: "success" | "error"; msg: string } | null): void {
    this.vm.toast = info;
    this.notifyVM();
  }

  displaySignIn(success: boolean): void {
    this.vm.sendTokenByEmailResponse = success;
    this.notifyVM();
  }

  displayTokenSignIn(userRetrived: UserType): void {
    this.vm.verifyTokenResponse = userRetrived;
    this.notifyVM();
  }

  displayTokenSignUp(userRetrived: UserType): void {
    this.vm.signUpResponse = userRetrived;
    this.notifyVM();
  }

  displayAuthLoading(): void {
    this.vm.loading = true;
    this.notifyVM();
  }
  displayUser(userRetrived: UserType): void {
    this.vm.userCreated = userRetrived;
    this.notifyVM();
  }
  displayInitialize(): void {
    this.vm.errors = [];
    this.vm.msg = "";
    this.vm.userCreated = null;
    this.vm.loading = false;
    this.vm.navigation = null;
    this.vm.sendTokenByEmailResponse = null;
    this.vm.verifyTokenResponse = null;
    this.vm.signUpResponse = null;
    this.vm.toast = null;
    this.notifyVM();
  }
}
