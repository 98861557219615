const and = (query) => {
  if (query.includes('?')) {
    return '&'
  }

  return '?'
}

export const createQueryV3 = ({ id, fields, includes, otherQuery }) => {
  let query = ''

  if (id) {
    query = '?filter[id]=equals:'
  }

  // Create filters query
  if (id) {
    if (Array.isArray(id)) {
      query += `${id.join(',')}`
    } else {
      query += `${id}`
    }
  }

  // Create includes query
  if (includes) {
    query += `${and(query)}include=${includes.join(',')}`
  }

  // Create fields query
  if (fields) {
    Object.keys(fields).forEach((field) => {
      query += `${and(query)}fields[${field}]=${fields[field].join(',')}`
    })
  }

  if (otherQuery) {
    query += `&${otherQuery}`
  }

  return query
}
