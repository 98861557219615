import { Email } from '~/components/BaseForm/core/fields'
import type { CoreGroupFields } from '~/components/BaseForm/types'

export default (): CoreGroupFields => {
  const email: typeof Email = {
    ...Email,
    attr: {
      ...Email.attr,
      labelTranslateKey: '',
      rules: '',
      placeholderTranslateKey: 'field.email',
      wrapperClass: 'w-full',
    },
    containerInputClass: 'w-full',
  }

  return {
    containerGroupFieldsClass: 'w-full',
    fields: [email],
  }
}
