<script setup lang="ts">
import { phoneByLanguage } from '~/helpers/getPhoneByLanguage'

const config = useRuntimeConfig()
const { userSpecificProperties } = useUserProperties()

const emits = defineEmits<{
  click: [MouseEvent]
}>()
withDefaults(
  defineProps<{
    color?: 'primary' | 'primary-light'
  }>(),
  {
    color: 'primary',
  },
)

const phoneNumber = computed(() =>
  phoneByLanguage({
    config,
    language: userSpecificProperties.value.country,
  }),
)

const callback = (e: MouseEvent) => {
  emits('click', e)
}
</script>

<template>
  <base-button
    :color="color"
    :href="`tel:${phoneNumber}`"
    variant="link"
    @click="callback($event)"
  >
    {{ phoneNumber }}
  </base-button>
</template>
