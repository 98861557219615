export default (clientHttp, apiVersion) => ({
  update(id, params, contractEntity) {
    return clientHttp.put(
      `${apiVersion}/user/client_contracts/${id}?context=${contractEntity}`,
      params,
    )
  },
  confirms(id, params) {
    return clientHttp.post(
      `${apiVersion}/user/client_contracts/${id}/confirms`,
      params,
    )
  },
  read(id, query) {
    return clientHttp.get(`${apiVersion}/user/client_contracts/${id}${query}`)
  },
})
