export type PageKey =
  | 'search'
  | 'destinations'
  | 'inspirations'
  | 'aboutUs'
  | null
export type DropUpKey = 'contact' | 'language' | null

export type HeaderMobile = {
  dropUpActive: DropUpKey
  menuIsOpen: boolean
  slidePageActive: PageKey
  showDropUp: boolean
  searchIsOpen: boolean
}

export const useHeaderMobile = () => {
  const headerMobile = useState<HeaderMobile>('header-mobile', () => ({
    dropUpActive: null,
    menuIsOpen: false,
    slidePageActive: null,
    showDropUp: false,
    searchIsOpen: false,
  }))

  const setMenuIsOpen = (isOpen: boolean) => {
    headerMobile.value.menuIsOpen = isOpen
  }

  const setSearchIsOpen = (isOpen: boolean) => {
    headerMobile.value.searchIsOpen = isOpen
  }

  const setSlidePageActive = (pageKey: PageKey) => {
    headerMobile.value.slidePageActive = pageKey
  }

  const setActiveDropUp = (dropUpActive: DropUpKey) => {
    if (headerMobile.value.dropUpActive === dropUpActive) {
      headerMobile.value.dropUpActive = null
      setShowDropUp(!headerMobile.value.showDropUp)
    } else {
      headerMobile.value.dropUpActive = dropUpActive
      setShowDropUp(true)
    }
  }

  const setShowDropUp = (isOpen: boolean) => {
    // reset state of active dropup onClose
    if (!isOpen) {
      headerMobile.value.dropUpActive = null
    }

    headerMobile.value.showDropUp = isOpen
  }

  return {
    headerMobile,
    setActiveDropUp,
    setMenuIsOpen,
    setSearchIsOpen,
    setShowDropUp,
    setSlidePageActive,
  }
}
