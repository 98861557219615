const fromEntries = (base, toEntries = (arg) => arg) =>
  Object.fromEntries(
    Object.entries(base ?? {})
      .map(toEntries)
      .filter((entry) => entry.length === 2 && typeof entry[1] !== 'undefined'),
  )

const toArray = (base) => (Array.isArray(base) ? base : [base]).filter(Boolean)

const serializeData = (data) => ({
  ...(data?.attributes ? { id: data?.id ?? null, ...data.attributes } : data),
  ...fromEntries(data.relationships, ([key, value]) => [
    key,
    value.data?.id ?? null,
  ]),
})

const serializeRelationships = (included) =>
  included
    ? {
        id: included.id,
        type: included.type,
        ...included.attributes,
        ...fromEntries(included.relationships, ([relation, { data }]) => {
          const isArray = Array.isArray(data)
          const key = `${relation}Id`.concat(isArray ? 's' : '')
          return [
            key,
            isArray ? data.map((d) => d?.id).filter(Boolean) : data?.id,
          ]
        }),
      }
    : []

const includedSerializer = (resIncluded) => {
  const serializeIncluded = (resData) => {
    const includedFromRelationships = toArray(resData).reduce(
      (result, data) => {
        const relationships = Object.values(data.relationships ?? {}).reduce(
          (includedAcc, relation) => {
            if (typeof relation?.data !== 'object') return includedAcc

            const includedRelation = toArray(relation.data).reduce(
              (relationAcc, { id, type }) => {
                if (!type) return relationAcc

                const key = `${type}s`
                if (!id && key !== 's') return { [key]: relationAcc[key] ?? [] }

                const includedData = resIncluded.find(
                  (inc) => inc.id === id && inc.type === type,
                )

                return {
                  ...relationAcc,
                  ...(typeof includedData?.relationships === 'object' &&
                  // avoid serializing relationships already in includedData
                  !Object.values(includedData.relationships).some(
                    ({ data }) =>
                      (data?.id === includedData.id &&
                        data?.type === includedData.type) ||
                      // avoid serializing relationships already in resData
                      toArray(resData).some(
                        (d) => d?.id === data?.id && d?.type === data?.type,
                      ),
                  )
                    ? serializeIncluded(includedData)
                    : {}),
                  [key]: (relationAcc[key] ?? []).concat(
                    serializeRelationships(includedData),
                  ),
                }
              },
              {},
            )

            return {
              ...includedAcc,
              ...includedRelation,
            }
          },
          {},
        )

        return {
          ...result,
          ...fromEntries(relationships),
        }
      },
      {},
    )

    const allIncluded = toArray(resIncluded).reduce((result, include) => {
      const { id, type } = include ?? {}
      if (!id || !type) return result

      const key = `${type}s`
      if (
        toArray(result[key]).some((inc) => inc.id === id && inc.type === type)
      )
        return result

      const serialized = serializeRelationships(include)
      if (!result[key])
        return {
          ...result,
          [key]: [serialized],
        }

      return {
        ...result,
        [key]: Array.isArray(result[key])
          ? result[key].concat(serialized)
          : { ...result[key], ...serialized },
      }
    }, includedFromRelationships)

    return fromEntries(allIncluded, ([key, value]) => [
      key,
      value?.length === 1 ? value[0] : value,
    ])
  }

  return resIncluded ? serializeIncluded : () => []
}

export const formatResponseApi = (res) => {
  try {
    const serializeIncluded = includedSerializer(res?.included)
    if (res?.data && Array.isArray(res.data))
      return {
        data: res.data.map(serializeData),
        headers: res.headers,
        meta: res.meta,
        included: serializeIncluded(res.data),
      }
    if (res?.included)
      return {
        data: serializeData(res.data),
        headers: res.headers,
        included: serializeIncluded(res.data),
      }
    if (res?.data)
      return {
        data: serializeData(res.data),
        headers: res.headers,
      }

    return res
  } catch (err) {
    const [first] = toArray(res?.data)
    const msg = first?.type || first?.id ? [first.type, first.id, err] : [err]
    console.error('Error::serializer', ...msg)
    throw err
  }
}
