export type Notification = {
  duration: number
  id: number
  link?: { title: string; to?: string; fn?: () => void } | null
  message: string
  showCloseButton: boolean
  title: string
  toastClass?: string
  type: 'primary' | 'secondary'
}

type CreateNotification = {
  (options: Partial<Omit<Notification, 'id'>>): void
}

export default function useNotifications() {
  const notifications = useState<Notification[]>(
    'notifications-toast',
    () => [],
  )

  const defaultNotificationOptions = {
    duration: 5000,
    message: '',
    showCloseButton: true,
    title: '',
    type: 'primary',
  }

  const createNotification: CreateNotification = (options) => {
    const _options = Object.assign({ ...defaultNotificationOptions }, options)
    const id = new Date().getTime()

    notifications.value.push({ id, ..._options })
  }

  const removeNotifications = (id: number) => {
    notifications.value = notifications.value.filter((item) => item.id !== id)
  }

  return {
    notifications,
    createNotification,
    removeNotifications,
  }
}
