export default defineNuxtPlugin(async (nuxtApp) => {
  const { $api } = nuxtApp.vueApp.config.globalProperties
  const { login, logout } = useAuth()

  try {
    const uid = useCookie('uid')
    const client = useCookie('client')
    const accessToken = useCookie('access-token')
    if (!accessToken.value || !client.value || !uid.value)
      throw new Error('no credentials')

    $api.setHeaders({
      'access-token': accessToken.value,
      client: client.value,
      uid: uid.value,
    })
    const { data: user } = await $api.v1.auth.validateToken()
    if (user && user.incompleteSignup === false) await login(user)
  } catch {
    await logout()
  }
})
