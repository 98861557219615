import { UpdateInquiryRepository } from "./interfaces/UpdateInquiryRepository";
import { UpdateInquiryPresentation } from "./interfaces/UpdateInquiryPresentation";
import { UpdateInquiryInput } from "./types";

export default class UpdateInquiryUseCase {
  constructor(private repository: UpdateInquiryRepository) {}

  async execute(
    input: UpdateInquiryInput,
    presenter: UpdateInquiryPresentation
  ) {
    const response = await this.repository.updateInquiry(input);
    if (response?.data) {
      presenter.displayUpdateInquiry(response.data);
    } else {
      presenter.displayUpdateInquiryError(
        "houseDetails.inquiry.errorOnUpdateInquiry"
      );
    }
  }
}
