import { GetNbHiddenHousesPresentation } from "./interfaces/GetNbHiddenHousesPresentation";
import { SearchHousesWithDatesRepository } from "../SearchHouses/SearchHousesWithDates/interfaces/SearchHousesWithDatesRepository";
import { GetNbHiddenHousesInput } from "../../../../types";
import { Search } from "../../domain";
import { SearchType } from "../../../../services/algolia";

export default class GetNbHiddenHousesUseCase extends Search {
  constructor(private repository: SearchHousesWithDatesRepository) {
    super();
  }

  async execute(
    presenter: GetNbHiddenHousesPresentation,
    input: GetNbHiddenHousesInput,
    algoliaIds: SearchType
  ) {
    const { getHousePeriodsIds, getHousePeriods, algolia } = this.repository;
    const repository = {
      getHousePeriodsIds,
      getHousePeriods,
      algolia: algolia(algoliaIds),
    };
    const currency = input.currency || "EUR";
    const mode = "client";
    const deviceMode = "web";
    const maxBudget =
      input.prices && input.prices[1] ? String(input.prices[1]) : "1000000";
    const minBudget =
      input.prices && input.prices[0] ? String(input.prices[0]) : "0";
    const period = {
      startDate: input.period.startDate,
      endDate: input.period.endDate,
    };
    const periodHouseIds = await repository.getHousePeriodsIds({
      currency,
      destinationIds: input.destinationIds,
      endDate: period.endDate,
      startDate: period.startDate,
      states: "hidden",
      minBudget,
      maxBudget,
    });

    if (periodHouseIds.data) {
      const searchParams = this.getSearchParams(
        {
          bathrooms: input.bathrooms || "",
          bedrooms: input.bedrooms || "",
          capacity: input.capacity || "",
          currency,
          destinationIds: input.destinationIds || [],
          facetFilters: input.facetFilters || new Map(),
          housesIds: input.housesIds || [],
          housesPeriodIds: periodHouseIds.data,
          maxPrice: Number(maxBudget),
          minPrice: Number(minBudget),
          page: input.page || 0,
          period,
          query: "",
          replicaValue: input.replica || "recommended",
        },
        deviceMode,
        mode,
        input.locale
      );

      const hits = await this.getBrowseResponse({
        ...searchParams,
        mode,
        onlyHiddenHouses: true,
        repository,
      });

      presenter.displayNbHiddenHouses(hits.length);
    }
  }
}
