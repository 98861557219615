import type {
  ConditionsField,
  InfoTextTranslate,
  Rules,
} from '~/components/BaseForm/types'

type InputText = {
  inputType: 'input'
  conditions?: ConditionsField
  hidden?: boolean
  containerInputClass?: string
  viewModeRow?: boolean
  order?: number
  attr: {
    autocomplete?: 'email' | 'current-password' | 'new-password' | 'username'
    boundTo?: string
    cols?: number
    decimal?: boolean
    disabled: boolean
    disablePrefixInput?: boolean
    icon?: string
    iconWrapperClass?: string
    inputHtml?: string
    placeholderTranslateKey?: string
    inputmode?: 'numeric'
    labelHtml?: string
    labelTranslateKey: string
    max?: number
    min?: number
    name: string
    negativeNumber?: boolean
    pattern?: string
    placeholder?: string
    prefixInput?: string
    rows?: number
    rules?: Rules
    showErrorText?: boolean
    suffixInput?: string
    type?: 'email' | 'password' | 'number'
    wrapperClass?: string
  }
  infoText?: string
  infoTextTranslateKey?: string | InfoTextTranslate
}

const inputText: InputText = {
  inputType: 'input',
  attr: {
    disabled: false,
    labelTranslateKey: 'form.label',
    name: 'input',
  },
}

export default inputText
