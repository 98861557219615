<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    lineColor?: string
    modelValue?: boolean
  }>(),
  {
    lineColor: 'black',
    modelValue: false,
  },
)
defineEmits<{
  'update:model-value': [boolean]
}>()
const lineCount = ref(4)

const color = computed(() =>
  props.lineColor === 'black' ? 'bg-gray-700' : `bg-${props.lineColor}`,
)
</script>

<template>
  <base-button
    :aria-label="$t('global.close')"
    :class="['header-burger', { 'header-burger--open': modelValue }]"
    color="white"
    data-testid="the-header-burger"
    size="xs"
    @click="$emit('update:model-value', !modelValue)"
  >
    <span v-for="line in lineCount" :key="line" :class="color" />
  </base-button>
</template>

<style>
.header-burger {
  @apply flex w-6 relative h-5 transition-all duration-500 ease-in-out cursor-pointer;
}

.header-burger span {
  @apply block absolute h-px w-full rounded-sm left-0 transition-all duration-300 ease-in-out;
}

.header-burger span:nth-child(1) {
  top: 0;
}

.header-burger span:nth-child(2),
.header-burger span:nth-child(3) {
  top: 50%;
}

.header-burger span:nth-child(4) {
  top: 100%;
}

.header-burger.header-burger--open span:nth-child(1) {
  top: 50%;
  @apply w-0 left-1/2;
}

.header-burger.header-burger--open span:nth-child(2) {
  @apply transform rotate-45;
}

.header-burger.header-burger--open span:nth-child(3) {
  @apply transform -rotate-45;
}

.header-burger.header-burger--open span:nth-child(4) {
  top: 50%;
  @apply w-0 left-1/2;
}
</style>
