export default (clientHttp, apiVersion) => ({
  confirms(id, params) {
    return clientHttp.post(
      `${apiVersion}/user/owner_contracts/${id}/confirms`,
      params,
    )
  },
  read(contractId, query) {
    return clientHttp.get(
      `${apiVersion}/user/owner_contracts/${contractId}${query}`,
    )
  },
})
