import { dateMask } from '~/helpers/BaseForm/formMasks'
import { InputMask } from '~/components/BaseForm/core/primitives'

const BirthDate: typeof InputMask = {
  ...InputMask,
  attr: {
    ...InputMask.attr,
    inputmode: 'numeric',
    mask: dateMask,
    rules: 'required|dateFormat',
    labelTranslateKey: 'form.birthDate',
    name: 'birthdate',
    pattern: /\d{2}\/\d{2}\/\d{4}$/,
    placeholderTranslateKey: 'form.placeholder.date',
  },
  containerInputClass: 'w-full md:w-1/2 md:pr-10',
}

export default BirthDate
