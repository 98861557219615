import {
  ConfirmClientContractUseCase,
  GetClientContractUseCase,
  SendClientContractInformationUseCase,
  PrepareClientContractInformationUseCase,
} from "../usecases";
import { GetClientContractPresentation } from "../usecases/GetClientContract/interfaces/GetClientContractPresentation";
import { ConfirmClientContractPresentation } from "../usecases/ConfirmClientContract/interfaces/ConfirmClientContractPresentation";
import VerifyIdentityDocumentUseCase from "../usecases/VerifyIdentityDocument/VerifyIdentityDocumentUseCase";
import { VerifyIdentityDocumentPresentation } from "../usecases/VerifyIdentityDocument/interfaces/VerifyIdentityDocumentPresentation";
import { SendClientContractInformationPresentation } from "../usecases/SendClientContractInformation/interfaces/SendClientContractInformationPresentation";
import { ContactInformation } from "./ViewModel";
import { IdentityDocumentFile } from "../../../types";

export default class ClientContractController {
  constructor(
    private usecases: {
      getClientContract?: GetClientContractUseCase;
      confirmClientContract?: ConfirmClientContractUseCase;
      verifyIdentityDocument?: VerifyIdentityDocumentUseCase;
      sendClientContractInformation?: SendClientContractInformationUseCase;
      prepareClientContractInformation?: PrepareClientContractInformationUseCase;
    },
    private presenter: GetClientContractPresentation &
      ConfirmClientContractPresentation &
      VerifyIdentityDocumentPresentation &
      SendClientContractInformationPresentation
  ) {}

  async getClientContract(clientContractId: string) {
    await this.usecases.getClientContract?.execute(this.presenter, {
      clientContractId,
    });
  }

  async confirmClientContract(
    isConditionsChecked: boolean,
    clientContractId: string
  ) {
    await this.usecases.confirmClientContract?.execute(this.presenter, {
      isConditionsChecked,
      clientContractId,
    });
  }

  async verifyIdentityDocument({
    documentType,
    pickedDocuments,
    isDevMode,
  }: {
    documentType: "passport" | "identityCard";
    pickedDocuments: IdentityDocumentFile[];
    isDevMode?: boolean;
  }) {
    await this.usecases.verifyIdentityDocument?.execute(this.presenter, {
      pickedDocuments,
      documentType,
      isDevMode: isDevMode ?? false,
    });
  }

  async sendClientContractInformation(
    {
      clientContractId,
      information,
    }: {
      clientContractId: string;
      information: BodyInit;
    },
    options?: Record<string, unknown>
  ) {
    return await this.usecases.sendClientContractInformation?.execute(
      this.presenter,
      {
        clientContractId,
        information,
      },
      options
    );
  }

  prepareClientContractInformation({
    contactInformation,
  }: {
    contactInformation: ContactInformation;
  }) {
    return this.usecases.prepareClientContractInformation?.execute(
      this.presenter,
      { contactInformation }
    );
  }
}
