import type { HouseSlug, Locale } from 'lc-services/types'

export const getDestinationSlug = (searchUrl: HouseSlug) =>
  Object.entries(searchUrl).reduce(
    (acc, [key, url]) => {
      const locale = key as Locale
      const slugArr = url.split('/')
      const destination = slugArr[0]
      const name = slugArr[slugArr.length - 1]
      return {
        ...acc,
        [locale]: {
          name,
          destination,
          path: `/${locale}/${destination}/${name}`,
        },
      }
    },
    {} as Record<Locale, { name: string; destination: string; path: string }>,
  )
