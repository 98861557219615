<template>
  <button
    :aria-label="$t('global.close')"
    class="close-button"
    v-bind="$attrs"
    type="button"
  >
    <BaseIcon name="close" :size="1.5" />
  </button>
</template>

<style>
.close-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow:
    inset 0 0 1px 0 rgb(32 32 32 / 50%),
    0 2px 36px 0 rgb(32 32 32 / 10%);
  @apply cursor-pointer flex items-center justify-center;
}

.close-button__cross {
  @apply text-lg;
}
</style>
