export default defineNuxtRouteMiddleware((to) => {
  const nuxt = useNuxtApp()
  const { authenticated } = useAuth()
  const guardLayouts = ['account']

  const redirectLink = nuxt.$localePath({
    name: 'login',
    query: { return_to: to.fullPath },
  })

  // Protected layout from auth is authenticated
  if (!authenticated.value && guardLayouts.includes(to.meta.layout)) {
    return navigateTo(redirectLink)
  }
})
