import { DeleteUserHouseWishlistRepository } from "./interfaces/DeleteUserHouseWishlistRepository";
import { DeleteUserHouseWishlistPresentation } from "./interfaces/DeleteUserHouseWishlistPresentation";
// import { Wishlists } from "../../domain/Wishlists";

import type { DeleteHouseWishlistInput } from "../../../../types/common/wishlist";

export default class DeleteUserHouseWishlistUseCase {
  constructor(private repository: DeleteUserHouseWishlistRepository) {}

  async execute(
    presenter: DeleteUserHouseWishlistPresentation,
    input: DeleteHouseWishlistInput
  ) {
    const response = await this.repository.deleteHouseWishlist({
      houseId: input.houseId,
    });

    if (response.success) {
      presenter.displayHouseWishlistDeleted(input.houseId);
    }

    if (response?.errors) {
      presenter.displayHouseWishlistDeletedError("wishlistHouse.notFound");
    }
  }
}
