import { CountryCodes, Locale } from "types";
import {
  CurrenciesType,
  GetUserLocationResponseType,
  UserPropertiesType,
} from "types/common/userProperties";

export default class UserProperties {
  private userProperties: UserPropertiesType;

  constructor(
    locale: Locale,
    private locationDetails: GetUserLocationResponseType | undefined
  ) {
    const supportedCurrencies = ["EUR", "USD", "GBP", "CHF"];
    const countryFound =
      (this.locationDetails?.location?.countryCode as CountryCodes) ?? "FR";
    this.userProperties = {
      currency:
        this.locationDetails?.currency &&
        supportedCurrencies.includes(this.locationDetails.currency)
          ? (this.locationDetails?.currency as CurrenciesType)
          : "EUR",
      country: countryFound,
      surface: this.getSurface(countryFound, locale) ?? "m²",
    };
  }

  build() {
    return this.userProperties;
  }

  private getSurface(country: CountryCodes, locale: Locale) {
    switch (country) {
      case "FR":
        return locale === "en" ? "sqm" : "m²";
      case "US":
        return "sq.ft.";
      case "GB":
        return "sqm";
      case "CH":
        return "m²";
      default:
        locale === "en" ? "sqm" : "m²";
    }
  }
}
