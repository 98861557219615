import type { SupportedCurrencies } from 'lc-services/types'
import { locales } from '~/helpers/locales'

type CurrencyGroup = {
  code: SupportedCurrencies
  name: string
  symbol: string
}

export const currencies: CurrencyGroup[] = [
  {
    code: 'CHF',
    name: 'CHF',
    symbol: 'CHF',
  },
  {
    code: 'EUR',
    name: 'EUR - €',
    symbol: '€',
  },
  {
    code: 'USD',
    name: 'USD - $',
    symbol: '$',
  },
  {
    code: 'GBP',
    name: 'GBP - £',
    symbol: '£',
  },
]

const transformPrice = ({
  currency,
  hasDates,
  hit,
  i18n,
  isValidPeriod,
  userIsAdmin,
  userIsPartner,
}: {
  currency: SupportedCurrencies
  hasDates: boolean
  hit: any
  i18n: any
  isValidPeriod: boolean
  userIsAdmin: boolean
  userIsPartner: boolean
}) => {
  if (hasDates) {
    if (isValidPeriod) {
      const eurPublicPrice: number | null = hit?.publicPrice || null
      return {
        ...periodPriceText(i18n, hit, userIsAdmin, userIsPartner),
        eurPublicPrice,
        isValid: true,
      }
    } else {
      const text: string = i18n.t('house.price_upon_request')

      return {
        isValid: false,
        text,
        value: null,
      }
    }
  }

  const minPrice: number | null = hit?.min_week_price?.[currency] || null
  const maxPrice: number | null = hit?.max_week_price?.[currency] || null
  const text: string = weeklyPriceRangeText(
    i18n,
    minPrice,
    maxPrice,
    currency,
    hit.display_prices,
    userIsAdmin,
  )

  return {
    value: minPrice,
    text,
    isValid: false,
  }
}

const getPriceWithCurrency = ({
  price,
  currency,
  minimumFractionDigits = 0,
  i18n,
}: {
  price: number
  currency: SupportedCurrencies
  minimumFractionDigits?: number
  i18n: { locale: Ref<string> }
}) => {
  const iso = locales.find((i) => i.code === i18n.locale.value)?.iso
  let options = {}

  if (currency) {
    options = {
      style: 'currency',
      currency,
    }
  }

  const formattedPrice = price?.toLocaleString(iso, {
    ...options,
    minimumFractionDigits,
  })

  return formattedPrice
}

const periodPriceText = (
  i18n: any,
  hit: {
    display_prices?: number
    publicPrice: number
    currency: SupportedCurrencies
    startAt: string
    endAt: string
  },
  userIsAdmin: boolean,
  userIsPartner: boolean,
) => {
  const currency = hit.currency
  let priceValue: number | null = null
  let priceText: string = i18n.t('house.price_upon_request')

  if (hit.display_prices || userIsAdmin) {
    priceValue = hit.publicPrice
    priceText = getPriceWithCurrency({
      price: priceValue,
      currency,
      i18n,
    })
  }

  if (priceValue) {
    const date1 = new Date(hit.startAt)
    const date2 = new Date(hit.endAt)
    const diff = date2.getTime() - date1.getTime()
    const nbNights = diff / (1000 * 3600 * 24)
    const nbNightsText = i18n.t('search.nbNights', nbNights)

    priceText = `${priceText} - ${nbNightsText}`
    if (userIsPartner) {
      priceText = `${i18n.t('search.from')} ${priceText}`
    }
  }

  return {
    value: priceValue,
    text: priceText,
  }
}

const weeklyPriceRangeText = (
  i18n: any,
  min: number | null,
  max: number | null,
  currency: SupportedCurrencies,
  displayPrices: boolean,
  userIsAdmin = false,
) => {
  if (
    typeof min === 'number' &&
    typeof max === 'number' &&
    (displayPrices || userIsAdmin)
  ) {
    const minPrice = getPriceWithCurrency({ price: min, currency, i18n })
    const maxPrice = getPriceWithCurrency({ price: max, currency, i18n })

    if (min === max) {
      return i18n.t('global.pricePerWeek', {
        price: minPrice,
      })
    }

    return i18n.t('search.price_weekly_range', {
      min: minPrice,
      max: maxPrice,
    })
  }

  return i18n.t('house.price_upon_request')
}

const getCurrencySymbol = (code: SupportedCurrencies) => {
  return currencies.find((c) => c.code === code)?.symbol
}

const getOrdinal = (n: number, locale: string, suffixOnly = false) => {
  const suffixes: Record<number, string> = {
    1: locale === 'en' ? 'st' : 'er',
    2: locale === 'en' ? 'nd' : 'ème',
    3: locale === 'en' ? 'rd' : 'ème',
  }
  const suffix = locale === 'en' ? suffixes[n % 10] || 'th' : 'ème'

  return suffixOnly ? suffix : `${n}${suffix}`
}

export {
  getCurrencySymbol,
  getPriceWithCurrency,
  getOrdinal,
  periodPriceText,
  transformPrice,
  weeklyPriceRangeText,
}
