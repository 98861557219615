import { GetWishlistSharedRepository } from "./interfaces/GetWishlistSharedRepository";
import { GetWishlistSharedPresentation } from "./interfaces/GetWishlistSharedPresentation";
import { WishlistShared } from "../../domain/WishlistShared";

export default class GetWishlistSharedUseCase {
  constructor(private repository: GetWishlistSharedRepository) {}

  async execute(
    presenter: GetWishlistSharedPresentation,
    wishlistToken: string
  ) {
    const response = await this.repository.getWishlistShared({
      wishlistToken,
    });

    if (response?.data) {
      const wishlist = new WishlistShared(response.data).build();
      presenter.displayWishlistShared(wishlist);
    }
  }
}
