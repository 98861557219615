export default (clientHttp, apiVersion) => ({
  update(formData, id, isCompany) {
    return clientHttp.putFormData(
      `${apiVersion}/user/houses/${id}/contract_informations${isCompany}`,
      formData,
    )
  },
  read(id) {
    return clientHttp.get(
      `${apiVersion}/user/houses/${id}/contract_informations`,
    )
  },
})
