<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'
import type { CheckboxField } from '~/components/BaseForm/types'

const props = withDefaults(
  defineProps<{
    bgCheckboxClass?: string
    color?: 'primary' | 'secondary'
    customHtml?: boolean
    disabled?: boolean
    fields?: CheckboxField[]
    label?: string
    labelHtml?: string
    modelValue: boolean | unknown[]
    name: string
    rules?: string
    textCheckboxClass?: string
    vertical?: boolean
    wrapperClass?: string
  }>(),
  {
    bgCheckboxClass: '',
    color: 'primary',
    customHtml: false,
    disabled: false,
    fields: () => [],
    label: '',
    labelHtml: '',
    rules: '',
    textCheckboxClass: '',
    vertical: false,
    wrapperClass: 'w-full mb-4',
  },
)
const emits = defineEmits<{
  'update:model-value': [boolean | unknown[]]
}>()
defineSlots<{ label(): any; 'label-single'(): any }>()

const rules = toRef(props, 'rules')

const { value: inputValue } = useField(props.name, rules, {
  initialValue: props.modelValue,
})

watch(inputValue, () => {
  emits('update:model-value', inputValue.value)
})

watch(
  () => props.modelValue,
  (newVal) => {
    inputValue.value = newVal
  },
)

const multipleCheckbox = computed(() => {
  return Boolean(props.fields.length)
})

const formattedId = useId()
</script>

<template>
  <div :class="wrapperClass">
    <slot name="label">
      <label v-if="label && multipleCheckbox" class="base-form-label mb-2">
        <BaseFormComponentsBaseFormLabel
          :label="label"
          :label-html="labelHtml"
        />
        <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
      </label>
    </slot>
    <div
      v-if="multipleCheckbox"
      :id="formattedId"
      :class="{ 'base-checkbox-layout--vertical': vertical }"
    >
      <label
        v-for="checkbox in fields"
        :key="checkbox.label?.toString()"
        :class="[
          'base-checkbox-label',
          { 'base-checkbox-label--disabled': checkbox.disabled },
          { 'base-checkbox-label--vertical ': vertical },
        ]"
      >
        <input
          v-model="inputValue"
          :class="[
            'base-checkbox base-form-tick',
            checkbox.bgCheckboxClass ||
              (color === 'primary'
                ? 'base-checkbox-primary'
                : 'base-checkbox-secondary'),
          ]"
          :disabled="checkbox.disabled"
          :value="checkbox.value"
          data-testid="base-checkbox"
          :data-cy="name"
          type="checkbox"
        />
        <span
          :class="[
            { 'flex items-center': checkbox.icon },
            checkbox.textCheckboxClass,
          ]"
          data-testid="base-checkbox-text"
        >
          <BaseIcon v-if="checkbox.icon" :name="checkbox.icon" class="mr-1" />
          <span v-if="customHtml" v-html="checkbox.label" />
          <template v-else>{{ checkbox.label }}</template>
        </span>
        <span
          v-if="checkbox.countHits"
          :class="['pl-2', checkbox.textCheckboxClass]"
          data-testid="base-checkbox-count-hits"
        >
          ({{ checkbox.countHits }})
        </span>
      </label>
    </div>

    <label v-else class="base-checkbox-label">
      <input
        :id="formattedId"
        v-model="inputValue"
        :class="[
          'base-checkbox base-form-tick',
          bgCheckboxClass ||
            (color === 'primary'
              ? 'base-checkbox-primary'
              : 'base-checkbox-secondary'),
          ,
        ]"
        :disabled="disabled"
        :data-cy="name"
        data-testid="base-checkbox"
        type="checkbox"
      />
      <slot name="label-single">
        <span :class="textCheckboxClass" data-testid="base-checkbox-text">
          <span v-if="customHtml" v-html="label" />
          <template v-else>
            <BaseFormComponentsBaseFormLabel
              :label="label"
              :label-html="labelHtml"
            />
          </template>

          <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
        </span>
      </slot>
    </label>

    <error-message as="div" :name="name" class="base-form--error" />
  </div>
</template>

<style>
.base-form-tick:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-size: 120% 120%;
  background-position: 50%;
  background-repeat: no-repeat;
}
.base-checkbox-label {
  @apply cursor-pointer inline-flex justify-start items-center mr-7;
}
.base-checkbox {
  @apply appearance-none cursor-pointer mr-2 h-5 w-5 border rounded-sm focus:outline-none;
  min-width: 1.25rem;
  min-height: 1.25rem;
}
.base-checkbox-primary {
  @apply border-gray-300 checked:bg-primary-700 checked:border-primary-700;
}
.base-checkbox-secondary {
  @apply border-gray-300 checked:bg-secondary-400 checked:border-secondary-400;
}

.base-checkbox-label--disabled {
  @apply text-gray-500 cursor-not-allowed;
}

.base-checkbox:disabled {
  @apply bg-gray-200 cursor-not-allowed;
}

.base-checkbox:hover:disabled {
  @apply bg-gray-200;
}

.base-checkbox-label--vertical {
  @apply mb-2 mr-0;
}

.base-checkbox-layout--vertical {
  @apply flex flex-col;
}
</style>
