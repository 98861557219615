export default (clientHttp, apiVersion) => ({
  read(houseId, params) {
    return clientHttp.get(
      `${apiVersion}/houses/${houseId}/surrounding_information`,
      {
        ...params,
      },
    )
  },
})
