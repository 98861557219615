<script setup lang="ts">
withDefaults(
  defineProps<{
    modelValue?: boolean
    text?: {
      title: string
      subtitle: string
      button: string
    }
  }>(),
  {
    modelValue: false,
    text: () => ({
      title: 'inquiry.success.title',
      subtitle: 'global.valid_inquiry',
      button: 'inquiry.success.finish',
    }),
  },
)
const emits = defineEmits<{
  'update:model-value': [value: boolean]
  'on-finish': []
}>()
defineSlots<{ default(): any }>()

const onFinish = () => {
  emits('on-finish')
  emits('update:model-value', false)
}
</script>

<template>
  <div class="w-full">
    <div
      :class="[
        'base-success-form__validation-row -z-1',
        { 'is-visible': modelValue },
      ]"
    >
      <div class="base-success-form__validation">
        <h3 class="flex flex-col items-center justify-center text-xl">
          <BaseIcon class="mb-2" name="checkCircle" :size="2.3" />
          {{ $t(`${text.title}`) }}
        </h3>
        <p class="mb-0">
          {{ $t(`${text.subtitle}`) }}
        </p>

        <base-button
          color="secondary"
          class="mt-4 cursor-pointer"
          data-testid="baseSuccessFormButton"
          @click="onFinish"
        >
          {{ $t(`${text.button}`) }}
        </base-button>
      </div>
    </div>

    <div
      :class="['base-success-form__form lc-row', { 'is-hidden': modelValue }]"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped>
.base-success-form__form {
  @apply opacity-100 transition-opacity duration-300 ease-in delay-500;
}

.base-success-form__form.is-hidden {
  @apply opacity-0 transition-opacity duration-300 ease-in;
}

.base-success-form__validation-row {
  @apply absolute top-0 left-0 right-0 w-full h-full overflow-hidden;
}

.base-success-form__validation-row.is-visible {
  @apply z-10;
}

.base-success-form__validation {
  @apply absolute w-full text-center px-2 cursor-default opacity-0 translate-y-full top-1/2 transition-transform duration-500 ease-in;
}

@screen md {
  .base-success-form__validation {
    @apply max-w-[600px] transform left-1/2 -translate-y-1/2	translate-x-full;
  }
}

.contact-form__validation .contact-form__validation-button {
  @apply cursor-default;
}

.base-success-form__validation-row.is-visible .base-success-form__validation {
  @apply opacity-100 transform -translate-y-1/2 transition-transform duration-500 ease-in delay-300;
}

@screen md {
  .base-success-form__validation-row.is-visible .base-success-form__validation {
    @apply opacity-100 transform -translate-y-1/2 -translate-x-1/2;
  }
}
</style>
