import { SignUpRepository } from "./interfaces/SignUpRepository";
import { SignUpPresentation } from "./interfaces/SignUpPresentation";
import UserFormError from "../../../../services/UserFormError";
import { checkPhoneNumber } from "../../domain/helpers/checkPhoneNumber";
import { dispatch } from "../../domain";
import { PhoneNumberValidator, UserType } from "../../../../types";
import { CreateUserInput } from "./types";

export default class SignUpUseCase {
  constructor(
    private repository: SignUpRepository,
    private isValidPhoneNumber: PhoneNumberValidator
  ) {}

  async execute(
    presenter: SignUpPresentation,
    input: CreateUserInput,
    validateTokenResponse?: UserType
  ) {
    presenter.displayInitialize();

    const isValidPhoneNumber = checkPhoneNumber(
      this.isValidPhoneNumber,
      input.user.phone
    );

    const baseErrors = new UserFormError(input)
      .firstnameRequired()
      .lastnameRequired()
      .civilityRequired();

    if (input.user.phone) baseErrors.phoneWrongFormat(isValidPhoneNumber);
    const errors = baseErrors.build();
    if (!input?.user?.terms_of_service_signed)
      errors.push({
        fieldName: "termsAndConditions",
        error: "termsAndConditions.missing",
      });
    if (errors.length === 0) {
      const createdFromAdminTool =
        !validateTokenResponse?.termsOfServiceSigned &&
        !validateTokenResponse?.termsOfServiceSignedAt &&
        !validateTokenResponse?.incompleteSignup &&
        validateTokenResponse?.invitationCreatedAt;

      const response = createdFromAdminTool
        ? await this.repository.modifyUserInfo({
            user: {
              civility: input.user.civility,
              first_name: input.user.first_name,
              last_name: input.user.last_name,
              phone: input.user.phone,
              newsletter: input.user.newsletter,
              terms_of_service_signed: input.user.terms_of_service_signed,
            },
          })
        : await this.repository.finishSignUp(input);

      dispatch.signUp(response, presenter);
    } else {
      presenter.displayErrors(errors[0]);
    }
  }
}
