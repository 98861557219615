import type { ToRefs } from 'vue'
import type {
  CheckboxField,
  MultiselectOption,
  RadioOption,
  Field,
} from '~/components/BaseForm/types'

type State = {
  isCalendar: boolean
  isInputFile: boolean
  isSimpleCheckbox: boolean
  isSimpleChoice: boolean
  isMultipleChoices: boolean
  isTextEdit: boolean
  valueFormatted: string | string[]
}

export function useFieldReadMode(
  field: Ref<Field>,
  value: string | string[] | number | boolean,
): ToRefs<State> {
  const valueFormatted = computed<string | string[]>(() => {
    let valueF = '' as string | string[]

    // handle when multiple choices -> return array of string
    if (state.isMultipleChoices) {
      const fields = field.value.fields
      valueF =
        fields
          ?.filter((x: CheckboxField) =>
            String(value).includes(x.value.toString()),
          )
          .map((x) => x.label?.toString() || '') || []
    }
    // handle when simple choice ->  return string
    else if (state.isSimpleChoice) {
      const defaultOptions = field.value.defaultOptions || []
      const options = field.value.options || []
      const allOptions = defaultOptions.concat(options)

      const label = allOptions?.find(
        (x: MultiselectOption | RadioOption) =>
          String(x.value) === String(value),
      )?.label
      valueF = label?.toString() || '/'
    }
    //  number
    else if (typeof value === 'number') {
      valueF = String(value)
    }
    // other
    else if (typeof value !== 'boolean') {
      valueF = value ? String(value) : '/'
    }

    if (field.value.attr?.suffixInput && value) {
      valueF = `${valueF} ${field.value.attr.suffixInput}`
    }
    if (field.value.attr?.prefixInput && value) {
      valueF = `${field.value.attr.prefixInput} ${valueF}`
    }

    return valueF
  })

  const state = reactive({
    isCalendar: ['calendar'].includes(field.value.inputType),
    isInputFile: ['file-input'].includes(field.value.inputType),
    isSimpleCheckbox:
      ['checkbox', 'checkbox-toggle'].includes(field.value.inputType) &&
      !Array.isArray(value),
    isSimpleChoice: [
      'radio-group',
      'multiselect',
      'multiselect-search',
    ].includes(field.value.inputType),
    isMultipleChoices:
      ['checkbox', 'multiselect'].includes(field.value.inputType) &&
      Array.isArray(value),
    isTextEdit: ['textarea', 'wysiwyg'].includes(field.value.inputType),
    valueFormatted,
  })

  return toRefs(state)
}
