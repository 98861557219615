import { UserPropertiesVM } from "./ViewModel";
import { GetUserPropertiesByLocationPresentation } from "../usecases/GetUserPropertiesByLocation/interfaces/GetUserPropertiesByLocationPresentation";
import { UserPropertiesType } from "types/common/userProperties";
import Presenter from "../../../abstracts/Presenter";

export default class UserPropertiesPresenter
  extends Presenter<UserPropertiesVM>
  implements GetUserPropertiesByLocationPresentation
{
  constructor() {
    super(new UserPropertiesVM());
  }

  displayMessage(msg: string) {
    this.vm.msg = msg;
    this.notifyVM();
  }

  displayUserLocaleProperties(userProperties: UserPropertiesType): void {
    this.vm.userProperties = userProperties;
  }
}
