import {
  CreateUserWishlistUseCase,
  DeleteUserHouseWishlistUseCase,
  DeleteUserWishlistUseCase,
  GetUserWishlistsUseCase,
  GetUserWishlistUseCase,
  GetUserWishlistHouseAvailablesUseCase,
  GetWishlistSharedUseCase,
  MakeRequestWishlistUseCase,
  ModifyRequestWishlistUseCase,
  ModifyUserWishlistUseCase,
} from "../usecases";
import { CreateUserWishlistPresentation } from "../usecases/CreateUserWishlist/interfaces/CreateUserWishlistPresentation";
import { DeleteUserHouseWishlistPresentation } from "../usecases/DeleteUserHouseWishlist/interfaces/DeleteUserHouseWishlistPresentation";
import { DeleteUserWishlistPresentation } from "../usecases/DeleteUserWishlist/interfaces/DeleteUserWishlistPresentation";
import { GetUserWishlistPresentation } from "../usecases/GetUserWishlist/interfaces/GetUserWishlistPresentation";
import { GetUserWishlistsPresentation } from "../usecases/GetUserWishlists/interfaces/GetUserWishlistsPresentation";
import { GetUserWishlistHouseAvailablesPresentation } from "../usecases/GetUserWishlistHouseAvailables/interfaces/GetUserWishlistHouseAvailablesPresentation";
import { GetWishlistSharedPresentation } from "../usecases/GetWishlistShared/interfaces/GetWishlistSharedPresentation";
import { MakeRequestWishlistPresentation } from "../usecases/MakeRequestWishlist/interfaces/MakeRequestWishlistPresentation";
import { ModifyRequestWishlistPresentation } from "../usecases/ModifyRequestWishlist/interfaces/ModifyRequestWishlistPresentation";
import { ModifyUserWishlistPresentation } from "../usecases/ModifyUserWishlist/interfaces/ModifyUserWishlistPresentation";

import type {
  CreateWishlistInput,
  DeleteHouseWishlistInput,
  DeleteWishlistInput,
  MakeRequestWishlistInput,
  ModifyRequestWishlistInput,
  ModifyWishlistInput,
  GetUserWishlistHouseAvailablesInput,
} from "../../../types/common/wishlist";

type Usecases = {
  createUserWishlist: CreateUserWishlistUseCase;
  deleteUserHouseWishlist: DeleteUserHouseWishlistUseCase;
  deleteUserWishlist: DeleteUserWishlistUseCase;
  getUserWishlist: GetUserWishlistUseCase;
  getUserWishlists: GetUserWishlistsUseCase;
  getUserWishlistHouseAvailables: GetUserWishlistHouseAvailablesUseCase;
  getWishlistShared: GetWishlistSharedUseCase;
  makeRequestWishlist: MakeRequestWishlistUseCase;
  modifyRequestWishlist: ModifyRequestWishlistUseCase;
  modifyUserWishlist: ModifyUserWishlistUseCase;
};

type Presenter = CreateUserWishlistPresentation &
  DeleteUserHouseWishlistPresentation &
  DeleteUserWishlistPresentation &
  GetUserWishlistPresentation &
  GetUserWishlistsPresentation &
  GetUserWishlistHouseAvailablesPresentation &
  GetWishlistSharedPresentation &
  MakeRequestWishlistPresentation &
  ModifyRequestWishlistPresentation &
  ModifyUserWishlistPresentation;

export default class WishlistController {
  constructor(
    private usecases: Partial<Usecases>,
    private presenter: Presenter
  ) {}

  async createUserWishlist(input: CreateWishlistInput) {
    await this.usecases.createUserWishlist?.execute(this.presenter, input);
  }

  async deleteUserWishlist(input: DeleteWishlistInput) {
    await this.usecases.deleteUserWishlist?.execute(this.presenter, input);
  }

  async deleteUserHouseWishlist(input: DeleteHouseWishlistInput) {
    await this.usecases.deleteUserHouseWishlist?.execute(this.presenter, input);
  }

  async getUserWishlists() {
    await this.usecases.getUserWishlists?.execute(this.presenter);
  }

  async getUserWishlist(wishlistToken: string) {
    await this.usecases.getUserWishlist?.execute(this.presenter, wishlistToken);
  }

  async modifyUserWishlist(input: ModifyWishlistInput) {
    await this.usecases.modifyUserWishlist?.execute(this.presenter, input);
  }

  async getUserWishlistHouseAvailables(
    input: GetUserWishlistHouseAvailablesInput
  ) {
    await this.usecases.getUserWishlistHouseAvailables?.execute(
      this.presenter,
      input
    );
  }

  async getWishlistShared(wishlistToken: string) {
    await this.usecases.getWishlistShared?.execute(
      this.presenter,
      wishlistToken
    );
  }

  async makeRequestWishlist(input: MakeRequestWishlistInput) {
    await this.usecases.makeRequestWishlist?.execute(this.presenter, input);
  }

  async modifyRequestWishlist(input: ModifyRequestWishlistInput) {
    await this.usecases.modifyRequestWishlist?.execute(this.presenter, input);
  }
}
