import { WishlistAvailabilities, HouseAvailable } from "../../../types";

export class WishlistHouseAvailables {
  private houseAvailables: HouseAvailable[] = [];

  constructor(
    private wishlistAvailabilitiesRepository:
      | WishlistAvailabilities[]
      | WishlistAvailabilities
  ) {
    if (Array.isArray(this.wishlistAvailabilitiesRepository)) {
      this.houseAvailables = this.wishlistAvailabilitiesRepository.map(
        (wishlistAvailibility) => ({
          id: Number(wishlistAvailibility.id),
          price: wishlistAvailibility.price,
        })
      );
    } else if (this.wishlistAvailabilitiesRepository.id) {
      this.houseAvailables = [
        {
          id: Number(this.wishlistAvailabilitiesRepository.id),
          price: this.wishlistAvailabilitiesRepository.price,
        },
      ];
    }
  }

  build() {
    return this.houseAvailables;
  }
}
