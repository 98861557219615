import { GetHousePeriodsRepository } from "./interfaces/GetHousePeriodsRepository";
import { GetHousePeriodsPresentation } from "./interfaces/GetHousePeriodsPresentation";
import { HousePeriods } from "../../domain/HousePeriods";
import { SupportedCurrencies } from "../../../../types";

export default class GetHousePeriodsUseCase {
  constructor(private repository: GetHousePeriodsRepository) {}

  async execute(
    presenter: GetHousePeriodsPresentation,
    houseId: string,
    period: { startDate: string; endDate: string },
    currency?: SupportedCurrencies,
    destinationIds: string[] = []
  ) {
    const response = await this.repository.getHousePeriods({
      currency,
      destinationIds,
      endDate: period.endDate,
      houseIds: [houseId],
      startDate: period.startDate,
    });

    if (response.data) {
      const housePeriodsFormatted = new HousePeriods(
        response.data[0],
        houseId
      ).build();

      presenter.displayHousePeriods(housePeriodsFormatted);
    }
  }
}
