<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string
    link?: string | null
    setupStyle?: string
  }>(),
  {
    link: '',
    setupStyle: '',
  },
)

const components = reactive({
  NuxtLink: {
    componentName: shallowRef(resolveComponent('NuxtLink')),
  },
  span: {
    componentName: shallowRef(h('span')),
  },
})

const type = computed(() => (props.link ? 'NuxtLink' : 'span'))
</script>

<template>
  <component
    :is="components[type].componentName"
    :class="[
      'text-md leading-5 text-gray-700 no-underline hover:text-gray-700 hover:no-underline',
      {
        'inline-block font-bold': setupStyle === 'bold',
        'font-monospace text-lg-bold flex items-center justify-between py-6 text-2xl lg:inline-block lg:py-0':
          setupStyle === 'title',
      },
    ]"
    :to="link"
  >
    {{ name }}
  </component>
</template>
