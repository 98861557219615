import {
  DestinationIncludedType,
  GetDestinationResponse,
  HouseDetailsResponse,
  HouseIncluded,
  HouseKeyFeatures,
} from "../usecases/GetHouseDetails/types";
import { KeyFeatures } from "./HouseKeyFeatures";
import {
  HouseDetailsAttributes,
  HouseSlug,
  Locale,
  NearbyAttributes,
  NearbyAttributesFormatted,
  SettingAttributes,
  SurroundingInformationAttributes,
} from "../../../types";

export class HouseDetails extends KeyFeatures {
  private houseDetailsFormatted: HouseDetailsAttributes & { houseUrl: string };

  constructor(
    protected houseResponse: HouseDetailsResponse,
    protected houseInclude: HouseIncluded,
    protected locale: Locale,
    protected destinationResponse: GetDestinationResponse,
    protected houseKeyFeaturesResponse?: HouseKeyFeatures
  ) {
    super(houseResponse, houseInclude);

    const includedAreas = this.houseInclude?.areas || [];
    const includedBrands = this.houseInclude?.brands || [];
    const includedCodes = this.houseInclude?.codes || [];

    this.houseDetailsFormatted = {
      ...houseResponse,
      areas: this.houseIncludedElementFormatted(includedAreas),
      areaGroups: this.houseInclude.areaGroups,
      bathrooms: this.houseResponse.bathrooms,
      bedrooms: this.houseResponse.bedrooms,
      brands: this.houseIncludedElementFormatted(includedBrands),
      capacity: this.houseResponse.capacity,
      codes: this.houseIncludedElementFormatted(includedCodes),
      conciergeServiceOffer: this.houseResponse.conciergeServiceOffer,
      coolingFloorSystem: this.houseResponse.coolingFloorSystem,
      coordinates: {
        latitude: parseFloat(this.houseResponse.gpslatitude),
        longitude: parseFloat(this.houseResponse.gpslongitude),
      },
      destinationId: this.houseResponse.destinationId,
      description: this.houseResponse.description,
      destination: this.formatDestination(this.destinationResponse),
      destinationName: this.houseInclude.destinations.clusterizedName,
      equipments: this.houseIncludedElementFormatted(
        this.houseInclude.equipments
      ),
      equipmentsCount: this.houseKeyFeaturesResponse?.equipments_count || 0,
      equipmentTypes: this.houseIncludedElementFormatted(
        this.houseInclude.equipmentTypes
      ),
      exclusivity: this.houseResponse.exclusivity,
      floorHeatingSystem: this.houseResponse.floorHeatingSystem,
      houseUrl: this.createHouseUrl(this.houseResponse.slug, this.locale),
      id: this.houseResponse.id,
      includedServices: this.houseIncludedElementFormatted(
        this.houseInclude.includedServices
      ),
      keyFeatures: this.houseKeyFeaturesResponse?.featured || [],
      licenceNumber: this.houseResponse.licenceNumber,
      matterportUrl: this.houseResponse.matterportUrl,
      onlineReservation: this.houseResponse.onlineReservation,
      minPrice: this.houseResponse.minPrice,
      maxPrice: this.houseResponse.maxPrice,
      name: this.houseResponse.name,
      nearby: this.getEssentialNearbyValues(
        this.houseInclude.surroundingInformations
      ),
      firstPhoto: this.houseInclude.photos,
      pools: this.houseInclude.pools,
      setting: this.getEssentialSettingValues(
        this.houseInclude.surroundingInformations
      ),
      slug: this.houseResponse.slug,
      surface: this.houseResponse.surface,
      surroundingInformations: this.houseInclude.surroundingInformations,
      stayInformation: this.houseInclude.stayInformations,
    };
  }

  private createHouseUrl(slug: HouseSlug, locale: Locale) {
    const rentalParam = {
      fr: "location-luxe",
      en: "luxury-rental",
    };

    return `${locale}/${rentalParam[locale]}/${slug[locale]}?from-app=true`;
  }

  protected houseIncludedElementFormatted(
    houseIncludeElement: object | object[]
  ) {
    return Array.isArray(houseIncludeElement)
      ? houseIncludeElement
      : [houseIncludeElement];
  }

  private getEssentialNearbyValues(
    surroundingInformations: SurroundingInformationAttributes
  ) {
    const surroundingInformationsformatter: { [key: string]: string[] } = {
      beach: ["beachAccessibility", "beachAccessibilityTimeInMinutes"],
      downtown: ["downtownAccessibility", "downtownAccessibilityTimeInMinutes"],
      golf: ["golfAccessibility", "golfAccessibilityTimeInMinutes"],
      restaurantsAndBars: [
        "restaurantsAndBarsAccessibility",
        "restaurantsAndBarsAccessibilityTimeInMinutes",
      ],
      shops: ["shopsAccessibility", "shopsAccessibilityTimeInMinutes"],
      skiTrail: ["skiTrailAccessibility", "skiTrailAccessibilityTimeInMinutes"],
    };

    const surroundingInformationsformatted: {
      [key: string]: (string | number | boolean | null)[];
    } = {};

    Object.keys(surroundingInformationsformatter).forEach(
      (surroundingInformationKey: string) => {
        const shouldCheck =
          surroundingInformations[
            surroundingInformationKey as keyof NearbyAttributes
          ];
        if (!shouldCheck) {
          return;
        }
        const keysToCheck =
          surroundingInformationsformatter[surroundingInformationKey];

        surroundingInformationsformatted[surroundingInformationKey] = [];
        keysToCheck.forEach((ktc) => {
          surroundingInformationsformatted[surroundingInformationKey].push(
            surroundingInformations[ktc as keyof NearbyAttributes]
          );
        });
      }
    );
    return surroundingInformationsformatted as NearbyAttributesFormatted;
  }

  private getEssentialSettingValues(
    surroundingInformations: SurroundingInformationAttributes
  ) {
    const settingKeys = [
      "locatedInCity",
      "neighborhood",
      "natureReserve",
      "surroundedByFields",
      "privateBeach",
      "privateDomain",
    ];

    return settingKeys.reduce(
      (acc: Record<string, boolean | null>, key: string) => {
        if (
          Object.prototype.hasOwnProperty.call(surroundingInformations, key)
        ) {
          acc[key] = surroundingInformations[key as keyof SettingAttributes];
        }
        return acc;
      },
      {}
    );
  }

  private formatDestination(desti: GetDestinationResponse) {
    let destinations: DestinationIncludedType[] = [];

    if (typeof desti.included?.destinations === "object") {
      destinations.push(desti.included.destinations as DestinationIncludedType);
    }

    if (Array.isArray(desti.included?.destinations)) {
      destinations = [
        ...(desti.included
          ?.destinations as unknown as DestinationIncludedType[]),
      ];
    }

    const country = destinations.find(
      (el) => typeof el.clusterId === "undefined"
    );
    const cluster = destinations.find((el) => el.clusterId !== undefined);

    return {
      destinationUrl: desti?.data?.searchUrl,
      name: desti?.data?.name,
      timezone: desti?.data?.timezone,
      ...(country
        ? {
            country: {
              countryId: country.countryId,
              searchUrl: country.searchUrl,
              name: country.name,
              slug: country.slug,
              timezone: country.timezone,
            },
          }
        : {}),
      ...(cluster
        ? {
            cluster: {
              countryId: cluster.countryId,
              clusterId: cluster.clusterId as string,
              searchUrl: cluster.searchUrl,
              name: cluster.name,
              slug: cluster.slug,
              timezone: cluster.timezone,
            },
          }
        : {}),
    };
  }

  build() {
    return this.houseDetailsFormatted;
  }
}
