import type {
  ConditionsField,
  Options,
  Rules,
} from '~/components/BaseForm/types'

type InputSelect = {
  inputType: 'multiselect'
  infoText?: string
  containerInputClass?: string
  conditions?: ConditionsField
  hidden?: boolean
  options: Options
  viewModeRow?: boolean
  order?: number
  attr: {
    canClear: boolean
    canDeselect: boolean
    disabled?: boolean
    height?: number
    inputHtml?: string
    labelHtml?: string
    labelTextTranslateKey?: string
    mode?: 'tags' | 'multiple'
    name: string
    placeholderTranslateKey: string
    rules?: Rules
    searchable: boolean
    showErrorText?: boolean
    trackBy: string
    wrapperClass?: string
  }
}

const inputSelect: InputSelect = {
  inputType: 'multiselect',
  options: [],
  attr: {
    canClear: false,
    canDeselect: false,
    placeholderTranslateKey: 'global.placeholders.select',
    trackBy: 'label',
    name: 'select',
    searchable: false,
  },
}

export default inputSelect
