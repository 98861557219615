import { Locale } from "types";
import { GetUserPropertiesByLocationUseCase } from "../usecases";
import { GetUserPropertiesByLocationPresentation } from "../usecases/GetUserPropertiesByLocation/interfaces/GetUserPropertiesByLocationPresentation";

type Usecases = {
  getUserPropertiesByLocation: GetUserPropertiesByLocationUseCase;
};

type Presenter = GetUserPropertiesByLocationPresentation;

export default class UserController {
  constructor(
    private usecases: Partial<Usecases>,
    private presenter: Presenter
  ) {}

  async getUserPropertiesByLocation(input: { locale: Locale }) {
    await this.usecases.getUserPropertiesByLocation?.execute(
      this.presenter,
      input
    );
  }
}
