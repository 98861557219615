import { LoginWithProvidersRepository } from "./interfaces/LoginWithProvidersRepository";
import { LoginWithProvidersProps } from "./types";
import { MobileAppLoginAddon } from "../../domain/MobileAppLoginAddon";
import { LoginWithProvidersPresentation } from "./interfaces/LoginWithProvidersPresenter";

type LoginWithProvidersUseCaseProps = {
  presenter: LoginWithProvidersPresentation;
  mobileProps?: LoginWithProvidersProps;
  webProps?: unknown; // TODO: Implement web behavior
};

export default class LoginWithProvidersUseCase extends MobileAppLoginAddon {
  constructor(repository: LoginWithProvidersRepository) {
    super(repository);
  }

  async execute({ presenter, mobileProps }: LoginWithProvidersUseCaseProps) {
    presenter.displayInitialize();

    if (mobileProps) {
      await this.loginOnMobileAppAsync(presenter, mobileProps);
    }
  }
}
