import { InputSelect } from '~/components/BaseForm/core/primitives'

const Language: typeof InputSelect = {
  ...InputSelect,
  attr: {
    ...InputSelect.attr,
    rules: 'required',
    labelTextTranslateKey: 'form.language',
    name: 'language',
  },
  containerInputClass: 'w-full md:w-1/2',
  options: [
    { labelTranslateKey: 'global.language.en', value: 'en' },
    { labelTranslateKey: 'global.language.fr', value: 'fr' },
  ],
}

export default Language
