import type { Locale } from 'lc-services/types'

export const useSeoLinks = () => {
  const config = useRuntimeConfig()
  const nuxt = useNuxtApp()
  const { $i18n } = nuxt
  const route = useRoute()

  const { getBaseURL } = useURL()
  const { getNewUrl } = useSetRouteParams()

  const linksAlternate = (domainUrl: string) => {
    const links = []
    $i18n.locales.value.forEach((l) => {
      links.push({
        rel: 'alternate',
        href: () => `${domainUrl}${getNewUrl(l.code as Locale)}`,
        hreflang: l.code,
      })
    })

    links.push({
      rel: 'alternate',
      href: () => `${domainUrl}${getNewUrl('en')}`,
      hreflang: 'x-default',
    })

    return links
  }

  const domainUrl = computed(() => getBaseURL())

  const linksCanonicalAndAlternate = computed(() => {
    return [
      {
        rel: 'canonical',
        href: () => `${domainUrl.value}${route.path}`,
      },
      ...linksAlternate(domainUrl.value),
    ]
  })

  const linksCanonicalAndAlternatePrivatevillapreview = computed(() => {
    return [
      {
        rel: 'canonical',
        href: () => `${config.public.whiteBrandUrl}${route.path}`,
      },
      ...linksAlternate(config.public.whiteBrandUrl),
    ]
  })

  return {
    getNewUrl,
    linksCanonicalAndAlternate,
    linksCanonicalAndAlternatePrivatevillapreview,
  }
}
