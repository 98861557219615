import { IdentityDocumentInformation } from "../usecases/VerifyIdentityDocument/interfaces/VerifyIdentityDocumentPresentation";
import { ApiError, ClientContractFormatted, ToastInfo } from "../../../types";

export interface ContactInformation extends IdentityDocumentInformation {
  type: "individual" | "company" | null;
  phone: string | null;
  email: string | null;
  address: string | null;
  postalCode: string | null;
  city: string | null;
  country: string | null;
  companyName: string | null;
  companyNumber: string | null; // rename to companyIdentificationNumber
}
export class ClientContractVM {
  errors: { fieldName: string; error: string }[] = [];
  loading = false;
  msg = "";
  navigation: string | null = null;
  clientContract: ClientContractFormatted | null = null;
  clientContractError: ApiError | undefined = undefined;
  toast: ToastInfo | null = null;
  contactInformation: ContactInformation = {
    type: null,
    civility: null,
    firstname: null,
    lastname: null,
    birthdate: null,
    identityDocumentType: null,
    identityDocumentExpirationDate: null,
    identityDocumentNumber: null,
    nationality: null,
    phone: null,
    address: null,
    postalCode: null,
    city: null,
    country: null,
    companyName: null,
    companyNumber: null,
    email: null,
    identityDocumentFiles: [],
  };
}
