<script setup lang="ts">
const { closeDropdown } = useHeaderDesktop()

defineSlots<{ 'content-left'(): any; 'content-right'(): any }>()
</script>

<template>
  <div class="dropdown-template">
    <div class="col-span-8 pl-20">
      <slot name="content-left" />
    </div>
    <div class="col-span-4">
      <slot name="content-right" />
    </div>

    <TheHeaderCloseButton
      aria-controls="header-dropdown"
      @close="closeDropdown"
    />
  </div>
</template>

<style scoped>
.dropdown-template {
  height: 38rem;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 0%),
    linear-gradient(-72deg, rgba(32, 32, 32, 1) 32%, rgba(32, 32, 32, 0) 0%);
  grid-template-rows: [main] 5fr [footer] 1fr;
  @apply grid grid-cols-12 w-full;
}
</style>
