import { PrepareClientContractInformationPresentation } from "./interfaces/PrepareClientContractInformationPresentation";
import { ContactInformation } from "../../adapters/ViewModel";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  ClientContractCompanyInformation,
  ClientContractIndividualInformation,
  ErrorsForm,
} from "../../../../types";

const requiredFieldNames: (keyof ContactInformation)[] = [
  "type",
  "phone",
  "email",
  "address",
  "postalCode",
  "city",
  "country",
  "civility",
  "firstname",
  "lastname",
  "birthdate",
  "nationality",
];

const companyRequiredFieldNames: (keyof ContactInformation)[] = [
  ...requiredFieldNames,
  "companyName",
  "companyNumber",
];

export default class PrepareClientContractInformationUseCase {
  private getFormErrors(contactInformation: ContactInformation): ErrorsForm {
    const errors = (
      contactInformation.type === "individual"
        ? requiredFieldNames
        : companyRequiredFieldNames
    )
      .map((fieldName) =>
        contactInformation[fieldName] === null ||
        contactInformation[fieldName] === ""
          ? {
              fieldName,
              error: "missing",
            }
          : null
      )
      .filter(
        (
          error
        ): error is {
          fieldName: keyof ContactInformation;
          error: string;
        } => error !== null
      );

    if (
      contactInformation.phone &&
      !isValidPhoneNumber(contactInformation.phone)
    ) {
      errors.push({
        fieldName: "phone",
        error: "phone.phoneFormatHasNotCorrect",
      });
    }

    return errors;
  }

  private getIndividualBodyInformation(
    contactInformation: ContactInformation
  ): ClientContractIndividualInformation {
    if (contactInformation.type === "individual") {
      if (
        !contactInformation.civility ||
        !contactInformation.address ||
        !contactInformation.firstname ||
        !contactInformation.city ||
        !contactInformation.country ||
        !contactInformation.postalCode ||
        !contactInformation.phone ||
        !contactInformation.nationality ||
        !contactInformation.lastname ||
        !contactInformation.email ||
        !contactInformation.birthdate
      ) {
        throw new Error("Missing contact information fields");
      }
      return {
        context: "individual",
        client_address: contactInformation.address,
        client_city: contactInformation.city,
        client_postal_code: contactInformation.postalCode,
        client_country: contactInformation.country,
        client_civility: contactInformation.civility,
        client_firstname: contactInformation.firstname,
        client_lastname: contactInformation.lastname,
        client_email: contactInformation.email,
        client_birthdate: contactInformation.birthdate,
        client_identity_document_type: contactInformation.identityDocumentType,
        client_identity_document_number:
          contactInformation.identityDocumentNumber,
        client_identity_document_expiration_date:
          contactInformation.identityDocumentExpirationDate,
        client_identity_document_files:
          contactInformation.identityDocumentFiles,
        client_nationality: contactInformation.nationality,
        client_phone: contactInformation.phone,
      };
    }

    throw new Error("Not individual contact information type");
  }

  private getCompanyBodyInformation(
    contactInformation: ContactInformation
  ): ClientContractCompanyInformation {
    if (contactInformation.type === "company") {
      if (
        !contactInformation.civility ||
        !contactInformation.address ||
        !contactInformation.firstname ||
        !contactInformation.city ||
        !contactInformation.country ||
        !contactInformation.postalCode ||
        !contactInformation.phone ||
        !contactInformation.nationality ||
        !contactInformation.companyName ||
        !contactInformation.companyNumber ||
        !contactInformation.lastname ||
        !contactInformation.email ||
        !contactInformation.birthdate
      ) {
        throw new Error("Missing contact information fields");
      }

      return {
        context: "company",
        client_company_address: contactInformation.address,
        client_company_city: contactInformation.city,
        client_company_postal_code: contactInformation.postalCode,
        client_company_country: contactInformation.country,
        client_company_representative_civility: contactInformation.civility,
        client_company_representative_firstname: contactInformation.firstname,
        client_company_representative_lastname: contactInformation.lastname,
        client_company_email: contactInformation.email,
        client_company_representative_birthdate: contactInformation.birthdate,
        client_company_representative_identity_document_type:
          contactInformation.identityDocumentType,
        client_company_representative_identity_document_number:
          contactInformation.identityDocumentNumber,
        client_company_representative_identity_document_expiration_date:
          contactInformation.identityDocumentExpirationDate,
        client_identity_document_files:
          contactInformation.identityDocumentFiles,
        client_company_representative_nationality:
          contactInformation.nationality,
        client_company_phone: contactInformation.phone,
        client_company_name: contactInformation.companyName,
        client_company_siret: contactInformation.companyNumber,
      };
    }

    throw new Error("Not company contact information type");
  }

  execute(
    presenter: PrepareClientContractInformationPresentation,
    { contactInformation }: { contactInformation: ContactInformation }
  ):
    | ClientContractIndividualInformation
    | ClientContractCompanyInformation
    | { errors: ErrorsForm } {
    try {
      if (
        contactInformation.phone &&
        !isValidPhoneNumber(contactInformation.phone)
      ) {
        throw new Error("Invalid phone number");
      }
      if (contactInformation.type === "individual") {
        return this.getIndividualBodyInformation(contactInformation);
      }

      if (contactInformation.type === "company") {
        return this.getCompanyBodyInformation(contactInformation);
      }

      throw new Error("Missing contact information type");
    } catch (err) {
      const errors = this.getFormErrors(contactInformation);

      if (errors.length === 0) {
        presenter.displayToast({
          type: "error",
          msg: "clientContract.clientContractEditInformationForm.toast.unknown",
        });
      }

      return { errors };
    }
  }
}
