import Presenter from "../../../abstracts/Presenter";
import { SearchVM } from "./ViewModel";
import {
  HitResultType,
  SearchHousesAndDestinationsPresentation,
} from "../usecases/SearchHousesAndDestinations/interfaces/SearchHousesAndDestinationsPresentation";
import { GetHousePeriodsPresentation } from "../usecases/GetHousePeriods/interfaces/GetHousePeriodsPresentation";
import { GetTopDestinationsPresentation } from "../usecases/GetTopDestinations/interfaces/GetTopDestinationsPresentation";
import { SearchHousesPresentation } from "../usecases/SearchHouses/interfaces/SearchHousesPresentation";
import { GetHouseDetailsPresentation } from "../usecases/GetHouseDetails/interfaces/GetHouseDetailsPresentation";
import { OrderRetrievedFacetsPresentation } from "../usecases/OrderRetrievedFacets/interfaces/OrderRetrievedFacetsPresentation";
import { HousePeriodsResponseDataFormatted } from "../usecases/GetHousePeriods/types";
import { MakeHouseRequestPresentation } from "../usecases/MakeHouseRequest/interfaces/MakeHouseRequestPresentation";
import { GetHousePeriodsAndBookingsPresentation } from "../usecases/GetHousePeriodsAndBookings/interfaces/GetHousePeriodsAndBookingsPresentation";
import { GetHouseTruthLinksPresentation } from "../usecases/GetHouseTruthLinks/interfaces/GetHouseTruthLinksPresentation";
import {
  BookingsPeriods,
  PeriodDurationInquiry,
  FacetsRetrieve,
  SearchHouse,
  CoordinatesBoundaries,
  HouseDetailsAttributes,
  HousePhotosAttributesFormatted,
  HousesCoordinates,
  HouseIncludedService,
  HouseTruthLinks,
} from "../../../types";
import { MakeHouseRequestResponseData } from "../usecases/MakeHouseRequest/types";
import { UpdateInquiryPresentation } from "../usecases/UpdateInquiry/interfaces/UpdateInquiryPresentation";
import { UpdateInquiryResponseData } from "../usecases/UpdateInquiry/types";
import { GetNbHiddenHousesPresentation } from "../usecases/GetNbHiddenHouses/interfaces/GetNbHiddenHousesPresentation";
import { SearchHousesMapPresentation } from "../usecases/SearchHousesMap/interfaces/SearchHousesMapPresentation";
import { GetHouseKeyFeaturesPresentation } from "../usecases/GetHouseKeyFeatures/interfaces/GetHouseKeyFeaturesPresentation";
import { GetHouseIncludedServicesPresentation } from "../usecases/GetHouseIncludedServices/interfaces/GetHouseIncludedServicesPresentation";
import {
  HouseAreaEquipment,
  HouseKeyFeatures,
} from "../usecases/GetHouseDetails/types";
import { GetAreaEquipmentsPresentation } from "../usecases/GetAreaEquipments/interfaces/GetAreaEquipmentsPresentation";
import { ApiError } from "lc-repository/dist/repositories/types";
import { HousePeriodsIdResponseData } from "lc-repository/dist/repositories/lc/v3/houses/periods";

export default class SearchPresenter
  extends Presenter<SearchVM>
  implements
    GetHouseDetailsPresentation,
    GetTopDestinationsPresentation,
    OrderRetrievedFacetsPresentation,
    SearchHousesAndDestinationsPresentation,
    SearchHousesPresentation,
    GetHousePeriodsPresentation,
    MakeHouseRequestPresentation,
    GetHousePeriodsAndBookingsPresentation,
    UpdateInquiryPresentation,
    GetNbHiddenHousesPresentation,
    SearchHousesMapPresentation,
    GetHouseKeyFeaturesPresentation,
    GetAreaEquipmentsPresentation,
    GetHouseIncludedServicesPresentation,
    GetHouseTruthLinksPresentation
{
  constructor() {
    super(new SearchVM());
  }

  displayError(error: ApiError): void {
    this.vm.error = error;
    this.notifyVM();
  }

  displayHouseAreaEquipments(houseAreaEquipments: HouseAreaEquipment[]): void {
    this.vm.houseAreaEquipments = houseAreaEquipments;
    this.notifyVM();
  }

  displayHouseKeyFeatures(houseKeyFeatures: HouseKeyFeatures): void {
    this.vm.houseKeyFeatures = houseKeyFeatures;
    this.notifyVM();
  }

  displayNbHiddenHouses(nb: number): void {
    this.vm.nbHiddenHouses = nb;
    this.notifyVM();
  }

  displayUpdateInquiry(updatedInquiry: UpdateInquiryResponseData): void {
    this.vm.updatedInquiry = updatedInquiry;
    this.notifyVM();
  }

  displayUpdateInquiryError(error: string): void {
    this.vm.inquiryError = error;
    this.notifyVM();
  }

  displayPeriodDurations(periodsDurations?: PeriodDurationInquiry[]): void {
    this.vm.periodsDurations = periodsDurations;
    this.notifyVM();
  }

  displayBookings(bookings?: BookingsPeriods): void {
    this.vm.bookings = bookings;
    this.notifyVM();
  }

  displayHousePhotos(housePhotos: HousePhotosAttributesFormatted | null): void {
    this.vm.housePhotos = housePhotos;
    this.notifyVM();
  }

  displayHouseDetails(
    houseDetails: HouseDetailsAttributes & { houseUrl: string }
  ): void {
    this.vm.houseDetails = houseDetails;
    this.notifyVM();
  }

  displayHousePeriods(housePeriods: HousePeriodsResponseDataFormatted): void {
    this.vm.housePeriods = housePeriods;
    this.notifyVM();
  }

  displaySearchCurrentPage(nb: number): void {
    this.vm.searchCurrentPage = nb;
    this.notifyVM();
  }

  displayFacets(facets: FacetsRetrieve): void {
    this.vm.facets = facets;
    this.notifyVM();
  }

  displayOrderedFacets(orderedFacets: FacetsRetrieve): void {
    this.vm.orderedFacets = orderedFacets;
    this.notifyVM();
  }

  displaySearchHousesResult(result: SearchHouse[]): void {
    this.vm.searchHousesResult = result;
    this.notifyVM();
  }

  displayAlgoliaQueryId(queryId?: string): void {
    this.vm.algoliaQueryId = queryId;
    this.notifyVM();
  }

  displayNbHouses(nb: number): void {
    this.vm.nbHouses = nb;
    this.notifyVM();
  }

  displayHousesCoordinates(coordinates: HousesCoordinates | undefined): void {
    this.vm.houseCoordinates = coordinates;
    this.notifyVM();
  }

  displayCoordinatesBoundaries(
    coordinatesBoundaries: CoordinatesBoundaries | undefined
  ): void {
    this.vm.coordinatesBoundaries = coordinatesBoundaries;
    this.notifyVM();
  }

  displayNbPages(nb: number): void {
    this.vm.nbPages = nb;
    this.notifyVM();
  }

  displayTopDestinationsResult(results: HitResultType): void {
    this.vm.topDestinationsResult = results;
    this.notifyVM();
  }

  displayHousesResult(results: HitResultType): void {
    this.vm.housesResult = results;
    this.notifyVM();
  }

  displayDestinationsResult(results: HitResultType): void {
    this.vm.destinationsResult = results;
    this.notifyVM();
  }

  displayMessage(msg: string) {
    this.vm.msg = msg;
    this.notifyVM();
  }

  displayMakeHouseRequest(houseRequest: MakeHouseRequestResponseData): void {
    this.vm.houseRequest = houseRequest;
    this.notifyVM();
  }

  displayHouseRequestError(error: string): void {
    this.vm.houseRequestError = error;
    this.notifyVM();
  }

  displayHousePeriodsById(
    housePeriodsById: HousePeriodsIdResponseData | null
  ): void {
    this.vm.housePeriodsById = housePeriodsById;
    this.notifyVM();
  }

  displayHouseIncludedServices(
    houseIncludedServices: HouseIncludedService[]
  ): void {
    this.vm.houseIncludedServices = houseIncludedServices;
    this.notifyVM();
  }

  displayHouseTruthLinks(
    houseTruthLinks: Pick<HouseTruthLinks, "description">[]
  ): void {
    this.vm.houseTruthLinks = houseTruthLinks;
    this.notifyVM();
  }
}
