import { createGtm, type VueGtmUseOptions } from '@gtm-support/vue-gtm'
import { uuid } from 'vue-uuid'

import type { MetaAncestries } from '~/types/types'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const router = useRouter()
  const { $i18n } = nuxtApp as any
  const hash = uuid.v4()
  const { authenticated, authUserIdTracking } = useAuth()
  const { currency, userType } = useUser()

  const pluginOptions: VueGtmUseOptions = {
    id: config.public.googleTagManagerCode,
    compatibility: false,
    source: 'https://load.conversion.lecollectionist.com/apzvvqdl.js',
    debug: process.env.NODE_ENV === 'development',
    defer: false,
    trackViewEventProperty: 'page_view',
    vueRouterAdditionalEventData: (route) => {
      const ancestries = route.meta?.ancestries as MetaAncestries | undefined

      return {
        cluster_id: ancestries?.clusterId,
        cluster_name: ancestries?.clusterName,
        country_id: ancestries?.countryId,
        country_name: ancestries?.countryName,
        currency: currency.value,
        destination_id: ancestries?.destinationId,
        destination_name: ancestries?.destinationName,
        locale: $i18n.locale.value,
        logged_in: authenticated.value,
        page_type: (route?.meta?.destinationPage as Record<string, boolean>)
          ?.isLpInspi
          ? 'inspiration'
          : 'other',
        user_lc_id: authUserIdTracking.value,
        user_type: userType.value,
      }
    },
    enabled:
      userType.value !== 'admin' && process.env.NODE_ENV === 'production',
    loadScript: true,
    nonce: hash,
    vueRouter: router,
    trackOnNextTick: false,
  }

  nuxtApp.vueApp.use(createGtm(pluginOptions))
})
