import type Cookies from 'universal-cookie/es6/Cookies'
import { HttpService } from './http-service'

import * as v1 from './apiV1'
import * as v2 from './apiV2'
import * as v3 from './apiV3'

export const setNewClientApi = ({
  baseURL,
  showSsrLog = false,
  cookies,
  fetcher,
}: {
  baseURL: string
  showSsrLog?: boolean
  cookies: Cookies
  fetcher: typeof useCustomFetch
}) => {
  const clientApiV1 = v1.default
  const clientApiV2 = v2.default
  const clientApiV3 = v3.default
  const services = {
    ...clientApiV1,
    ...clientApiV2,
    ...clientApiV3,
  }
  type ApiService<V extends keyof typeof services> = {
    [S in keyof (typeof services)[V]]: (typeof services)[V][S] extends (
      ...args: any[]
    ) => infer R
      ? R
      : never
  }

  const h = new HttpService({
    cookies,
    baseURL,
    showSsrLog,
    fetcher,
  })

  const clientApi = Object.entries(services).reduce(
    (acc, [version, api]) => ({
      ...acc,
      [version]: Object.entries(api).reduce(
        (acc, [service, factory]) => ({
          ...acc,
          [service]: factory(h, version),
        }),
        {},
      ),
    }),
    {
      v1: {} as ApiService<'v1'>,
      v2: {} as ApiService<'v2'>,
      v3: {} as ApiService<'v3'>,
    },
  )

  return { clientApi, setHeaders: h.setHeaders }
}

export type ClientApi = ReturnType<typeof setNewClientApi>['clientApi']
