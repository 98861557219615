import { OrderRetrievedFacetsPresentation } from "./interfaces/OrderRetrievedFacetsPresentation";
import { FacetsBuild } from "../../domain";
import { Locale, FacetsRetrieve, Mode } from "../../../../types";

export default class FormatRetrievedFacetsUseCase {
  execute(
    presenter: OrderRetrievedFacetsPresentation,
    retrievedFacets: FacetsRetrieve,
    locale: Locale,
    mode: Mode
  ) {
    const orderedFacets = new FacetsBuild(
      retrievedFacets,
      locale,
      mode
    ).build();

    presenter.displayOrderedFacets(orderedFacets);
  }
}
