import { GetUserPropertiesByLocationRepository } from "./interfaces/GetUserPropertiesByLocationRepository";
import { GetUserPropertiesByLocationPresentation } from "./interfaces/GetUserPropertiesByLocationPresentation";
import { Locale } from "types";
import UserProperties from "../../domain/UserProperties";

export default class GetUserPropertiesByLocationUseCase {
  constructor(private repository: GetUserPropertiesByLocationRepository) {}

  async execute(
    presenter: GetUserPropertiesByLocationPresentation,
    input: { locale: Locale }
  ) {
    const country = await this.repository.getUserLocation();
    const userProperties = new UserProperties(input.locale, country).build();

    presenter.displayUserLocaleProperties(userProperties);
  }
}
