import type {
  ConditionsField,
  InfoTextTranslate,
  Rules,
} from '~/components/BaseForm/types'

type InputTextarea = {
  inputType: 'textarea'
  containerInputClass?: string
  conditions?: ConditionsField
  hidden?: boolean
  viewModeRow: boolean
  infoText?: string
  infoTextTranslateKey?: string | InfoTextTranslate
  order?: number
  attr: {
    cols?: number
    inputHtml?: string
    labelHtml?: string
    labelTranslateKey: string
    name: string
    placeholderTranslateKey?: string
    placeholder?: string
    rows?: number
    rules?: Rules
    wrapperClass?: string
  }
}

const inputTextarea: InputTextarea = {
  inputType: 'textarea',
  viewModeRow: true,
  attr: {
    name: 'textarea',
    labelTranslateKey: 'form.label',
  },
}

export default inputTextarea
