<script setup lang="ts">
import type { Rules } from '../types'

withDefaults(
  defineProps<{
    rules?: Rules
  }>(),
  {
    rules: '',
  },
)

const isRequired = computed(() => false)
</script>

<template>
  <span v-if="isRequired" data-testid="base-form-label-required" class="ml-1"
    >*</span
  >
</template>
