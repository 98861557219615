import { InputFile } from '~/components/BaseForm/core/primitives'

const Rib: typeof InputFile = {
  ...InputFile,
  attr: {
    ...InputFile.attr,
    accept: 'image/*, .pdf',
    rules: '',
    labelTranslateKey: 'form.rib',
    name: 'rib',
  },
  containerInputClass: 'w-full md:w-1/2',
}

export default Rib
