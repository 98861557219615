import { createQueryV3 } from '~/repository/config/createQueryV3'

export default (clientHttp, apiVersion) => ({
  create(params) {
    return clientHttp.post(`${apiVersion}/user/automatic_proposals`, params)
  },
  update(id, params) {
    return clientHttp.put(
      `${apiVersion}/user/automatic_proposals/${id}`,
      params,
    )
  },
  read(includes = []) {
    const query = includes?.length ? createQueryV3({ includes }) : ''

    return clientHttp.get(`${apiVersion}/user/automatic_proposals${query}`)
  },
  delete(id) {
    return clientHttp.delete(`${apiVersion}/user/automatic_proposals/${id}`)
  },
})
