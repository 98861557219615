const phoneByLanguage = ({ config, language }) => {
  let result

  switch (language) {
    case 'BE':
      result = config.public.collectionistPhoneBe
      break
    case 'CH':
      result = config.public.collectionistPhoneCh
      break
    case 'EN':
      result = config.public.collectionistPhoneFr
      break
    case 'FR':
      result = config.public.collectionistPhoneFr
      break
    case 'GB':
      result = config.public.collectionistPhoneGb
      break
    case 'UK':
      result = config.public.collectionistPhoneGb
      break
    case 'US':
      result = config.public.collectionistPhoneUs
      break
    default:
      result = config.public.collectionistPhoneFr
  }

  return result
}

export { phoneByLanguage }
