<script setup lang="ts">
import type { UserVariant } from '~/types/amplitude/types'

const props = withDefaults(
  defineProps<{
    flagKey?: string
  }>(),
  {
    flagKey: '',
  },
)

defineSlots<{ control(): any; treatment(): any }>()

const userVariant = useState<UserVariant>('user-variant')
const version = computed(
  () =>
    (userVariant.value[props.flagKey] ?? 'control') as 'control' | 'treatment',
)
</script>

<template>
  <slot v-if="version === 'treatment'" name="treatment" />
  <slot v-else name="control" />
</template>
