import { InputText } from '~/components/BaseForm/core/primitives'

const Lastname: typeof InputText = {
  ...InputText,
  attr: {
    ...InputText.attr,
    labelTranslateKey: 'form.lastName',
    name: 'lastName',
    rules: 'required',
  },
}

export default Lastname
