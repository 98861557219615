import { InputText } from '~/components/BaseForm/core/primitives'

const Firstname: typeof InputText = {
  ...InputText,
  attr: {
    ...InputText.attr,
    rules: 'required',
    labelTranslateKey: 'form.firstName',
    name: 'firstName',
  },
}

export default Firstname
