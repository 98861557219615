<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    infoText?: string
    label?: string
    labelHtml?: string
    modelValue?: string
    name: string
    placeholder?: string
    rules?: string
    wrapperClass?: string
  }>(),
  {
    infoText: '',
    label: '',
    labelHtml: '',
    modelValue: '',
    placeholder: '',
    rules: '',
    wrapperClass: 'w-full mb-4',
  },
)

const emit = defineEmits<{
  'focus-out': []
  'update:model-value': [string]
}>()

defineSlots<{ label(): any }>()

const rules = toRef(props, 'rules')

const {
  value: inputValue,
  handleBlur,
  handleChange,
  errors,
} = useField<string>(props.name, rules, {
  initialValue: props.modelValue,
})

const isError = computed(() => Boolean(errors.value.length))

const onInput = (event: Event): void => {
  const target = event.target as HTMLInputElement

  handleChange(event)
  emit('update:model-value', target.value)
}

const onBlur = (): void => {
  handleBlur()
  emit('focus-out')
}

const computedClass = computed((): string[] => {
  const computedClass = ['base-textarea']

  if (isError.value) {
    computedClass.push('base-form--hasError')
  }

  return computedClass
})

watch(
  () => props.modelValue,
  (value) => {
    inputValue.value = value
  },
)
</script>

<template>
  <div :class="wrapperClass">
    <slot name="label">
      <label v-if="label" :for="name" class="base-form-label">
        <BaseFormComponentsBaseFormLabel
          :label="label"
          :label-html="labelHtml"
        />
        <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
      </label>
    </slot>

    <textarea
      :id="name"
      :ref="name"
      :class="computedClass"
      :name="name"
      :placeholder="placeholder"
      :value="inputValue"
      v-bind="$attrs"
      @blur="onBlur"
      @change="handleChange"
      @input="onInput"
    />

    <error-message :name="name" as="span" class="base-form--error" />
    <BaseFormComponentsBaseFieldInfo v-if="infoText" :text="$t(infoText)" />
  </div>
</template>

<style>
.base-textarea {
  @apply block rounded-sm bg-clip-padding w-full border border-gray-300 text-gray-500 font-normal text-md leading-normal p-2 bg-white resize-none overflow-y-scroll focus:bg-white focus:border-primary-100 focus:shadow-focus focus:outline-none;
}
</style>
