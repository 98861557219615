type Subscriber<T> = (vm: T) => void;

export default abstract class Presenter<T> {
  protected subscriber: Subscriber<T> | undefined;

  constructor(public vm: T) {}

  abstract displayMessage(msg: string): void;

  protected notifyVM() {
    this.subscriber?.call(this.subscriber, this.vm);
  }

  protected diffVM(vmValue: unknown, newValue: unknown) {
    if (vmValue !== newValue) {
      return true;
    }

    return false;
  }

  //TODO: to test
  protected setVM(vmValue: unknown, newValue: unknown) {
    if (this.diffVM(vmValue, newValue)) {
      vmValue = newValue;
      this.notifyVM();
    }
  }

  subscribeVM(subscriber: Subscriber<T>) {
    this.subscriber = subscriber;
    this.subscriber(this.vm);
  }
}
