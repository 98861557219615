import { useStorage } from '@vueuse/core'

export default defineNuxtRouteMiddleware((to) => {
  if (
    'query' in to &&
    'utm_source' in to.query &&
    'utm_medium' in to.query &&
    'utm_campaign' in to.query &&
    'gclid' in to.query
  ) {
    const source = to.query.utm_source
    const medium = to.query.utm_medium
    const campaign = to.query.utm_campaign
    const gclid = to.query.gclid

    useStorage('utm', {
      source,
      medium,
      campaign,
      gclid,
    })
  }
})
