import { getFormData, getFile } from './getFormData'
import type {
  BaseFormApiError,
  BaseFormMetaOptions,
  ErrorApiResponse,
  ErrorApiScope,
  ErrorsCustomMessage,
  MutationType,
} from '~/components/BaseForm/types'

type MutateType = {
  metaOptions: BaseFormMetaOptions
  mutation: MutationType
  values: Record<string, unknown>
}

type MutateResponseType = {
  errors?: ErrorsCustomMessage
  request: () => Promise<unknown>
}

export type SendMutationType<T = unknown> = {
  activeErrorScrollTo: boolean
  form: HTMLElement | null
  handleErrors: (
    keypayload: string[],
    errorsApi: ErrorApiScope[],
    errorsCustom: ErrorsCustomMessage,
  ) => BaseFormApiError[]
  metaOptions: BaseFormMetaOptions
  mutation: MutationType
  onSubmit: {
    success?: (
      response: T,
      resetData: () => void,
      resetForm: () => void,
    ) => void
    error?: (
      error: { type: string; errors: ErrorApiResponse },
      setFieldError: (
        field: string,
        message: string | string[] | undefined,
      ) => void,
    ) => void
  }
  resetForm: () => void
  refUdapter: () => void
  resetData: () => void
  scrollToError: (refForm: HTMLElement, errors: BaseFormApiError[]) => void
  setFieldError: (field: string, message: string | string[] | undefined) => void
  values: Record<string, unknown>
}

type ErrorManagerType = Omit<
  SendMutationType & {
    errorApi: ErrorApiScope[] | undefined
    errorsCustom: ErrorsCustomMessage
  },
  'mutation' | 'metaOptions' | 'onSubmit' | 'refUdapter' | 'resetData'
>

const errorManager = ({
  activeErrorScrollTo,
  errorApi,
  errorsCustom,
  handleErrors,
  form,
  scrollToError,
  setFieldError,
  values,
}: ErrorManagerType): void => {
  if (Array.isArray(errorApi) && typeof errorApi[0] !== 'string') {
    const keysPayloadAuthorized = Object.keys(values)
    const errors = handleErrors(keysPayloadAuthorized, errorApi, errorsCustom)

    errors.forEach((error: unknown) => {
      const err = error as { fieldName: string; message: string }
      setFieldError(err.fieldName, err.message)
    })

    if (errors.length && activeErrorScrollTo && form) {
      scrollToError(form, errors)
    }
  }
}

const mutate = ({
  mutation,
  values,
  metaOptions,
}: MutateType): MutateResponseType => {
  const { request, input, errors } = mutation(
    values,
    getFormData,
    getFile,
    metaOptions,
  )

  return {
    errors,
    request: () => (input ? request(input) : request({})),
  }
}

export const sendMutation = async ({
  activeErrorScrollTo,
  form,
  handleErrors,
  metaOptions,
  mutation,
  onSubmit,
  refUdapter,
  resetData,
  resetForm,
  scrollToError,
  setFieldError,
  values,
}: SendMutationType): Promise<void> => {
  try {
    const response = await mutate({ mutation, values, metaOptions }).request()
    refUdapter()

    if (onSubmit?.success) {
      return onSubmit.success(response, resetData, resetForm)
    }
  } catch (error) {
    const catchedError = error as ErrorApiResponse
    const errorApi = catchedError?.response?.data?.errors

    errorManager({
      activeErrorScrollTo,
      errorApi,
      errorsCustom: mutate({ mutation, values, metaOptions })
        .errors as ErrorsCustomMessage,
      form,
      handleErrors,
      resetForm,
      scrollToError,
      setFieldError,
      values,
    })

    if (onSubmit?.error) {
      return onSubmit.error(
        { type: 'api', errors: error as ErrorApiResponse },
        setFieldError,
      )
    }
  }
}
