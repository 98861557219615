<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue?: boolean
  }>(),
  {
    modelValue: false,
  },
)
const emits = defineEmits<{
  'update:model-value': [boolean]
}>()

const localePath = useLocalePath()
const { trackEvent } = useTracking()
const { headerMobile, setMenuIsOpen, setSearchIsOpen } = useHeaderMobile()
const { headerTheme, setTemporaryTheme } = useHeaderNavigation()

const menuMobileIsOpen = computed(() => headerMobile.value.menuIsOpen)
const searchIsOpen = computed(() => headerMobile.value.searchIsOpen)
const hasWhiteColorCondition = computed(
  () => !menuMobileIsOpen.value && headerTheme.value === 'transparent',
)
const colorIcon = computed(() =>
  hasWhiteColorCondition.value ? 'white' : 'black',
)
const colorIconSearchAndWishlist = computed(() =>
  hasWhiteColorCondition.value ? 'white' : 'secondary-black',
)

const toggleNav = () => {
  emits('update:model-value', !props.modelValue)
}

const toggleSearch = () => {
  setSearchIsOpen(!searchIsOpen.value)
  setTemporaryTheme('white')

  trackEvent({
    event: 'header_clicked',
    item: 'search',
  })

  if (menuMobileIsOpen.value && searchIsOpen.value) {
    setMenuIsOpen(false)
  }

  if (!searchIsOpen.value && headerTheme.value === 'white') {
    setTemporaryTheme('')
  }
}

const handleClickPhone = () => {
  trackEvent({
    event: 'phone_number_click',
    label: { section: 'header' },
    source: 'header',
  })
}

const handleClickWishlist = () => {
  trackEvent({
    event: 'header_clicked',
    item: 'wishlist',
  })
}
</script>

<template>
  <nav
    class="navigation-icons flex items-center"
    :aria-label="$t('header.secondaryNavigation')"
  >
    <TheHeaderPhone
      class="hidden tablet:block"
      data-testid="navigation-icons-mobile-link-phone"
    >
      <span class="navigation-icons-mobile__phone-text">
        {{ $t('header.callUs') }}
      </span>
      <BasePhoneNumber
        :color="headerTheme === 'transparent' ? 'primary-light' : 'primary'"
        @click="handleClickPhone"
      />
    </TheHeaderPhone>

    <BaseButton
      :aria-label="$t('header.search')"
      :color="colorIconSearchAndWishlist"
      class="ml-10"
      data-testid="navigation-icons-mobile-link-search"
      variant="link"
      @click="toggleSearch"
    >
      <BaseIcon name="search" :stroke-width="searchIsOpen ? 1.5 : 1" />
    </BaseButton>

    <ABContainer flag-key="wishlist-summary">
      <template #control>
        <BaseButton
          :aria-label="$t('header.wishlist')"
          :color="colorIconSearchAndWishlist"
          :to="localePath('wishlist')"
          class="ml-3"
          data-cy="header-wishlist"
          data-testid="navigation-icons-desktop-link-wishlist"
          variant="link"
          @click="handleClickWishlist"
        >
          <BaseIcon name="heart" :stroke-width="1" />
        </BaseButton>
      </template>

      <template #treatment>
        <TheHeaderButtonWishlist class="ml-3" />
      </template>
    </ABContainer>

    <TheHeaderMobileBurger
      :model-value="modelValue"
      :line-color="colorIcon"
      class="ml-3"
      @update:model-value="toggleNav"
    />
  </nav>
</template>

<style>
.navigation-icons .nuxt-link-active .icn {
  @apply font-bold;
}

.navigation-icons-mobile__phone-text {
  color: var(--header-text-color);
}
</style>
