export default (clientHttp, apiVersion) => ({
  /**
   * @return {PromiseLike<{data: { id: string; destination: string; user: string }[] }>}
   */
  async read() {
    return clientHttp.get(`${apiVersion}/user/waiting_searches`)
  },

  delete(alertId) {
    return clientHttp.delete(`${apiVersion}/user/waiting_searches/${alertId}`)
  },
})
