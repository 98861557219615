import { GetHouseDetailsRepository } from "./interfaces/GetHouseDetailsRepository";
import { GetHouseDetailsPresentation } from "./interfaces/GetHouseDetailsPresentation";
import { HouseDetails } from "../../domain/HouseDetails";
import { Locale } from "../../../../types";
import { HouseKeyFeatures } from "./types";

export default class GetHouseDetailsUseCase {
  constructor(private repository: GetHouseDetailsRepository) {}

  async execute({
    presenter,
    houseId,
    locale,
    withKeyfeatures = false,
    withCodes = false,
    privateToken,
  }: {
    presenter: GetHouseDetailsPresentation;
    houseId: string;
    locale: Locale;
    withKeyfeatures?: boolean;
    withCodes?: boolean;
    privateToken?: string;
  }) {
    const includeCodes = withCodes ? ["codes"] : [];

    try {
      const houseDetailsresponse = await this.repository.getHouseById({
        houseId,
        included: {
          include: [
            ...includeCodes,
            "area_groups",
            "areas",
            "brands",
            "destination",
            "equipments",
            "equipment_types",
            "included_services",
            "pools",
            "surrounding_information",
            "first_photo",
            "stay_information",
          ],
          fields: {
            destination: ["clusterizedName"],
          },
        },
        privateToken,
      });

      if (houseDetailsresponse.data && houseDetailsresponse.included) {
        const houseKeyFeaturesResponse = withKeyfeatures
          ? await this.repository.getHouseKeyFeatures({ houseId, privateToken })
          : undefined;

        const newHouseKey = houseKeyFeaturesResponse?.data
          ? houseKeyFeaturesResponse?.data
          : (houseKeyFeaturesResponse as HouseKeyFeatures);

        const destinationResponse = await this.repository.getDestinationById({
          destinationId: houseDetailsresponse?.data?.destinationId,
          include: {
            include: ["country", "cluster"],
            fields: {
              country: ["name", "slug", "searchUrl"],
              cluster: ["name", "slug", "searchUrl"],
            },
          },
        });

        const houseDetailsFormatted = new HouseDetails(
          houseDetailsresponse.data,
          houseDetailsresponse.included,
          locale,
          destinationResponse,
          newHouseKey
        ).build();

        presenter.displayHouseDetails(houseDetailsFormatted);
      }

      if (houseDetailsresponse.errors)
        presenter.displayError(houseDetailsresponse.errors);
    } catch (err) {
      console.log("GetHouseDetailsUseCase", err);
    }
  }
}
