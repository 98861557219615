/**
 * Remove suffix lang route from nuxt-router
 * @param {String} routeName
 * @return {String} return a formatted name e.g. owners___fr => owners
 */

const formatRoute = (routeName) => {
  const routeSuffixRegex = /___(en|fr)/
  const formattedRoute = routeName?.replace(routeSuffixRegex, '')

  return formattedRoute
}

export { formatRoute }
