<script setup lang="ts">
import { stringToHtmlElm } from '~/utils/BaseForm'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    strHtml?: string
  }>(),
  {
    strHtml: '',
  },
)

const components = reactive<Record<string, { componentName: string }>>({
  text: {
    componentName: 'span',
  },
  link: {
    componentName: 'a',
  },
  routerLink: {
    componentName: 'router-link',
  },
})

const arrHtml = stringToHtmlElm(props.strHtml)

const translateText = (text: string) => t(text)
const toIconPath = (val: string) => val as IconPath
</script>

<template>
  <template v-if="arrHtml.length">
    <template v-for="elm in arrHtml" :key="`${elm}-${Date.now()}`">
      <BaseIcon
        v-if="elm.type === 'icon'"
        :data-testid="elm.type"
        :name="toIconPath(elm.value)"
        v-bind="elm.attrs"
      />
      <component
        :is="components[elm.type].componentName"
        v-else
        :data-testid="elm.type"
        :name="elm.value"
        v-bind="elm.attrs"
      >
        <template v-if="elm.type !== 'icon'" />
        {{ translateText(elm.value) }}
      </component>
    </template>
  </template>
</template>
