// API V3
import automaticProposals from '~/repository/services/v3/automaticProposals'
import clientContracts from '~/repository/services/v3/clientContracts'
import clientContractsReview from '~/repository/services/v3/clientContracts/review'
import contacts from '~/repository/services/v3/contacts'
import destination from '~/repository/services/v3/destinations/index'
import eventInquiries from '~/repository/services/v3/eventInquiries'
import house from '~/repository/services/v3/getHouse'
import houses from '~/repository/services/v3/houses'
import houseKeyFeatures from '~/repository/services/v3/houses/keyFeatures'
import housePhotos from '~/repository/services/v3/houses/photos'
import housesAreasGroupsAreas from '~/repository/services/v3/houses/areaGroups/areas'
import houseSaleInformation from '~/repository/services/v3/houses/saleInformation'
import houseSaleInquiries from '~/repository/services/v3/houses/saleInquiries'
import housesPeriods from '~/repository/services/v3/houses/periods'
import housesSurroundingInformation from '~/repository/services/v3/houses/surroundingInformation'
import idsHousePeriods from '~/repository/services/v3/ids/housePeriods'
import searchableTagsIdentifiers from '~/repository/services/v3/searchableTags/identifiers'
import realEstateContactInquiries from '~/repository/services/v3/realEstateContactInquiries/index'
import userAutomaticProposals from '~/repository/services/v3/user/automaticProposals'
import userContract from '~/repository/services/v3/user/contract'
import userHouses from '~/repository/services/v3/user/houses/index'
import userInformation from '~/repository/services/v3/user/information'
import userHousesBookings from '~/repository/services/v3/user/houses/bookings'
import userHousesSaleInformation from '~/repository/services/v3/user/houses/saleInformation'
import userHousesTaxCertificate from '~/repository/services/v3/user/houses/taxCertificate'
import userClientContracts from '~/repository/services/v3/user/clientContracts/index'
import userOwnerContracts from '~/repository/services/v3/user/ownerContracts/index'
import userTravelAgentGeneralRentalCondition from '~/repository/services/v3/user/travelAgentGeneralRentalCondition'

export default {
  v3: {
    automaticProposals,
    clientContracts,
    clientContractsReview,
    contacts,
    destination,
    eventInquiries,
    house,
    houseKeyFeatures,
    housePhotos,
    houses,
    houseSaleInformation,
    houseSaleInquiries,
    housesAreasGroupsAreas,
    housesPeriods,
    housesSurroundingInformation,
    idsHousePeriods,
    searchableTagsIdentifiers,
    realEstateContactInquiries,
    userAutomaticProposals,
    userClientContracts,
    userContract,
    userHouses,
    userHousesBookings,
    userHousesSaleInformation,
    userHousesTaxCertificate,
    userInformation,
    userOwnerContracts,
    userTravelAgentGeneralRentalCondition,
  },
}
