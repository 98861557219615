<script setup lang="ts">
const { housesWishlistActiveInHeader } = useMultipleWishlist()
const { setSummaryOpen, summaryOpen } = useMultipleWishlistSummary()
const { headerTheme } = useHeaderNavigation()
const { trackEvent } = useTracking()

const colorIcon = computed(() =>
  headerTheme.value === 'transparent' ? 'white' : 'black',
)

const svgStrokeClass = computed(() => {
  if (housesWishlistActiveInHeader.value.exist) {
    return 'duration-1000 fill-primary-800 stroke-primary-800'
  }

  return `duration-200 fill-transparent ${headerTheme.value === 'transparent' ? 'stroke-white' : 'stroke-gray-700'}`
})

const handleClick = () => {
  setSummaryOpen(!summaryOpen.value)

  trackEvent({
    event: 'header_clicked',
    item: 'wishlist',
  })
}
</script>

<template>
  <BaseButton
    id="header-wishlist"
    :aria-label="$t('header.wishlist')"
    :color="colorIcon"
    class="relative !border-0"
    has-icon
    no-padding
    role="menu"
    type="button"
    @click="handleClick"
  >
    <BaseIcon
      name="heart"
      :class="['transition ease-in-out', svgStrokeClass]"
      :stroke-width="1"
    />
    <span
      v-if="housesWishlistActiveInHeader.exist"
      class="absolute inset-0 flex items-center justify-center text-[7.5px] text-white"
    >
      {{ housesWishlistActiveInHeader.number }}
    </span>
  </BaseButton>
</template>
