import type { ConfigBaseFormModal } from '~/components/BaseForm/compose/types'
import type {
  BaseFormApiError,
  ErrorApiResponse,
} from '~/components/BaseForm/types'

type NotValidFormHandlerType = {
  activeErrorScrollTo: boolean
  errors: Partial<Record<string, string>>
  form: HTMLElement | null
  modalConfig: ConfigBaseFormModal | null
  onSubmit: {
    error?: (
      error: { type: string; errors: ErrorApiResponse },
      setFieldError: (
        field: string,
        message: string | string[] | undefined,
      ) => void,
    ) => void
  }
  scrollToError: (refForm: HTMLElement, errors: BaseFormApiError[]) => void
  setFieldError: (field: string, message: string | string[] | undefined) => void
}

export const notValidFormHandler = ({
  activeErrorScrollTo,
  errors,
  form,
  scrollToError,
  modalConfig,
  onSubmit,
  setFieldError,
}: NotValidFormHandlerType): void => {
  if (activeErrorScrollTo) {
    const errorsFormatted: BaseFormApiError[] = []
    for (const [key, value] of Object.entries(errors)) {
      errorsFormatted.push({
        fieldName: key,
        message: value || '',
      })
    }

    if (form) {
      scrollToError(form, errorsFormatted)
    }
  }

  // when error close modal
  if (modalConfig?.isOpen.value) modalConfig.clearData()

  if (onSubmit?.error) {
    return onSubmit.error({ type: 'form', errors }, setFieldError)
  }
}
