import Presenter from "../../../abstracts/Presenter";
import { WishlistVM } from "./ViewModel";

import { CreateUserWishlistPresentation } from "../usecases/CreateUserWishlist/interfaces/CreateUserWishlistPresentation";
import { DeleteUserHouseWishlistPresentation } from "../usecases/DeleteUserHouseWishlist/interfaces/DeleteUserHouseWishlistPresentation";
import { DeleteUserWishlistPresentation } from "../usecases/DeleteUserWishlist/interfaces/DeleteUserWishlistPresentation";
import { GetUserWishlistPresentation } from "../usecases/GetUserWishlist/interfaces/GetUserWishlistPresentation";
import { GetUserWishlistsPresentation } from "../usecases/GetUserWishlists/interfaces/GetUserWishlistsPresentation";
import { GetUserWishlistHouseAvailablesPresentation } from "../usecases/GetUserWishlistHouseAvailables/interfaces/GetUserWishlistHouseAvailablesPresentation";
import { GetWishlistSharedPresentation } from "../usecases/GetWishlistShared/interfaces/GetWishlistSharedPresentation";
import { MakeRequestWishlistPresentation } from "../usecases/MakeRequestWishlist/interfaces/MakeRequestWishlistPresentation";
import { ModifyRequestWishlistPresentation } from "../usecases/ModifyRequestWishlist/interfaces/ModifyRequestWishlistPresentation";
import { ModifyUserWishlistPresentation } from "../usecases/ModifyUserWishlist/interfaces/ModifyUserWishlistPresentation";

import {
  WishlistFormatted,
  WishlistDetailsFormatted,
  WishlistDetailsSharedFormatted,
  HouseAvailable,
} from "../../../types";

export default class WishlistPresenter
  extends Presenter<WishlistVM>
  implements
    CreateUserWishlistPresentation,
    DeleteUserHouseWishlistPresentation,
    DeleteUserWishlistPresentation,
    GetUserWishlistHouseAvailablesPresentation,
    GetUserWishlistPresentation,
    GetUserWishlistsPresentation,
    GetWishlistSharedPresentation,
    MakeRequestWishlistPresentation,
    ModifyRequestWishlistPresentation,
    ModifyUserWishlistPresentation
{
  constructor() {
    super(new WishlistVM());
  }

  displayMessage(): void {
    throw new Error("Method not implemented.");
  }

  displayWishlists(wishlists: WishlistFormatted[]): void {
    this.vm.wishlists = wishlists;
    this.notifyVM();
  }

  displayWishlist(wishlist: WishlistDetailsFormatted | null): void {
    this.vm.wishlist = wishlist;
    this.notifyVM();
  }

  displayWishlistShared(wishlist: WishlistDetailsSharedFormatted | null): void {
    this.vm.wishlistShared = wishlist;
    this.notifyVM();
  }

  displayDeleteWishlistError(error: string): void {
    this.vm.wishlistError = error;
    this.vm.wishlistDeleted = null;
    this.notifyVM();
  }

  displayModifyWishlistError(error: string): void {
    this.vm.wishlistError = error;
    this.vm.wishlistUpdated = null;
    this.notifyVM();
  }

  displayWishlistCreated(wishlist: WishlistFormatted): void {
    this.vm.wishlistError = "";
    this.vm.wishlists.push(wishlist);
    this.notifyVM();
  }

  displayWishlistDeleted(wishlist: WishlistFormatted): void {
    this.vm.wishlistError = "";
    this.vm.wishlistDeleted = wishlist;
    this.notifyVM();
  }

  displayWishlistUpdated(wishlist: WishlistFormatted): void {
    this.vm.wishlistError = "";
    this.vm.wishlistUpdated = wishlist;
    this.notifyVM();
  }

  displayHouseWishlistDeleted(houseId: number): void {
    this.vm.wishlistError = "";
    this.vm.wishlistHouseDeleted = houseId;
    this.notifyVM();
  }

  displayHouseWishlistDeletedError(error: string): void {
    this.vm.wishlistError = error;
    this.notifyVM();
  }

  displayMakeRequestWishlistSucceeded(success: boolean | null): void {
    this.vm.makeRequestWishlistSucceeded = success;
    this.notifyVM();
  }

  displayModifyRequestWishlistSucceeded(success: boolean | null): void {
    this.vm.modifyRequestWishlistSucceeded = success;
    this.notifyVM();
  }

  displayHouseAvailables(houseAvailables: HouseAvailable[]): void {
    this.vm.houseAvailables = houseAvailables;
    this.notifyVM();
  }
}
