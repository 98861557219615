import { PhoneNumberValidator } from "../../../../types";
import { CountryCodes } from "lc-repository/dist/entities";

export const checkPhoneNumber = (
  isValidPhoneNumber: PhoneNumberValidator,
  phoneValue?: string | null,
  countryCode?: CountryCodes
): boolean => {
  return phoneValue ? isValidPhoneNumber(phoneValue, countryCode) : true;
};
