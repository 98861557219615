import { HouseIncludedService } from "../../../types";

export class HouseIncludedServices {
  private houseIncludedServices: HouseIncludedService[] = [];

  constructor(private houseIncludedServicesRepository: HouseIncludedService[]) {
    this.houseIncludedServices = this.houseIncludedServicesRepository.map(
      (includedService) => ({
        identifier: includedService.identifier,
        name: includedService.name,
      })
    );
  }

  build() {
    return this.houseIncludedServices;
  }
}
