export default (clientHttp, apiVersion) => ({
  read(id) {
    return clientHttp.get(`${apiVersion}/user/houses/${id}`)
  },

  update(id, params) {
    return clientHttp.put(`${apiVersion}/user/houses/${id}`, {
      ...params,
    })
  },
})
