import { createQueryV3 } from '~/repository/config/createQueryV3'

export default (clientHttp, apiVersion) => ({
  read({ token, includes }) {
    const query = createQueryV3({ includes })

    return clientHttp.get(
      `${apiVersion}/automatic_proposals${query}&private_token=${token}`,
    )
  },
})
